import { HostListener, Component, OnInit, Inject, AfterViewChecked } from '@angular/core';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { configJson } from '../../../shared/services/dashboard-config.service';
import { CommonService } from './../../../shared/services/common.service';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonConstant } from './../../../shared/constants/common-constant';
import { ApiConstant } from '../../constants/api-constant';
import { LocalstorageService } from '../../services/localstorage.service';
import { forkJoin } from 'rxjs';
import { TableService } from '../table/table.service';
import { ExportService } from './../../../shared/services/export.service';
import { Location } from '@angular/common';
import { promise } from 'protractor';
import { isArray } from 'underscore';
import { FilterService } from '../../services/filter.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var $: any;
var cloneDeep = _.cloneDeep

@Component({
    selector: 'app-selectors',
    templateUrl: './selectors.component.html',
    styleUrls: ['./selectors.component.scss'],
    providers: [CommonConstant, DatePipe]
})
export class SelectorsComponent implements OnInit, AfterViewChecked {
    userInfo: object;
    userRole: string;
    filtersObj: Array<{ [key: string]: any }[]> = [];
    allCompanies: Array<object> = [];
    allCompaniesArr: any = [];
    marketerCompanyArr: any;
    attribute1 = [];
    activeTabId: number;
    toggleObj: object = {};
    resetObj: object = {};
    dpOpenStatus: object = {};
    now: Date = new Date();
    year: number = this.now.getFullYear();
    month: number = this.now.getMonth();
    day: number = this.now.getDate();
    toDate = this.now;
    forYear = new Date(this.now);
    fromDate: Date;// = new Date(this.year - 1, this.month, this.day+1);
    myDateValue: Date;
    clientType: string;
    companyList: any[];
    carrierList: any[];
    agencyList: any[];
    products: { category: string; sub_category: string }[];
    policyStatusList: { category: string; }[];
    lineOfBusinessFilter: string = '';
    medicareAdvantageSaleType: string = '';
    isTAPRequired: string = '';
    isApplicationsApplicable: string = '';
    isPlacedToggleRequired: string = '';
    getChartData: any;
    filterDateOptions = CommonConstant.filterDateTypes;
    getParameters: object = {};
    policyStatus: any[];
    applicationsStatus: any[];
    isSelectorLoaded: boolean = false;
    allPolicyStatus: Object;
    allApplicationsStatus: Object;
    allProducts: any;
    allLineOfBusiness: Object;
    lineOfBusiness: any[];
    allCarrierList: any[];
    allAgencyFiltersList: any[];
    showFilter: boolean;
    multiselectObj: Array<object> = [{}];
    tab1ChartsPosValue: Number;
    carriersSelected: any;
    agencyFiltersSelected: any;
    policyStatusSelected: any;
    applicationsStatusSelected: any;
    allCarriersArr: any[];
    allAgencyFiltersArr: any[];
    selectedCompaniesLength: any;
    selectedCarriersLength: any;
    callSelectorsChanged: boolean = false;
    customInitDate: Date;
    policyArrStatusLength: number;
    applicationArrStatusLength: number;
    allSubProducts: any;
    selectedSubProducts: any;
    subProducts: any;
    companySelected: any;
    chartInfo: any;
    isFirstTimeloaing: boolean = false;
    fromDateCounter: number = 0;
    toDateCounter: number = 0;
    forYearCounter: number = 0;
    forYearOffset: any;
    selectedItems = [
        { name: 'FirstVal', key: 1, additionalInfo: 'The is first in the row' },
        { name: 'SecondVal', key: 2, additionalInfo: 'The is second in the row' },
    ];
    mySelectedItem: any;
    medicareAdvantageFilter: any;
    lob: any;
    client: string;
    oneCarriersSelected: boolean = false;
    lastUpdatedDate: any;
    tabsData: any = [];
    api_name: string = '';
    id: string = 'table2';
    title: string = "Table1";
    tabId: number;
    columnFilters: Array<object> = [];
    fileNameToExport: string = "Agents";
    clickEventOn: Array<object> = [];
    columnDefs: Array<object> = [];
    scrollY: string = 'disabled';
    paging: string = 'enabled';
    companyFilter: string = '';
    selectedSubTab: number;
    subTabComplete: boolean = false;
    routeParams: object = {
        url: '',
        queryParam: ''
    }
    loadTable: boolean;
    agentReportSelector: any;
    showMonthFilter: boolean = false;
    sapAndTapObj: any;
    activeSubTab: object = {};
    incentiveProgramsList: any;
    enable = false;
    weeklyAndMonthlyenable: boolean = false;
    showIncentiveDisclaimer: boolean = false;
    disclaimerOptions: any;
    incentiveProgram: any;
    activeSubTabId: any;
    activeAgentTab: any;
    fromDateCurrentYear: string = '';
    customDateRange: string = '';
    dateRange: object;
    tableService: any;
    directCarrierList: any;
    isFirstLoad: boolean = true;
    selectedProductFilter: string;
    show_lob: any;
    show_agency: any;
    show_carrier: any;
    show_product_type: any;
    show_from_date: any;
    show_to_date: any;
    show_filter_by: any;
    show_application_status: any;
    show_policy_status: any;
    show_for_year: any;
    show_company: any;
    filterDateTypes: any;
    saleTypeList: [];
    activeRoute = [];
    tooltipCarriersSelected: any;
    tooltipAgencySelected: any;
    tooltipPolicySelected: any;
    tooltipApplicationSelected: any;

    constructor(
        private _commonService: CommonService,
        private spinner: NgxSpinnerService,
        private localStorageService: LocalstorageService,
        @Inject(TableService) tableService: TableService,
        private exportService: ExportService,
        private location: Location,
        private filterService: FilterService,
        private router: Router,
    ) {
        this.tableService = tableService;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            // this.currentRoute = event.url;          
            console.log(event);
            this.activeRoute = event.url.split(/[\/?]+/g);
        });
    }
    ngAfterViewChecked(): void {
        this.activeTabId = this.localStorageService.get('activeTabId');
        this.activeSubTab = this.localStorageService.get('activeSubTab');
        this.activeSubTabId = this.activeSubTab ? this.activeSubTab['id'] : null;
        this.selectedProductFilter = $('#productFilter .init').html();
        if (this.selectedProductFilter) {
            this.showhideMedicareAdvantage(this.selectedProductFilter);
        }
        // this.hideEmptySElectorGroup();
        $('#lineOfBusinessFilter .init').html(this.lob);
    }

    ngOnInit() {
        this.filterService.show_lob$
            .subscribe(res => {
                this.show_lob = res;
            });
        this.filterService.show_agency$
            .subscribe(res => {
                this.show_agency = res;
            });
        this.filterService.show_carrier$
            .subscribe(res => {
                this.show_carrier = res;
            });
        this.filterService.show_product_type$
            .subscribe(res => {
                this.show_product_type = res;
            });
        this.filterService.show_filter_by$
            .subscribe(res => {
                this.show_filter_by = res;
            });
        this.filterService.show_from_date$
            .subscribe(res => {
                this.show_from_date = res;
            });
        this.filterService.show_to_date$
            .subscribe(res => {
                this.show_to_date = res;
            });
        this.filterService.show_application_status$
            .subscribe(res => {
                this.show_application_status = res;
            });
        this.filterService.show_policy_status$
            .subscribe(res => {
                this.show_policy_status = res;
            });
        this.filterService.show_for_year$
            .subscribe(res => {
                this.show_for_year = res;
            });
        this.filterService.show_company$
            .subscribe(res => {
                this.show_company = res;
            });

        this.mySelectedItem = this.selectedItems[0].key
        this._commonService.showLoader();
        this.myDateValue = new Date();
        this.customInitDate = new Date();
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.fromDateCurrentYear = (this.userInfo['fromDateCurrentYear']) ? (this.userInfo['fromDateCurrentYear']) : 'no';
        this.customDateRange = (this.userInfo['customDateRange']) ? (this.userInfo['customDateRange']) : 'no';
        this.dateRange = (this.userInfo['dateRange']) ? (this.userInfo['dateRange']) : [];
        this.userRole = this.userInfo['role'];
        this.activeTabId = this.localStorageService.get('activeTabId')
        this.lineOfBusinessFilter = this.userInfo['lineOfBusinessFilter'];
        this.medicareAdvantageSaleType = this.userInfo['medicareAdvantageSaleType'];
        this.isTAPRequired = this.userInfo['isTAPRequired'];
        this.isApplicationsApplicable = this.userInfo['isApplicationsApplicable'];
        this.isPlacedToggleRequired = this.userInfo['isPlacedToggleRequired'];
        this.companyFilter = this.userInfo['companyFilter'];
        this.myDateValue = new Date();
        this.toggleObj = configJson.selectors.Toggle;
        this.resetObj = configJson.selectors.Reset;
        this.clientType = configJson.clientType;
        this.client = configJson.client;
        this.chartInfo = configJson.charts;
        this._commonService.setSapOrTapToggle(this.activeTabId, 'byProductTapValue', 'sap');
        this._commonService.setSapOrTapToggle(this.activeTabId, 'byCarrierTapValue', 'sap');
        this._commonService.setSapOrTapToggle(this.activeTabId, 'mapDataTapValue', 'sap');
        this._commonService.setSapOrTapToggle(this.activeTabId, ApiConstant.agentsTabTapValue, 'sap');
        this._commonService.setSapOrTapToggle(this.activeTabId, ApiConstant.analyticsTabTapValue, 'sap');
        this._commonService.setSapOrTapToggle(this.activeTabId, ApiConstant.reportsTabTapValue, 'sap');
        this.forYearOffset = (new Date().getTimezoneOffset());
        if (this.userInfo['companyFilter'] === 'yes' && this.userInfo['role'] === 'Manager') {
            this.getMarketerCompanies();
        } else {
            this._commonService.setSelector('marketerCompany', '');
            this._commonService.setSelector('attribute1', '');
            if (this.lineOfBusinessFilter === 'yes') {
                var lobparams = {
                    lob: 'All'
                }
                this._commonService.getLineOfBusiness(lobparams).subscribe(res => {
                    if (res.length) {
                        let lineOfBusiness = [];
                        this.allLineOfBusiness = res;
                        res.forEach(function (ele, index) {
                            lineOfBusiness.push({ category: ele['lob'] });
                        });
                        this.lineOfBusiness = lineOfBusiness;
                        this._commonService.setSelector('lob', 'All');
                        this.getInitialApiCalls();
                    }
                });
            } else {
                this.companyList = [];
                this.getInitialApiCalls();
            }
        }
        this.medicareAdvantageFilter = {
            filterValue: 'All'
        }
        this.getRTSCarriers();
        this._commonService.setSelector('salepolicytoggle', '#');
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.location.replaceState(this.location.path().split('?')[0], '');
        }, 5000);
        if (this.clientType === 'carrier' || this.carriersSelected?.length <= 1) {
            this.checkLastUpdatedDate();
        }
    }

    getRTSCarriers() {
        let params = {
            client: configJson.client,
            clientType: configJson.clientType
        }
        this._commonService.getRTScarriers(params).subscribe((res:any) => {
            let filteredCarrierArray = res
            .map(carrier => carrier.carrier_name);

            let RTSCarrierList = filteredCarrierArray.map((item, index) => ({
                item_id: index + 1,
                item_text: item
            }));
            this._commonService.setRTSCarriersList(RTSCarrierList);
            this._commonService.setSelector('rtsCarriers', RTSCarrierList.length ? RTSCarrierList.map(item => item.item_text) : []);
            
        });
    }

    setSelectorsStyleObject = () => {
        var height = '100px';
        var paddingtop = '10px';
        this.activeTabId = this.localStorageService.get('activeTabId');
        this.activeSubTab = this.localStorageService.get('activeSubTab');
        if (this.activeTabId === 4) {
            height = '55px';
        }
        if (this.activeTabId === 5 && this.activeSubTab['id'] === 27) {
            height = '0px';
            paddingtop = '0px';
        }
        if (this.activeTabId === 7) {
            height = '0px';
            paddingtop = '0px';
        }
        return { 'height': height, 'padding-top': paddingtop }
    }

    @HostListener('document:click', ['$event'])
    onClick(e: any) {
        if (e.target.className !== 'init') {
            $('#productFilter, #policyStatusFilter, #applicationStatusFilter, #filterByDateFilter, #lineOfBusinessFilter', '#agency-select').children('li:not(.init)').hide();
        } else {
            if ($('#carriers').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#carriers').parent().removeClass('show');
                }, 50)
            }
            if ($('#agency-select').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#agency-select').parent().removeClass('show');
                }, 50)
            }
            if ($('#company').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#company').parent().removeClass('show');
                }, 50)
            }
            if ($('#policyStatusFilter').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#policyStatusFilter').parent().removeClass('show');
                }, 50)
            }
            if ($('#applicationStatusFilter').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#applicationStatusFilter').parent().removeClass('show');
                }, 50)
            }
        }
        if (e.target.className === 'row selectors filder-holder selectors-background') {
            if ($('#carriers').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#carriers').parent().removeClass('show');
                }, 50)
            }
            if ($('#agency-select').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#agency-select').parent().removeClass('show');
                }, 50)
            }
            if ($('#company').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#company').parent().removeClass('show');
                }, 50)
            }
            if ($('#policyStatusFilter').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#policyStatusFilter').parent().removeClass('show');
                }, 50)
            }
            if ($('#applicationStatusFilter').parent().hasClass('show')) {
                setTimeout(() => {
                    $('#applicationStatusFilter').parent().removeClass('show');
                }, 50)
            }
        }
        if (!["dropdownMenucompany", "dropdownMenucarriers", "dropdownMenuagency-select", "dropdownMenupolicyStatusFilter", "dropdownMenuapplicationStatusFilter"].includes(e.target.id)) {
            $('#agency-select').parent().removeClass('show');
            $('#carriers').parent().removeClass('show');
            $('#policyStatusFilter').parent().removeClass('show');
            $('#applicationStatusFilter').parent().removeClass('show');
            $('#company').parent().removeClass('show');
        }
    }

    //getMarketerCompanies
    getMarketerCompanies = () => {
        var reqParams = {
            id: this.userInfo['id']
        }
        this._commonService.getMarketerCompanies(reqParams).subscribe(res => {
            let list = [];
            var companyList: any = [];
            this.allCompanies = res;
            res.forEach(function (ele, index) {
                list.push(ele['company_name']);
                companyList.push({
                    'id': index,
                    'text': ele['company_name']
                });
            });
            this.companyList = companyList;
            this.allCompaniesArr = list;
            this.selectAllCompanies();
            this._commonService.setSelector('attribute1', list);
            this._commonService.setSelector('marketerCompany', list);
            this.attribute1 = list;
            this.marketerCompanyArr = list;
            this.getInitialApiCalls();
        });
    };

    getCarriers() {
        var api = this.clientType === 'carrier' ? '/getAgency' : '/getcarriers';
        var name = this.clientType === 'carrier' ? 'agency_name' : 'carrier_name';
        let params = this._commonService.getSelector();
        var reqParams = {
            attribute1: [],
            attribute2: '',
            attribute3: '',
            attribute4: '',
            attribute5: '',
            client: 'TBI',
            clientType: 'agency',
            crmId: 'AgentVizion Id',
            filterDateType: 'Filter Date'
        }
    }

    getInitialApiCalls = () => {
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth();
        var day = now.getDate();
        let fromDate = '';
        if (this.customDateRange.includes('yes')) {
            let dFromDate = this.dateRange[0]['defaultFromDate'];
            let dToDate = this.dateRange[0]['defaultToDate'];
            this.fromDate = new Date(dFromDate);
            this.toDate = new Date(dToDate);
            fromDate = this.convertDate(this.fromDate);
        } else {
            if (this.fromDateCurrentYear.includes('yes')) {
                this.fromDate = new Date(now.getFullYear(), 0, 1);
                fromDate = this.convertDate(new Date(now.getFullYear(), 0, 1));
            } else {
                this.fromDate = new Date(year - 1, month + 1, day);
                fromDate = this.convertDate(new Date(year - 1, month + 1, day))
            }
            this.toDate = now;
        }
        let toDate = this.convertDate(this.toDate);
        this.filterDateTypes = this.dateRange[0] && this.dateRange[0]['defaultDateType'] ? this.dateRange[0]['defaultDateType'] : CommonConstant.filterDateTypes[0]['category'];
        this._commonService.setSelector('filterDateType', this.filterDateTypes)
        this._commonService.setSelector('fromDate', fromDate);
        this._commonService.setSelector('toDate', toDate);
        let forYear = this.convertDate(new Date());
        this._commonService.setSelector('forYear', forYear);
        this._commonService.showLoader();
        if (this.isApplicationsApplicable === 'yes') {
            var callGetApplicationStatusList = this._commonService.getApplicationStatus();
        }
        var callGetPolicyStatusList = this._commonService.getPolicyStatus();
        var reqParams = {
            lob: 'All'
        }
        var callGetProductList = this._commonService.getProducts(reqParams);
        var carrierReqParams = {
            attribute1: this.marketerCompanyArr, //params['marketerCompany'],
            client: this.client,
            clientType: 'agency',
            filterDateType: CommonConstant.filterDateTypes[0]['category']
        }
        let agencyReqParams = {
            attribute1: this.marketerCompanyArr, //params['marketerCompany'],
            client: this.client,
            clientType: 'carrier',
            filterDateType: CommonConstant.filterDateTypes[0]['category']
        }
        var callGetCarriers = this._commonService.getCarriers(carrierReqParams);
        var callGetAgencies = this._commonService.getCarriers(agencyReqParams);
        var multipleQApiArr = []
        if (this.isApplicationsApplicable === 'yes') {
            multipleQApiArr = [callGetPolicyStatusList, callGetProductList, callGetCarriers, callGetAgencies, callGetApplicationStatusList]
        } else {
            multipleQApiArr = [callGetPolicyStatusList, callGetProductList, callGetCarriers, callGetAgencies];
        }
        forkJoin(multipleQApiArr).subscribe(res => {
            let response: any = res;
            this._commonService.setSelector('product', 'All');
            var name = 'category';
            let policyList = [];
            let statusList = [];
            response[0].forEach(function (ele, index) {
                policyList.push(ele[name]);
                statusList.push({
                    'id': index,
                    'text': ele['category']
                });
            });
            this.policyStatus = statusList;
            this.allPolicyStatus = JSON.stringify(policyList);
            this._commonService.setAllPolicyStatus(policyList);
            this._commonService.setSelector('policyStatus', policyList);
            this.policyArrStatusLength = this.policyStatus.length;
            this.selectAllPolicyStatus();
            var productList = [];
            this.allProducts = res[1];
            response[1].forEach(function (product) {
                productList.push({ category: product['category'] });
            });
            this.products = productList;
            this._commonService.setSelector('product', 'All');
            this._commonService.productList = productList;
            let carrierList = [];
            let agencyList = [];
            let agencyFiltersList = [];
            let cList = [];
            // this.allCarrierList = res[2];
            var name = 'carrier_name';
            response[2].forEach((carrier, index) => {
                if (this.clientType === 'carrier' && this.userRole !== 'Manager' && this.userRole !== 'Agent') {
                    if (carrier[name] == 'DIRECT') {
                        var downlineEle = carrier['downline'].split('|');
                        cList = [...cList, ...downlineEle];
                        this.directCarrierList = downlineEle;
                        carrierList.push({
                            'id': index,
                            'text': carrier[name]
                        });

                    } else {
                        cList.push(carrier[name]);
                        carrierList.push({
                            'id': index,
                            'text': carrier[name]
                        });
                    }
                }
                else {
                    cList.push(carrier[name]);
                    carrierList.push({
                        'id': index,
                        'text': carrier[name]
                    });
                }
            });

            name = 'agency_name';

            if (isArray(response[3]) && response[3].length > 0) {
                response[3].forEach((agency, index) => {
                    if (this.userRole !== 'Manager' && this.userRole !== 'Agent') {
                        agencyFiltersList.push(agency[name])
                        if (agency[name] == 'DIRECT') {
                            var downlineEle = agency['downline'].split('|');
                            agencyFiltersList = [...agencyFiltersList, ...downlineEle];
                            this.directCarrierList = downlineEle;
                            agencyList.push({
                                'id': index,
                                'text': agency[name]
                            });
                        } else {
                            agencyFiltersList.push(agency[name]);
                            agencyList.push({
                                'id': index,
                                'text': agency[name]
                            });
                        }
                    } else {
                        agencyList.push({
                            'id': index,
                            'text': agency[name]
                        });
                        agencyFiltersList.push(agency[name]);
                    }
                });
            }
            this.allCarrierList = cList;
            let tempList = new Set(agencyFiltersList);
            agencyFiltersList = Array.from(tempList);
            this.allAgencyFiltersList = agencyFiltersList;
            this.carrierList = carrierList;
            this.agencyList = agencyList;
            this.selectAllCarriers();
            this.selectAllAgencies();
            this._commonService.setSelector('agencyFilter', agencyFiltersList);
            this._commonService.setSelector('agencyFilters', agencyFiltersList);
            this._commonService.setSelector('carrierFilter', cList);
            this.multiselectObj = [];
            if (this.isApplicationsApplicable === 'yes') {
                var name = 'category';
                let applicationsList = [];
                let appStatusList = [];
                response[4].forEach(function (ele, index) {
                    applicationsList.push(ele[name]);
                    appStatusList.push({
                        'id': index,
                        'text': ele['category']
                    });
                });
                this.applicationsStatus = appStatusList;
                this.allApplicationsStatus = JSON.stringify(applicationsList);
                this._commonService.setSelector('applicationStatus', applicationsList);
                this.applicationArrStatusLength = this.applicationsStatus.length;
                this.selectAllApplicationsStatus();
            }
            this.getFilterObjForTab(configJson.selectors.Filters);
            this.multiselectObj.forEach(element => {
                // setTimeout(() => {
                this.getTreeDropdown(element['id']);
                // }, 0);
            });
            this.isSelectorLoaded = true;
            this._commonService.setIsSelectorLoaded();
            // this.showProductFilter();
        });
        this.getSaleTypeList();
    }

    checkIsTabApplicable(selector, index2, index1) {
        //tabId length is zero for all tabs
        if (selector.tabId.length === 0) {
            this.filtersObj[index1] ? '' : this.filtersObj[index1] = [];
            this.filtersObj[index1].push(selector);
        }

        //tabId length is more then zero and checking which tab to show filter based on active tab
        if (selector.tabId.length && selector.tabId.indexOf(this.activeTabId) !== -1) {
            this.filtersObj[index1] ? '' : this.filtersObj[index1] = [];
            this.filtersObj[index1].push([selector]);
        }
    }

    getFilterObjForTab = (filterObjArr) => {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userRole = this.userInfo['role'];
        filterObjArr.forEach((ele, index1) => {
            ele.forEach((selector, index2) => {
                if (selector.type === 'multiSelect') {
                    this.multiselectObj.push(selector);
                }
                if (selector.role.length === 0) {
                    this.checkIsTabApplicable(selector, index2, index1);
                }
                if (selector.role.length && selector.role.indexOf(this.userRole) !== -1) {
                    this.checkIsTabApplicable(selector, index2, index1);
                }
                this.getSelectorValues(selector, index2, index1)
            });
        })
    }

    getSelectorValues = (selector, index2, index1) => {
        if (selector.name === 'company') {
            selector.values = this.companyList;
        } else if (selector.name === 'Carrier') {
            selector.values = this.carrierList;
        } else if (selector.name === 'Agency') {
            selector.values = this.agencyList;
        } else if (selector.name === 'Line of Business') {
            selector.values = this.lineOfBusiness;
        } else if (selector.name === 'Product') {
            selector.values = this.products;
        } else if (selector.name === 'Policy Status') {
            selector.values = this.policyStatus;
        } else if (selector.name === 'Application Status') {
            selector.values = this.applicationsStatus;
        } else if (selector.name === 'Filter By') {
            selector.values = CommonConstant.filterDateTypes;
        }
        this.filtersObj[index1][index2] = selector;
        this.showFilter = true;
    }

    checkLastUpdatedDate = () => {
        let params = cloneDeep(this._commonService.getParameters());
        let tempinterval = setInterval(() => {
            if (this.clientType == 'carrier') params['param1'] = this._commonService.clientName;
            else {
                params['param1'] = this.carriersSelected.length == 1 ? this.carriersSelected[0] : '';
            }
            if (params['param1']) {
                clearInterval(tempinterval);
                this._commonService.checkLastUpdatedDate(params).subscribe(res => {
                    this.lastUpdatedDate = res;
                    this.oneCarriersSelected = true;
                });
            }
        }, 100);
    }

    updatePageContent = function (event) {
        var value = $(event.target).html().trim();
        $(event.target).siblings().removeClass('selected');
        $(event.target).addClass('selected');
        $(event.target).siblings('.init').html(value);
        $(event.target).parent().children('li.option').hide();
        if ($(event.target).closest('ul').attr('id') === 'filterByDateFilter') {
            this.filterDateTypeChangeEvent(value);
            this.callCommonSubTabContent();
        } else if ($(event.target).closest('ul').attr('id') === 'lineOfBusinessFilter') {
            this.lobFilterTypeChangeEvent({ id: 'lineOfBusinessFilter', value: value });
            // this.onGetSubTabContent({});
        } else if ($(event.target).closest('ul').attr('id') === 'productFilter') {
            this.selectedProductFilter = value;
            this.productChanged(value);
        }
    };

    dropDownEvent = function (event) {
        if (!$(event.target).siblings().is(':visible')) {
            $('.selectFilter li.option').hide();
        } else {
            $(event.target).siblings().show();
        }
        $(event.target).siblings().toggle();
        this.selectorClick(event, 'dummy');
    };

    bindChangeEvent = (target) => {
        let that = this;
        setTimeout(() => {
            $('#' + target).on('changed.jstree', function (e, data) {
                $.holdReady(true);
                $('#spinner').show();
                $('md-autocomplete-wrap input').prop('disabled', true);
                data.id = target;
                //scope.filterChangeEvent({ data: data });

                // if(this.callSelectorsChanged){
                that.selectorsChanged(data);
                // }
                if (target === 'stateFilter') {
                    //scope.stateCount = data.action === 'select_node' ? data.node.text === 'All' ? scope.states.length : scope.stateCount + 1 : data.node.text === 'All' ? 0 : scope.stateCount - 1;
                }
                setTimeout(() => {

                    $('.' + target + '_container .dropdown-toggle-main').addClass('show');
                    if (!that.isFirstLoad) {
                        $('#' + target).parent().addClass('show');
                    }
                }, 0);
            }).jstree();
        }, 0)
    }

    getTreeDropdown(target) {
        this.treeStructure(target)
        $('#carriers').on('changed.jstree', function (e, data) {
            $.holdReady(true);
            $('md-autocomplete-wrap input').prop('disabled', true);
            data.id = 'carriers';
            setTimeout(function () {
                $('.carriers_container .dropdown-toggle-main').addClass('show');
            }, 0);
        }).jstree();
        //bind change event on carrier dropdown
    };

    treeStructure(target: any) {
        setTimeout(() => {
            // console.log('111111', target, $('#'+target).length);
            let $treeview = $('#' + target);
            if (target === 'company') {
                $treeview.jstree({
                    'core': {
                        'data': [{
                            'text': 'All',
                            'children': this.companyList
                        }]
                    },
                    'checkbox': {
                        'keep_selected_style': false
                    },
                    'types': {
                        'default': {
                            'icon': false
                        },
                        'demo': {
                            'icon': false
                        }
                    },
                    'plugins': ['types', 'checkbox'],
                    'themes': { 'icons': false }
                }).on('loaded.jstree', function () {
                    $('#' + target).jstree('open_all');
                    $('#' + target).jstree('check_all');
                    // this.bindChangeEvent(target);
                });
            } else if (target === 'carriers') {

                // console.log('2222222', target, $('#'+target).length);
                $treeview.jstree({
                    'core': {
                        'data': [{
                            'text': 'All',
                            'children': this.carrierList
                        }]
                    },
                    'checkbox': {
                        'keep_selected_style': false
                    },
                    'types': {
                        'default': {
                            'icon': false
                        },
                        'demo': {
                            'icon': false
                        }
                    },
                    'plugins': ['types', 'checkbox'],
                    'themes': { 'icons': false }
                }).on('loaded.jstree', function () {
                    $('#' + target).jstree('open_all');
                    $('#' + target).jstree('check_all');

                });

            } else if (target === 'agency-select') {
                $treeview.jstree({
                    'core': {
                        'data': [{
                            'text': 'All',
                            'children': this.agencyList
                        }]
                    },
                    'checkbox': {
                        'keep_selected_style': false
                    },
                    'types': {
                        'default': {
                            'icon': false
                        },
                        'demo': {
                            'icon': false
                        }
                    },
                    'plugins': ['types', 'checkbox'],
                    'themes': { 'icons': false }
                }).on('loaded.jstree', function () {
                    $('#' + target).jstree('open_all');
                    $('#' + target).jstree('check_all');

                });
            } else if (target === 'policyStatusFilter') {
                $treeview.jstree({
                    'core': {
                        'data': [{
                            'text': 'All',
                            'children': this.policyStatus
                        }]
                    },
                    'checkbox': {
                        'keep_selected_style': false
                    },
                    'types': {
                        'default': {
                            'icon': false
                        },
                        'demo': {
                            'icon': false
                        }
                    },
                    'plugins': ['types', 'checkbox'],
                    'themes': { 'icons': false }
                }).on('loaded.jstree', function () {
                    $('#policyStatusFilter').jstree('open_all');
                    $('#policyStatusFilter').jstree('check_all');
                });
            } else if (target === 'applicationStatusFilter') {
                $treeview.jstree({
                    'core': {
                        'data': [{
                            'text': 'All',
                            'children': this.applicationsStatus
                        }]
                    },
                    'checkbox': {
                        'keep_selected_style': false
                    },
                    'types': {
                        'default': {
                            'icon': false
                        },
                        'demo': {
                            'icon': false
                        }
                    },
                    'plugins': ['types', 'checkbox'],
                    'themes': { 'icons': false }
                }).on('loaded.jstree', function () {
                    $('#applicationStatusFilter').jstree('open_all');
                    $('#applicationStatusFilter').jstree('check_all');
                });
            }
            this.bindChangeEvent(target);
        }, 0);
    }

    selectAllCarriers = function () {
        //$scope.carriersSelected = JSON.parse(allCarriers);
        this.carriersSelected = cloneDeep(this.allCarrierList);
    };

    selectAllAgencies = function () {
        this.agencyFiltersSelected = cloneDeep(this.allAgencyFiltersList);
    }

    selectAllCompanies = function () {
        this.companySelected = cloneDeep(this.allCompaniesArr);
    };

    async lobFilterTypeChangeEvent(obj) {
        //this.tab1ChartsPosValue = 0;
        this.lob = obj.value;
        this._commonService.setLobVal(obj.value);
        this._commonService.setParameters('lob', obj.value);
        // if ($('#breadcrumb').length) {
        //     $('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
        // }
        var reqParams = {
            lob: obj.value
        }
        var callGetProductList = this._commonService.getProducts(reqParams);
        await new Promise((resolve, reject) => {
            forkJoin([callGetProductList]).subscribe(res => {
                $('#productFilter .init').html('Select Product Type');
                this.selectedProductFilter = 'Select Product Type';
                var listOfProductsvalues = [];
                var productCategoryValue: any
                var productList = [];
                this.allProducts = res[0];
                res[0].forEach(function (product) {
                    productList.push({ category: product['category'] });
                });
                this.products = productList;
                this._commonService.setSelector('product', 'All');
                this._commonService.productList = productList;
                // this.filtersObj = [];

                // this.getFilterObjForTab(configJson.selectors.Filters);
                this.filtersObj.forEach(e => {
                    let productSelector = e.find(x => x.id == 'productFilter');
                    if (productSelector) {
                        productSelector.values = this.products;
                    }
                })
                res[0].forEach((ele) => {
                    if (res[0].length > 1) {
                        listOfProductsvalues.push(ele['category']);
                    } else {
                        productCategoryValue = ele['category'];
                    }
                    this._commonService.setSelector('param2', 'All');
                });
                resolve({})
            });
        })
        this._commonService.setSelector('lob', obj.value);
        if (this.activeTabId === 1 || this.activeTabId === 3) {
            this.callCommonSubTabContent();
        } else {
            this.onGetSubTabContent({});
        }
    }

    selectorsChanged = (data: any) => {
        // this.isFirstTimeloaing = true;
        this.tab1ChartsPosValue = 0;
        var index;
        var agencyFiltersIndex;
        if (data.id === 'company') {
            var companiesLength = this.allCompaniesArr.length;
            this.selectedCompaniesLength = data.selected.length;
            var selecteNodes = data.selected.length > companiesLength ? data.selected.length - 1 : data.selected.length;
            $('#selectedCompanyCount').html(selecteNodes);
            if (data.action === 'select_node') {
                if (data.node.text === 'All') {
                    this.allCompanies = this.allCompaniesArr;
                    this.selectAllCompanies();
                } else {
                    if (this.companySelected.indexOf('') > -1) {
                        this.companySelected = [];
                    }
                    this.companySelected.push(data.node.text);
                }
                this.attribute1 = this.companySelected;
                this._commonService.setSelector('attribute1', this.companySelected);
                this.callCommonSubTabContent();
            } else if (data.action === 'deselect_node') {
                if (data.node.text === 'All') {
                    this.companySelected = [''];
                } else {
                    index = this.companySelected.indexOf(data.node.text); // <-- Not supported in <IE9
                    if (index !== -1) {
                        if (this.companySelected.length === 1) {
                            this.companySelected = [''];
                        } else {
                            this.companySelected.splice(index, 1);
                        }
                    }
                }
                this.attribute1 = this.companySelected;
                this._commonService.setSelector('attribute1', this.companySelected);
                this.callCommonSubTabContent();
            }
        } else if (data.id === 'carriers') {
            //var carriersLength = JSON.parse(allCarriers).length;
            // var carriersLength = this.allCarrierList.length;
            var carriersLength;
            carriersLength = this.allCarrierList.length;
            this.selectedCarriersLength = data.selected.length;
            var selecteNodes = data.selected.length > carriersLength ? data.selected.length - 1 : data.selected.length;
            // this.tooltipCarriersSelected = this.allCarrierList.filter((element, index) => data.selected.includes(index.toString())).join(', ');
            $('#selectedCarrierCount').html(selecteNodes);
            if (data.action === 'select_node') {
                if (data.node.text === 'All') {
                    this.selectAllCarriers();
                } else {
                    if (this.carriersSelected.indexOf('') > -1) {
                        this.carriersSelected = [];
                    }
                    this.carriersSelected.push(data.node.text);
                }
                this.oneCarriersSelected = false;
                this._commonService.setSelector('carrierFilter', this.carriersSelected);
                // if(this.activeTabId != 2){
                if (this.clientType === 'carrier' || this.carriersSelected.length <= 1) {
                    this.checkLastUpdatedDate();
                    $('#lastUpdatedDate').show();
                }
                // } else {
                //     $('#lastUpdatedDate').hide();
                // }
                if (this.activeTabId === 1) {
                    if (this.carriersSelected.length <= 1) {
                        $('#marqueeMainContainer').hide();
                    } else {
                        $('#marqueeMainContainer').show();
                    }
                } else {
                    $('#marqueeMainContainer').hide();
                }
                this.callCommonSubTabContent();
            } else if (data.action === 'deselect_node') {
                if (data.node.text === 'All') {
                    this.carriersSelected = [''];
                } else {
                    if (this.userRole !== 'Manager' && this.userRole !== 'Agent' && this.clientType === 'carrier') {
                        index = this.carriersSelected.indexOf(data.node.text); // <-- Not supported in <IE9
                        let that = this;
                        if (index !== -1) {
                            if (that.carriersSelected && that.carriersSelected.length === 1) {
                                that.carriersSelected = [''];
                            } else {
                                that.carriersSelected.splice(index, 1);
                            }
                        } else {
                            if (data.node.text === 'DIRECT') {
                                that.directCarrierList.forEach(function (ele) {
                                    if (that.carriersSelected) {
                                        var indexVal = that.carriersSelected.indexOf(ele)
                                        that.carriersSelected.splice(indexVal, 1);
                                    }
                                });
                            }
                        }
                    } else {
                        index = this.carriersSelected.indexOf(data.node.text);
                        if (index !== -1) {
                            if (this.carriersSelected.length === 1) {
                                this.carriersSelected = [''];
                            } else {
                                this.carriersSelected.splice(index, 1);
                            }
                        }
                    }
                }
                this.oneCarriersSelected = false;
                this._commonService.setSelector('carrierFilter', this.carriersSelected);
                if (this.activeTabId !== 2) {
                    if (this.carriersSelected.length <= 1) {
                        this.checkLastUpdatedDate();
                        $('#lastUpdatedDate').show();
                    }
                } else {
                    $('#lastUpdatedDate').hide();
                }
                if (this.activeTabId === 1) {
                    if (this.carriersSelected.length <= 1) {
                        $('#marqueeMainContainer').hide();
                    } else {
                        $('#marqueeMainContainer').show();
                    }
                } else {
                    $('#marqueeMainContainer').hide();
                }
                this.callCommonSubTabContent();

            }
            this.tooltipCarriersSelected = this.carriersSelected.join(', ');
        } else if (data.id === 'agency-select') {
            var agencyLength;
            agencyLength = this.agencyList.length;
            var selecteNodes = data.selected.length > agencyLength ? data.selected.length - 1 : data.selected.length;
            // this.tooltipAgencySelected = this.agencyList.filter(obj => data.selected.includes(obj.id.toString())).map(obj => obj.text).join(', ');
            $('#selectedAgencyCount').html(selecteNodes);
            if (data.action === 'select_node') {
                if (data.node.text === 'All') {
                    this.selectAllAgencies();
                } else {
                    if (this.agencyFiltersSelected.indexOf('') > -1) {
                        this.agencyFiltersSelected = [];
                    }
                    if (this.userRole !== 'Manager' && this.userRole !== 'Agent' && this.clientType === 'carrier') {
                        let that = this;
                        that.agencyFiltersSelected.push(data.node.text);
                    } else {
                        this.agencyFiltersSelected.push(data.node.text);
                    }
                    if (data.node.text === 'DIRECT') {
                        let that = this;
                        that.directCarrierList?.forEach(function (ele) {
                            that.agencyFiltersSelected.push(ele);
                        });
                    }
                }
                this.oneCarriersSelected = false;
                this._commonService.setSelector('agencyFilter', this.agencyFiltersSelected);
                this._commonService.setSelector('agencyFilters', this.agencyFiltersSelected);
                // if(this.activeTabId != 2){
                if (this.clientType === 'carrier' || this.carriersSelected.length <= 1) {
                    this.checkLastUpdatedDate();
                    $('#lastUpdatedDate').show();
                }
                // } else {
                //     $('#lastUpdatedDate').hide();
                // }
                if (this.activeTabId === 1) {
                    if (this.carriersSelected.length <= 1) {
                        $('#marqueeMainContainer').hide();
                    } else {
                        $('#marqueeMainContainer').show();
                    }
                } else {
                    $('#marqueeMainContainer').hide();
                }
                this.callCommonSubTabContent();
            } else if (data.action === 'deselect_node') {
                if (data.node.text === 'All') {
                    this.agencyFiltersSelected = [''];
                } else {
                    index = this.agencyFiltersSelected.indexOf(data.node.text);
                    if (this.userRole !== 'Manager' && this.userRole !== 'Agent' && this.clientType === 'carrier') {
                        let that = this;
                        if (index !== -1) {
                            if (that.agencyFiltersSelected && that.agencyFiltersSelected.length === 1) {
                                that.agencyFiltersSelected = [''];
                            } else {
                                that.agencyFiltersSelected.splice(index, 1);
                            }
                        }
                    } else {
                        if (index !== -1) {
                            if (this.agencyFiltersSelected && this.agencyFiltersSelected.length === 1) {
                                this.agencyFiltersSelected = [''];
                            } else {
                                this.agencyFiltersSelected.splice(index, 1);
                            }
                        }
                    }
                    if (data.node.text === 'DIRECT') {
                        let that = this;
                        that.directCarrierList?.forEach(function (ele) {
                            if (that.agencyFiltersSelected) {
                                var indexVal = that.agencyFiltersSelected.indexOf(ele)
                                that.agencyFiltersSelected.splice(indexVal, 1);
                            }
                        });
                    }
                }
                this.oneCarriersSelected = false;
                this._commonService.setSelector('agencyFilter', this.agencyFiltersSelected);
                this._commonService.setSelector('agencyFilters', this.agencyFiltersSelected);
                if (this.activeTabId !== 2) {
                    if (this.carriersSelected.length <= 1) {
                        this.checkLastUpdatedDate();
                        $('#lastUpdatedDate').show();
                    }
                } else {
                    $('#lastUpdatedDate').hide();
                }
                if (this.activeTabId === 1) {
                    if (this.carriersSelected.length <= 1) {
                        $('#marqueeMainContainer').hide();
                    } else {
                        $('#marqueeMainContainer').show();
                    }
                } else {
                    $('#marqueeMainContainer').hide();
                }
                this.callCommonSubTabContent();
            }
            this.tooltipAgencySelected = this.agencyFiltersSelected.join(', ');
        } else if (data.id === 'policyStatusFilter') {
            this.policyStatusChanged(data);
        } else if (data.id === 'applicationStatusFilter') {
            this.applicationsStatusChanged(data);
        } else if (data.id === 'subProductFilter') {
            // this.subProductChanged(data);
        }

    };

    selectAllPolicyStatus = function () {
        if (this.allPolicyStatus) {
            this.policyStatusSelected = JSON.parse(this.allPolicyStatus);
        }
    };

    selectAllApplicationsStatus = function () {
        if (this.allPolicyStatus) {
            this.applicationsStatusSelected = JSON.parse(this.allApplicationsStatus);
        }
    };

    policyStatusChanged = function (data) {
        var index;
        var policyStatusLength = JSON.parse(this.allPolicyStatus).length;
        var selecteNodes = data.selected.length > policyStatusLength ? data.selected.length - 1 : data.selected.length;
        // this.tooltipPolicySelected = JSON.parse(this.allPolicyStatus).filter((element, index) => data.selected.includes(index.toString())).join(', ');
        $('#selectedPolicyStatusCount').html(selecteNodes);
        if (data.action === 'select_node') {
            if (data.node.text === 'All') {
                this.selectAllPolicyStatus();
            } else {
                if (this.policyStatusSelected.indexOf('') > -1) {
                    this.policyStatusSelected = [];
                }
                this.policyStatusSelected.push(data.node.text);
            }
            this.policyStatus = this.policyStatusSelected.toString();
            this._commonService.setSelector('policyStatus', this.policyStatusSelected);
            //this.callCommonSubTabContent();
            this.onGetSubTabContent({});
        } else if (data.action === 'deselect_node') {
            if (data.node.text === 'All') {
                this.policyStatusSelected = [''];
            } else {
                index = this.policyStatusSelected.indexOf(data.node.text); // <-- Not supported in <IE9
                if (index !== -1) {
                    if (this.policyStatusSelected.length === 1) {
                        this.policyStatusSelected = [''];
                    } else {
                        this.policyStatusSelected.splice(index, 1);
                    }
                }
            }
            this.policyStatus = this.policyStatusSelected.toString();
            this._commonService.setSelector('policyStatus', this.policyStatusSelected);
            // this.callCommonSubTabContent();
            this.onGetSubTabContent({});
        }
        this.tooltipPolicySelected = this.policyStatusSelected.join(', ');
    };

    applicationsStatusChanged = function (data) {
        if (this.isApplicationsApplicable === 'yes') {
            var index;
            var applicationsStatusLength = JSON.parse(this.allApplicationsStatus).length;
            var selecteNodes = data.selected.length > applicationsStatusLength ? data.selected.length - 1 : data.selected.length;
            // this.tooltipApplicationSelected = JSON.parse(this.allApplicationsStatus).filter((element, index) => data.selected.includes(index.toString())).join(', ');
            $('#selectedapplicationStatusCount').html(selecteNodes);
            if (data.action === 'select_node') {
                if (data.node.text === 'All') {
                    this.selectAllApplicationsStatus();
                } else {
                    if (this.applicationsStatusSelected.indexOf('') > -1) {
                        this.applicationsStatusSelected = [];
                    }
                    this.applicationsStatusSelected.push(data.node.text);
                }
                this.applicationsStatus = this.applicationsStatusSelected.toString();
                this._commonService.setSelector('applicationStatus', this.applicationsStatusSelected);
                this.callCommonSubTabContent();
            } else if (data.action === 'deselect_node') {
                if (data.node.text === 'All') {
                    this.applicationsStatusSelected = [''];
                } else {
                    index = this.applicationsStatusSelected.indexOf(data.node.text); // <-- Not supported in <IE9
                    if (index !== -1) {
                        if (this.applicationsStatusSelected.length === 1) {
                            this.applicationsStatusSelected = [''];
                        } else {
                            this.applicationsStatusSelected.splice(index, 1);
                        }
                    }
                }
                this.applicationsStatus = this.applicationsStatusSelected.toString();
                this._commonService.setSelector('applicationStatus', this.applicationsStatusSelected);
                this.callCommonSubTabContent();
            }
        }
        this.tooltipApplicationSelected = this.applicationsStatusSelected?.join(', ');
    };

    productChanged = function (value) {
        if (this.lineOfBusinessFilter === 'yes') {
            let that = this;
            if (value === 'All') {
                var listOfProducts = [];
                var listOfProductValue = '';
                var productsLength = that.products.length;
                that.products.forEach(function (ele) {
                    if (productsLength > 1) {
                        listOfProducts.push(ele.category);
                        // this.param2 = 'All'; //listOfProducts;
                        that._commonService.setSelector('product', 'All');

                    } else {
                        listOfProductValue = ele.category;
                        // this.param2 = 'All'; //listOfProductValue;
                        that._commonService.setSelector('product', 'All');
                    }
                });
            } else {
                that._commonService.setSelector('product', value);
            }
        } else {
            this._commonService.setSelector('product', value);
        }
        this.showProductFilter(value);
        this._commonService.setParameters('param7', 'category');
        this.updateSubProducts(value);
    };

    filterDateTypeChangeEvent = function (value) {
        this.tab1ChartsPosValue = 0;
        this.filterDateType = value;
        // if($('#breadcrumb').length){
        // 	$('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
        // }
        this._commonService.setSelector('filterDateType', value);
    };

    updateSubProducts = (product) => {
        var obj = cloneDeep(this.allProducts);
        var subProducts = [];
        var allSubProducts: any;
        if (obj) {
            obj.filter(function (ele) {
                if (ele.category === product) {
                    allSubProducts = ele.sub_category.split(',');
                    allSubProducts.forEach(function (el) {
                        subProducts.push({
                            'text': el
                        });
                    });
                }
            });
        }
        this.allSubProducts = allSubProducts
        this.selectedSubProducts = allSubProducts;
        this.subProducts = subProducts;
        this._commonService.setParameters('subProducts', allSubProducts);
        this.callCommonSubTabContent();
    }

    convertDate = function (date) {
        var newDate = new Date(date);
        var year = newDate.getFullYear();
        var month = newDate.getMonth() + 1;
        var day = newDate.getDate();
        return year + '-' + month + '-' + day;
    };

    fromDateChanged(date) {
        this.fromDateCounter = this.fromDateCounter + 1;
        if (this.fromDateCounter > 2 && date.length != 0) {
            this.fromDate = date;
            let fromDate = this.convertDate(date);
            this._commonService.setSelector('fromDate', fromDate);
            this.callCommonSubTabContent();
        }
    }

    toDateChanged(date) {
        this.fromDateCounter = this.fromDateCounter + 1;
        if (this.fromDateCounter > 2 && date.length != 0) {
            this.toDate = date;
            let toDate = this.convertDate(date);
            this._commonService.setSelector('toDate', toDate);
            this.callCommonSubTabContent();
        }
    }

    forYearDateChanged(date) {
        var dateLength = date.getFullYear();
        this.forYearCounter = this.forYearCounter + 1;
        if (this.forYearCounter >= 2 && dateLength != 0) {
            this.forYear = date;
            let forYear = this.convertDate(date);
            this._commonService.setSelector('forYear', forYear);
            this.callCommonSubTabContent();
        }
    }

    onResetButton() {
        this._commonService.resetSelector()
        window.location.reload();
    }

    selectorClick(event, selectorname) {
        this.isFirstLoad = false;
        let selector = $('#' + selectorname).parent().hasClass('show');
        if ($('#carriers').parent().hasClass('show')) {
            setTimeout(() => {
                $('#carriers').parent().removeClass('show');
            }, 50)
        }
        if ($('#company').parent().hasClass('show')) {
            setTimeout(() => {
                $('#company').parent().removeClass('show');
            }, 50)
        }

        if ($('#policyStatusFilter').parent().hasClass('show')) {
            setTimeout(() => {
                $('#policyStatusFilter').parent().removeClass('show');
            }, 50)
        }

        if ($('#applicationStatusFilter').parent().hasClass('show')) {
            setTimeout(() => {
                $('#applicationStatusFilter').parent().removeClass('show');
            }, 50)
        }

        if ($('#agency-select').parent().hasClass('show')) {
            setTimeout(() => {
                $('#agency-select').parent().removeClass('show');
            }, 50)
        }

        if (selector) {
            $('#dropdownMenucarriers').parent().removeClass('show');
            setTimeout(() => {
                $('#' + selectorname).parent().removeClass('show');
            }, 100)
        } else {
            $('#' + selectorname).parent().removeClass('show');
            $('#' + selectorname).parent().addClass('show');
        }
        // if(selectorname == 'policyStatusFilter' && $('#policyStatusFilter').parent().hasClass('show')){
        //     let temp = document.getElementsByClassName("dropdown-menu show") as HTMLCollectionOf<HTMLElement>;
        //     temp[0].style.right = '0'; temp[0].style.left = 'auto'
        // }
    }

    onsaleAndPremiumToggle(e, toggleObj) {
        // var target = $(e.target).closest('.tab');
        // $(target).children('.toggleActive').removeClass('toggleActive');
        // $(e.target).addClass('toggleActive');
        $('.tab button').removeClass('toggleActive');
        $('.tab button[type="' + toggleObj.value + '"]').addClass('toggleActive');
        this._commonService.setSelector('salepolicytoggle', toggleObj.value);
        this.callCommonSubTabContent();
    }

    onToggle(e, toggleObj) {
        // var target = $(e.target).closest('.tab');
        // $(target).children('.toggleActive').removeClass('toggleActive');
        // $(e.target).addClass('toggleActive');
        $('.tab button').removeClass('toggleActive');
        $('.tab button[type="' + toggleObj.value + '"]').addClass('toggleActive');
        var id = parseInt($('.nav-link.active').attr('id'));
        // if (id === 1 && $('#toggleAgents .toggleActive').attr('type') === '$') {
        //     toggleObj.api = ApiConstant.getAgentDetails
        // }
        // if (id === 1 && $('#toggleAgents .toggleActive').attr('type') === '#') {
        //     toggleObj.api = ApiConstant.getAgentSummary
        // }
        // if (id === 2) {
        //     toggleObj.api = ApiConstant.getCommissionData
        // }
        if (toggleObj.api === ApiConstant.getAgentDetails || toggleObj.api === ApiConstant.getAgentApplicationDetails) {
            $('#commonSapTapToggle').hide();
            $('#breadcrumb').hide();
            $('#agentNameTooltipSpan').show();
            $('#exportAgentTabDetail, #exportAgentTabSummary').show();
            $('#agentTabExport').hide();
            // if (id === 25) {
            //     toggleObj.api = ApiConstant.getAgentApplicationDetails
            // }
            // if (id === 26) {
            //     toggleObj.api = ApiConstant.getAgentSummaryPersonalDetails
            // }
            // if (id === 33) {
            //     toggleObj.api = ApiConstant.getAgentPaymentDetails;
            // }
        }
        if (toggleObj.api === ApiConstant.getAgentSummary || toggleObj.api === ApiConstant.getAgentApplicationSummaryRollUp || toggleObj.api === ApiConstant.getAgentSummaryPersonalDetailsRollup) {
            $('#commonSapTapToggle').show();
            if ($('#breadcrumb').length) {
                $('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
                this._commonService.setParameters('breadcrumbFilters', []);
                this._commonService.setParameters('level', '');
            }
            $('#breadcrumb').show();
            $('#agentNameTooltipSpan').hide();
            $('#exportAgentTabDetail, #exportAgentTabSummary').hide();
            $('#agentTabExport').show();
            // if (id === 25) {
            //     toggleObj.api = ApiConstant.getAgentApplicationSummaryRollUp
            // }
            // if (id === 26) {
            //     toggleObj.api = ApiConstant.getAgentSummaryPersonalDetailsRollup
            // }
            // if (id === 33) {
            //     toggleObj.api = ApiConstant.getAgentPaymentSummary
            // }
        }
        this._commonService.setSelector('salepolicytoggle', $('#toggleAgents .toggleActive').attr('type'));
        this.onGetSubTabContent({});
    }

    onReportToggle(e, toggleObj) {
        let salepolicytoggle = toggleObj.value;
        $('#reportTabTapBtn .switch ').css('margin-top', '14px');
        // let target = $(e.target).closest('.tab');
        // $(target).children('.toggleActive').removeClass('toggleActive');
        // $(e.target).addClass('toggleActive');
        $('.tab button').removeClass('toggleActive');
        $('.tab button[type="' + toggleObj.value + '"]').addClass('toggleActive');
        $('#toggleReport .toggleActive').attr('type') === '$' ? salepolicytoggle = '$' : salepolicytoggle = '#';

        if (this.activeTabId === 5) {
            // this.getSubTabContent($('#pageSubTabs li.active').attr('id'), '');
            // this.callCommonSubTabContent($('#pageSubTabs li.active').attr('id'));
            if ($('#toggleReport .toggleActive').attr('type') === '$') {
                $('#reportTitle h4 b').html('Premium by Product (Monthly)');
            } else {
                $('#reportTitle h4 b').html('Policy by Product (Monthly)');
            }
        } else {
        }
        this._commonService.setSelector('salepolicytoggle', salepolicytoggle);
        this.callCommonSubTabContent();
    }

    onMedicareAdvantageChange = function (value) {
        this.medicareAdvantageFilter = {
            filterValue: value
        }
        $('#saleTypeAll, #saleTypeNewSales, #saleTypeOthers').css('color', '#333333');
        var labelId = '';
        if (value === 'All') {
            labelId = 'saleTypeAll';
        } else if (value === 'New Sales') {
            labelId = 'saleTypeNewSales';
        } else {
            labelId = 'saleTypeOthers';
        }
        $('#' + labelId).css('color', '#1ABB9A');
        this._commonService.setParameters('param12', value);
        this.updateSubProducts(value);
    };

    // temp = 0;
    callCommonSubTabContent = () => {
        // if(this.temp) return;
        // else {
        //     this.temp = 1;
        //     setTimeout(() => {
        //         this.temp = 0;
        //     }, 1000);
        // }
        this.activeTabId = this.localStorageService.get('activeTabId');
        let activeSubTab = this.localStorageService.get('activeSubTab');
        let activeSubTabId;
        if (activeSubTab && Object.keys(activeSubTab).length) {
            activeSubTabId = activeSubTab['id'];
        }
        if (this.activeTabId == 1) {
            this._commonService.resetAgencyChart.next();
        } else if (this.activeTabId !== 1) {
            if (this.activeTabId == 3 && activeSubTabId == 3) {
                this._commonService.updateYOYChart.next();
            } else if (this.activeTabId == 5 && activeSubTabId == 10) {
                this._commonService.updateStateMapChart.next();
            } else {
                if (this.activeTabId == 2) {
                    $('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
                }
                this.onGetSubTabContent({});
            }
        }
    }

    onGetSubTabContent = (obj) => {
        this._commonService.showLoader();
        this._commonService.setParameters('param5', 'All');
        this.sapAndTapObj = this._commonService.getSapOrTapToggle();
        $('app-yoycomparison').remove();
        this.activeTabId = this.localStorageService.get('activeTabId');

        if (!Object.keys(obj).length) {
            this.activeSubTab = this.localStorageService.get('activeSubTab');
            if (this.activeSubTab) {
                obj.id = this.activeSubTab['id'];
                obj.api = this.activeSubTab['api'];
            }
        } else {
            this.localStorageService.set('activeSubTab', { id: obj.id, api: obj.api });
        }
        this.activeSubTabId = obj.id;
        let featureList = obj.api ? obj.api.split(',').join() : obj;
        $('#pageSubTabs #' + obj.id).closest('li').addClass('active');
        if (this.isTAPRequired === 'yes') {
            $('#commonSapTapToggle').show();
        }
        if (this.activeTabId == 2) {
            this._commonService.updateAgentsData.next(obj);
            return;
        } else if (this.activeTabId == 3) {
            if (obj.id === 3) {
                $('#breadcrumb').hide();
                this._commonService.updateYOYChart.next();
            } else if (obj.id == 4) {
                $('#breadcrumb').show();
                if (featureList.includes(obj.api)) {

                    this.api_name = ApiConstant.getAgencyAnalyticsReport;
                    this.id = "table2";
                    this.fileNameToExport = '';
                }
            } else if (obj.id == 5) {
                if (featureList.includes(ApiConstant.getHighPerformers) || featureList.includes(ApiConstant.getHighPerformersTap)) {
                    if (this.isTAPRequired === 'yes' && this.sapAndTapObj[ApiConstant.analyticsTabTapValue] === 'tap') {
                        this.api_name = ApiConstant.getHighPerformersTap;
                    } else {
                        this.api_name = ApiConstant.getHighPerformers;
                    }
                    this.id = "table2";
                    this.fileNameToExport = 'High Performers';
                }

            } else if (obj.id == 6) {
                if (featureList.includes(ApiConstant.getLowPerformers) || featureList.includes(ApiConstant.getLowPerformersTap)) {
                    if (this.isTAPRequired === 'yes' && this.sapAndTapObj[ApiConstant.analyticsTabTapValue] === 'tap') {
                        this.api_name = ApiConstant.getLowPerformersTap;
                    } else {
                        this.api_name = ApiConstant.getLowPerformers;
                    }
                    this.id = "table2";
                    this.fileNameToExport = 'Low Performers';
                }
            } else if (obj.id == 7) {
                if (featureList.includes(ApiConstant.getPendingByAgent) || featureList.includes(ApiConstant.getPendingByAgentTap)) {
                    if (this.isTAPRequired === 'yes' && this.sapAndTapObj[ApiConstant.analyticsTabTapValue] === 'tap') {
                        this.api_name = ApiConstant.getPendingByAgentTap;
                    } else {
                        this.api_name = ApiConstant.getPendingByAgent;
                    }
                    this.id = "table2";
                    this.fileNameToExport = 'Pending Policies';
                }
            } else if (obj.id == 8) {
                if (featureList.includes(ApiConstant.getLapsedPolicies) || featureList.includes(ApiConstant.getLapsedPoliciesTap)) {
                    if (this.isTAPRequired === 'yes' && this.sapAndTapObj[ApiConstant.analyticsTabTapValue] === 'tap') {
                        this.api_name = ApiConstant.getLapsedPoliciesTap;
                    } else {
                        this.api_name = ApiConstant.getLapsedPolicies;
                    }
                    this.id = "table2";
                    this.fileNameToExport = 'Lapsed Policies';
                }
            }
        } else if (this.activeTabId == 4) {
            this._commonService.updateAlertsData.next();
        } else if (this.activeTabId == 6) {
            this.api_name = ApiConstant.getMissingAgentDetails;
            this.id = "table2";
            this.fileNameToExport = 'Missing Agents';
        } else if (this.activeTabId == 5) {
            this._commonService.updateReportsData.next();
        } else if (this.activeTabId == 6) {
            if (obj.id == 24) {
                if (featureList.includes(ApiConstant.getMissingAgentDetails)) {
                    this.api_name = ApiConstant.getMissingAgentDetails;
                    this.id = "table2";
                    this.fileNameToExport = 'Missing Agents';
                    this.columnDefs = [];
                }
            }
        } else if (this.activeTabId == 7) {
            this.loadTable = false;
            let tabObject = this.tabsData.filter(ele => { return ele.tab_id == this.activeTabId })
            if (tabObject.length) {
                let subTabObj = tabObject[0]['subTabs'];
                let activeSubTab = subTabObj.filter(ele => { return ele.sub_tab_id == obj.id });
            }
        }
        if (this.activeTabId != 7 && obj.id != 3 && obj.id != 16 && obj.id != 10) {  // 3 = YOYComparision , 10 = report map data
            //   if(this.api_name != ApiConstant.getIncentivePrograms){
            this.subTabComplete = true;
            obj.api = this.api_name;
            if (this.activeTabId !== 4 && this.activeTabId !== 5) {
                if (obj.id == 13) {
                    this.callTableComponent(obj);
                } else {
                    this.callTableComponent(obj);
                }
            }
            //   }
        }
    }

    callTableComponent = (obj) => {
        this.tableService.setTableConfigObj({
            tabId: this.activeTabId,
            title: this.title,
            apiName: this.api_name,
            id: this.id,
            fileNameToExport: this.fileNameToExport,
            columnDefs: this.columnDefs
        });
        this._commonService.updateTableData.next();
    }

    showProductFilter(value?: string) {
        this.activeTabId; this.activeSubTabId;
        if ((this.medicareAdvantageSaleType && this.medicareAdvantageSaleType.toLowerCase() === 'yes' && value === 'Medicare Advantage') || (this.medicareAdvantageSaleType && this.medicareAdvantageSaleType.toLowerCase() === 'yes' && value === 'Med Adv')) {
            if ((this.activeTabId === 2 && this.activeSubTabId === 25 && this.isApplicationsApplicable === 'yes') || (this.activeTabId === 4 && this.activeSubTabId === 25 && this.isApplicationsApplicable === 'yes')) {
                $('#medicareAdvantageFilterContainer').hide();
            } else {
                $('#medicareAdvantageFilterContainer').show();
            }
            this.medicareAdvantageFilter = {
                filterValue: 'All'
            }
            $('#saleTypeAll').css('color', '#1ABB9A');
            this._commonService.setParameters('param12', 'All');
        } else {
            setTimeout(() => {
                // console.log($('#medicareAdvantageFilterContainer'));
                $('#medicareAdvantageFilterContainer').hide()
            }, 0)
            this._commonService.setParameters('param12', 'All');
            $('#saleTypeAll').css('color', '#333333');
        }
    }

    showhideMedicareAdvantage(value?: string) {
        this.activeTabId; this.activeSubTabId;
        if ((this.medicareAdvantageSaleType && this.medicareAdvantageSaleType.toLowerCase() === 'yes' && value === 'Medicare Advantage') || (this.medicareAdvantageSaleType && this.medicareAdvantageSaleType.toLowerCase() === 'yes' && value === 'Med Adv')) {
            if ((this.activeTabId === 2 && this.activeSubTabId === 25 && this.isApplicationsApplicable === 'yes') || (this.activeTabId === 4 && this.activeSubTabId === 25 && this.isApplicationsApplicable === 'yes')) {
                $('#medicareAdvantageFilterContainer').hide();
            } else {
                $('#medicareAdvantageFilterContainer').show();
            }
        } else {
            $('#medicareAdvantageFilterContainer').hide()
        }
    }

    hideEmptySElectorGroup() {
        let abc: any = document.getElementsByClassName("selectorGroup");
        let breaker = true;
        for (let i = 0; i < abc.length; i++) {
            let children = abc[i].children[0].children;
            for (let x = 0; x < children.length; x++) {
                let child: any = children[x];
                if (child.style.display != "none") {
                    breaker = true;
                    break;
                } else { breaker = false }
            }
            if (breaker == true) {
                abc[i].style.display = "block";
                continue;
            }
            abc[i].style.display = "none"; // Comment out to see effect
        }
    }

    filterClass(selectorId) {
        switch (selectorId) {
            case 'company':
                return this.show_company.className;
            case 'carriers':
                return this.show_carrier.className;
            case 'agency-select':
                return this.show_agency.className;
            case 'policyStatusFilter':
                return this.show_policy_status.className;
            case 'applicationStatusFilter':
                return this.show_application_status.className;
            case 'lineOfBusinessFilter':
                return this.show_lob?.className;
            case 'productFilter':
                return this.show_product_type?.className;
            case 'filterByDateFilter':
                return this.show_filter_by?.className;
            case 'fromDate':
                return this.show_from_date?.className;
            case 'toDate':
                return this.show_to_date?.className;
            case 'forYear':
                return this.show_for_year?.className;
            default:
                return ''
        }
    }

    showFilters(selectorId) {
        switch (selectorId) {
            case 'company':
                return this.show_company?.show;
            case 'carriers':
                return this.show_carrier?.show;
            case 'agency-select':
                return this.show_agency?.show;
            case 'policyStatusFilter':
                return this.show_policy_status?.show;
            case 'applicationStatusFilter':
                return this.show_application_status?.show;
            case 'lineOfBusinessFilter':
                return this.show_lob?.show;
            case 'productFilter':
                return this.show_product_type?.show;
            case 'filterByDateFilter':
                return this.show_filter_by?.show;
            case 'fromDate':
                return this.show_from_date?.show;
            case 'toDate':
                return this.show_to_date?.show;
            case 'forYear':
                return this.show_for_year?.show;
            default:
                return false
        }
    }

    calculateDisplay(index, selectorId) {
        let className = this.showFilters(selectorId) ? 'show-on-display ' : 'hide-on-display ';
        return index == 3 ? 'col-4 ' + className + selectorId + '_container' : 'col-12 ' + className + selectorId + '_container';
    }

    getSaleTypeList() {
        this._commonService.getSaleTypeList().subscribe(res => {
            this.saleTypeList = res;
        })
    }

    getSelectorTooltip(tooltipArray) {
        if(tooltipArray) {
            return tooltipArray.join(', ');
        }
    }

    show_block(id) {
        if(id == 'policyStatusFilter' && this.show_policy_status?.show == false) {
            return {
                'display': 'none'
            }
        }
        return '';
    }
}
