import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ModalService } from './modal.service';
import { LocalstorageService } from './../services/localstorage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './../../shared/services/common.service'
import { configJson } from '../../shared/services/dashboard-config.service';

@Component({
  selector: 'app-performance-modal',
  templateUrl: './performance-modal.component.html',
  styleUrls: ['./performance-modal.component.scss']
})
export class PerformanceModalComponent implements OnInit {

    @Input() performanceModalObj: object;
    activeTabId: number;
    clientType: any;
    agentDetailsArr: any;
    agentDetailsObject: any;
    agentMoreDetails: boolean;
    chartInfo: any;

    constructor(
        public commonService: CommonService,
        private localStorageService: LocalstorageService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
        console.log('performanceModalObj', this.performanceModalObj);
        this.clientType = this.performanceModalObj['clientType'];
        this.activeTabId = this.localStorageService.get('activeTabId');
        this.getAgentPersonalDetails();
    }

    getAgentPersonalDetails = () => {
      let params = this.commonService.getParameters();
        console.log('params', params);
        let reqParams = {
            tabId: this.activeTabId,
            agentId: this.performanceModalObj['id'],
            agentMarketingCompany: this.performanceModalObj['agentMarketingCompany'],
            clientType: this.performanceModalObj['clientType'],
            attribute1: params['attribute1'],
            param5: this.performanceModalObj['awnHiddenId'],
            url: 'getAgentOrAgencyPersonalDetails',
            level: this.performanceModalObj['level'],
            idType: this.performanceModalObj['idType']
        }
        this.commonService.getAgentPersonalDetails(reqParams).subscribe(res => {
            console.log('res', res);
            this.commonService.hideLoader();
            this.agentDetailsArr = res;
            this.agentDetailsObject = res[0];
        });

    }

}
