export class CommonConstant {
    public static filterDateTypes = [{
        category: 'Filter Date'
    },
    {
        category: 'Issue Date'
    },
    {
        category: 'Effective Date'
    },
    {
        category: 'Paid To Date'
    },
    {
        category: 'Entry Date'
    }
    ];

    public static parameterMappingObj = {
        'param1':'agencyFilter', //NOTE: param1 now denotes agency filters
        'param2':'product',
        'param3':'fromDate',
        'param4':'toDate',
        'policyStatus':'policyStatus',
        'applicationStatus':'applicationStatus',
        'attribute1':'marketerCompany',
        'filterDateType': 'filterDateType',
        'forYear': 'forYear',
        'salepolicytoggle': 'salepolicytoggle',
        'lob': 'lob',
        'agencyFilters': 'agencyFilters',
        'carrierFilter': 'carrierFilter' // NOTE: carrier filter denotes list of carriers
    };

    public static sapOrTapToggle = {
        'byProductTapValue': 'sap',
        'byCarrierTapValue': 'sap',
        'mapDataTapValue': 'sap',
        'agentsTabTapValue': 'sap',
        'analyticsTabTapValue': 'sap',
        'reportsTabTapValue': 'sap',
        'stackedPlacedToggleVal': 'submitted',
        'multiBarPlacedToggleVal': 'submitted',
        'mapPlacedToggleVal': 'submitted'
    }

    public static lockedMessage: string = 'User is locked. Please contact your Helpdesk. Your account will be unlocked after 15 mins.';
    public static npnLockedMessage: string = 'Your Account is now locked. Please contact your Helpdesk or wait 15 minutes to try again.';
    public static invalidLoginAttemptMsg: string =  'You have exceeded the limit of invalid login attempts(3). Please enter the OTP sent to your email id.';
    public static accountVerificationMsg: string = 'Please verify your account with your registered email address prior to logging in with your NPN.';
    public static accountVerifiedMsg: string = 'Thank you for verifying your account. You may now access AgentVizion2GO.';
    public static enterOTPMsg: string = 'Please enter OTP sent to your email id for login.';
    public static enterNPNMsg: string = 'Please enter valid NPN';
}
