<div class="row mx-auto py-2 justify-content-center align-items-center" style="background: rgb(243, 243, 243);">
    <div class="col-6">
        <div class="titleWrapper">
            <div>
                <img src="assets/{{logo}}" class="user-avatar logo w-100" style="max-width: 100px; border-radius: 10px;">
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="footer ng-scope" style="width: 95%; margin: 5px 5% 0; text-align: right;">
            <a target="_blank" href="http://www.magnifact.com/"><span>
                <img src="assets/images/NHMLogo.png" class="w-100" style="max-width: 200px;">
            </span></a>
        </div>
    </div>
</div>