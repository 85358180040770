import { Component, Input, OnInit, Output } from '@angular/core';
import { configJson } from '../shared/services/dashboard-config.service';
declare var $: any;
import { CommonService } from './../shared/services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { iAVColumnConfig, iAVPaginationConfig } from 'av-table/lib/av-table-data.model';
import { HttpErrorResponse } from '@angular/common/http';
import { DataLoadStatusService } from './data-load-status.service';

@Component({
  selector: 'app-data-load-status',
  templateUrl: './data-load-status.component.html',
  styleUrls: ['./data-load-status.component.scss']
})
export class DataLoadStatusComponent implements OnInit {
  @Input() my_table_modal_object;
  defaulttableConfig: any;

  userInfo: object;
  activeTabId: number;
  userRole: string = '';
  clientType: string = '';
  fileExportName: string = '';
  configJsonData: object;

  getParameters: object = {};
  paginationConfig: iAVPaginationConfig = {
    currentRecordStart: 1, currentRecordEnd: 100, offset: 100, totalRecords: 100
  };

  reqParams: any = this._commonService.getParameters();
  rowData = [];
  statusText = [];
  tab_api_name;
  columnConfig: iAVColumnConfig[] = [];
  
  constructor(
    public activeModal: NgbActiveModal,
    private _commonService: CommonService,
    private _dataLoadStatusService: DataLoadStatusService
  ) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userRole = this.userInfo['role'];
    this.columnConfig = configJson['tables'][this.my_table_modal_object.configName].columnConfigs;
    this.tab_api_name = configJson['tables'][this.my_table_modal_object.configName].api_name;
    this.statusText = configJson['tables']?.[this.my_table_modal_object.configName]?.statusColorTexts ? configJson['tables'][this.my_table_modal_object.configName].statusColorTexts : [];
    this.reqParams.param10 = ["production","RTS"];
    this.reqParams.searchObj = [];
    this.getTableModalData();
  }

  getTableModalData() {
    let params = this.reqParams;
    this._commonService.showLoader();
    this._commonService.getTabelDataLoad({ params }, this.tab_api_name, this.my_table_modal_object.sameUrl).subscribe((res: any) => {
      if (res.data) {
        this.rowData = res.data;
        if (this.rowData?.length) {
          this.columnConfig = Object.keys(this.rowData[0]).map(e => {
            let config = this.columnConfig?.find(x => x.columnName == e);
            let a: iAVColumnConfig = {
              columnName: e,
              sortable: config?.sortable || config?.sortable == false ? config?.sortable : true,
              sortColumDirection: '',
              serverSideSort: config?.serverSideSort ? config.sortable : true,
              serverSideFilter: config?.serverSideSort ? config.sortable : true,
              clickEventRequired: config?.serverSideSort ? config.sortable : true,
              align: config?.align ? config.align : 'text-start',
              dataType: config?.dataType ? config.dataType : 'text', //"text" | "number" | "boolean" | "currency",
              clickable: config?.clickable ? config.sortable : true,
              applyFilter: config?.applyFilter ? config.applyFilter : false,
              display: config?.display || config?.display == false ? config.display : true
            }
            if (e == params.column) {
              a.sortColumDirection = this.reqParams.columnOrderType
            }
            if (e.toLowerCase() == "data health"){
              a.dataType = 'status'
              a.align = 'text-center'
              a.statusText = this.statusText
            }
           
            return a;
          })
        }
        this.paginationConfig.totalRecords = res.totalCount[0]?.count;
        this._commonService.hideLoader();
      }
    }, (err: HttpErrorResponse) => {
      this._commonService.hideLoader();
    })
  }


  searchEvent($event) {
    let searchObj = this.reqParams.searchObj.find(e => e.column == $event.columnName);
    if (searchObj) {
      if ($event.searchString) {
        searchObj.value = $event.searchString;
      } else {
        this.reqParams.searchObj = this.reqParams.searchObj.filter(e => e.column != $event.columnName)
      }
    } else {
      if ($event.searchString) {
        this.reqParams.searchObj.push({ column: $event.columnName, value: $event.searchString });
      }
    }
    this.getTableModalData();
  }

  sortEvent($event) {
    this.reqParams.column = $event?.direction ? $event?.columnName : '';
    this.reqParams.columnOrderType = $event?.direction;
    this.getTableModalData();
  }

  clickEvent($event) {
    console.log('clickEvent', $event);
  }

  paginatorClickEvent($event) {
    console.log('paginatorClickEvent', $event);
    switch ($event?.buttonClicked) {
      case 'next':
        this.reqParams.pageNo = this.paginationConfig.currentRecordStart + this.paginationConfig.offset - 1;
        this.paginationConfig.currentRecordStart = this.paginationConfig.currentRecordStart + this.paginationConfig.offset;
        this.paginationConfig.currentRecordEnd = this.paginationConfig.currentRecordEnd + this.paginationConfig.offset;
        this.getTableModalData();
        break;
      case 'previous':
        this.reqParams.pageNo = this.paginationConfig.currentRecordStart - this.paginationConfig.offset - 1;
        this.paginationConfig.currentRecordStart = this.paginationConfig.currentRecordStart - this.paginationConfig.offset;
        this.paginationConfig.currentRecordEnd = this.paginationConfig.currentRecordStart + this.paginationConfig.offset - 1;
        this.getTableModalData();
        break;
      case 'last':
        let pageNo = parseInt((this.paginationConfig.totalRecords / this.paginationConfig.offset).toString()) * this.paginationConfig.offset;
        this.reqParams.pageNo = pageNo;
        this.paginationConfig.currentRecordStart = pageNo + 1;
        this.paginationConfig.currentRecordEnd = this.paginationConfig.totalRecords;
        this.getTableModalData();
        break;
      case 'first':
        this.reqParams.pageNo = 0;
        this.paginationConfig.currentRecordStart = 1;
        this.paginationConfig.currentRecordEnd = this.paginationConfig.offset;
        this.getTableModalData();
        break;
      default:
        break;
    }
  }

  onMouseover = (e) => {
    // setTimeout(()=>{
    // }, 0)
  }

  onMouseout = (e) => {
    // setTimeout(()=>{
    //   $('.dropdown').removeClass('open');
    // }, 500);
  }

  closeCustom = () => {
    $('.mapTable .col-search-input').css('display','none');
    let params = this.reqParams;
    params['exportExcel'] = false;
    params.param10 = "";
    this.activeModal.close();
  }

  onExportCsv = () =>{
    let params = this.reqParams;
    params['exportExcel'] = true
    params.param10 = ["production","RTS"];
    params.searchObj = [];
    let fileName = this.my_table_modal_object.title+".csv";
    this._dataLoadStatusService.exportCsv({params}, this.tab_api_name, fileName, this.my_table_modal_object);
  }

}
