<div #footer class="footer mx-0 footer-style px-2 w-100">
    <div class="px-1 footer-logo-parent-container"> 
        <div class="footer-logo-container d-flex align-items-end m-auto">
            <a class="" target="_blank" href="https://www.magnifact.com/">
                <img src="assets/images/AgentVizion.png" class="background-white" style="width: 70px;">
            </a>
            <a class="" target="_blank" href="https://www.magnifact.com/">
                <img src="assets/images/NHMLogo.png" class="background-white" style="width: 130px;">
            </a>
        </div>
    </div>
    <div class="text text-justify footer-copyright-text-container">
        <div>
            <small>AgentVizion is a registered trademark of Magnifact, LLC., and is protected by U.S. Patent D917,520. Any and all material in this product and related documentation is deemed the intellectual property of Magnifact, LLC., and contains information which is privileged, confidential and prohibited from disclosure or unauthorized use under applicable law.</small>
        </div>
        <div class="appVersion top-border"><strong>Version: {{app_version}} | U.S. Patent D917,520</strong></div>
    </div>
</div>
