import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { NgForm } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification/notification.service';
declare var $:any;

@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss']
  })
  
  export class CardsComponent implements OnInit {
    cardData: any = {
        card_number: '',
        card_cvv: '',
        exp_month: '',
        exp_year: ''
    };
    isVisa:boolean = false;
    isMasterCard:boolean = false;
    userInfo: object;

    @Input() registrationDetails: any;

    constructor(private authService: AuthService, private flashMessagesService: FlashMessagesService, private _notificationSvc: NotificationService) {}

    ngOnInit() {
        this.isVisa = false;
        this.isMasterCard = false;
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }

    creditCardNumberSpacing(value) {
        let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        let matches = v.match(/\d{4,16}/g);
        let match = matches && matches[0] || ''
        let parts = []
        let visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
        let mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
        // let amexpRegEx = /^(?:3[47][0-9]{13})$/;
        // let discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
        for (let i=0, len=match.length; i<len; i+=4) {
            parts.push(match.substring(i, i+4))
        }
        if(visaRegEx.test(value.replace(/\s/g, ""))) {
            this.isVisa = true;
            this.isMasterCard = false;
        }
        if(mastercardRegEx.test(value.replace(/\s/g, ""))) {
            this.isMasterCard = true;
            this.isVisa = false;
        }
        if (parts.length) {
            value = parts.join(" ");
            this.cardData.card_number = value
        }
    }

    submitCardForm(form: NgForm) {
        const data = {
            card_number: this.cardData.card_number.replace(/\s/g, ""),
            card_cvv: this.cardData.card_cvv,
            exp_month: this.cardData.exp_month,
            exp_year: this.cardData.exp_year
          };
        //   console.log("data", data);
        const formData = new FormData();
        formData.append('cardData', JSON.stringify(data));
        this.authService.updateCardDetails(formData).subscribe(res=>{
            // console.log("res", res);
            $('#cardDetailsModal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            form.resetForm();
            this.cardData.card_number = '';
            this._notificationSvc.success('Success', 'Your card details updated successfully!', 2000);
            // this.flashMessagesService.show('Your card details updated successfully!', { cssClass: 'alert-success', timeout: 2000, closeOnClick:true, showCloseBtn: true });
        }, err=>{
            this.cardData.card_number = '';
            this._notificationSvc.error('Error', err.error.err?.message ?? err.error.err, 3000);
        }
        )
    }
  }