import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot   } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from '../shared/services/common.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  subscription : Subscription;
  SSOLogin: string;
  constructor(private authService : AuthService, private router : Router,  private commonService: CommonService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
 
    let promise:boolean = await new Promise((resolve, reject) => {

      this.authService.isAuthenticated(route).then(res=>{
          this.commonService.setActiveTabData(state.url);
          // logged in so return true
          resolve(true);
      }).catch(res=>{
         //  not logged in so redirect to login page with the return url and return false
          this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
          resolve(false);
      });
    });
    return promise;
  }
}	