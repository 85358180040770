import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { DataTablesModule } from 'angular-datatables';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from './modal/modal.service';
import { TableModalComponent} from './table-modal/table-modal.component';
import { NotificationComponent } from './notification/notification.component';
import { IncentivePointsModalComponent } from './incentive-points-modal/incentive-points-modal.component';
import {WeeklyFastStartBonusModalComponent} from './components/weekly-fast-start-bonus-modal/weekly-fast-start-bonus-modal.component'
import { PerformanceModalComponent } from './performance-modal/performance-modal.component';

@NgModule({
  declarations: [
  //  ModalComponent,
    TableModalComponent,
    NotificationComponent,
    IncentivePointsModalComponent,
    WeeklyFastStartBonusModalComponent,
    PerformanceModalComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    DataTablesModule.forRoot(),
    NgbModule,
    FlashMessagesModule.forRoot()
  ],
  exports: [
    TableModalComponent,
    NotificationComponent,
    IncentivePointsModalComponent,
    WeeklyFastStartBonusModalComponent,
    PerformanceModalComponent
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ModalService]
})
export class SharedModule { }
