<div *ngIf="my_table_modal_object.showModal">
	<div class="modal-header">
		<h4 class="modal-title modal-title-name" style="width:100%;margin-left: 8px;">{{my_table_modal_object.title}}
		</h4>
		<div>
			<div class="dropdown dropleft export cursor-pointer">
				<svg id="dropdownMenuButton" (mouseover)="onMouseover($event)" (mouseleave)="onMouseout($event)"
					data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="16"
					height="16" fill="currentColor" class="bi bi-three-dots-vertical three_dots dropdown-toggle"
					viewBox="0 0 16 16">
					<path
						d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
				</svg>
				<div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
					<div class="export-style" (click)="onExportCsv()"> <i class="fa fa-file"></i> Export</div>
				</div>
			</div>
		</div>
		<button type="button" aria-label="Close" class="btn-close" (click)="closeCustom()"><i class="fa fa-times" aria-hidden="true"></i></button>
	</div>
	<div class="modal-body mainpanal" style="margin:0 !important;padding-top: 2px;">
		<app-av-table [data]="rowData" [columnConfig]="columnConfig" (searchEvent)="searchEvent($event)"
			(sortEvent)="sortEvent($event)" (cellClickEvent)="clickEvent($event)"></app-av-table>
		<app-av-paginator [paginationConfig]="paginationConfig"
			(pageUpdateEvent)="paginatorClickEvent($event)"></app-av-paginator>
	</div>
</div>

<div *ngIf="!my_table_modal_object.showModal">
	<app-av-table [data]="rowData" [columnConfig]="columnConfig" (searchEvent)="searchEvent($event)"
			(sortEvent)="sortEvent($event)" (cellClickEvent)="clickEvent($event)"></app-av-table>
	<app-av-paginator [paginationConfig]="paginationConfig"
			(pageUpdateEvent)="paginatorClickEvent($event)"></app-av-paginator>
</div>