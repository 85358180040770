import { Component, OnInit, Output, Input ,EventEmitter, Inject, AfterViewInit } from '@angular/core';
// import { DOCUMENT } from '@angular/platform-browser';
// import { DOCUMENT } from '@angular/common';
import { CommonModule } from '@angular/common';
import {ModalService} from './modal.service';
import { LocalstorageService } from './../services/localstorage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { configJson } from '../../shared/services/dashboard-config.service';
import { CommonService } from './../../shared/services/common.service';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit, AfterViewInit{
    // private ngUnsubscribe: Subject<any> = new Subject();
    @Input() my_modal_title: string;
    @Input() my_modal_popupObj: object;
    @Input() my_modal_content:Object;
    @Input() baseTablePrameterObj: Object;
    chartInfo: any;
    activeTabId: number;
    agentDetailsArr: any;
    agentDetailsObject: any;
    clientType: any;
    agentMoreDetails: boolean;
    loadChart: boolean = false;;
    // loadChart: boolean = false;

    constructor(
        public commonService: CommonService,
        private localStorageService: LocalstorageService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(){
        this.commonService.showLoader();
        console.log('my_modal_popupObj', this.chartInfo, this.my_modal_popupObj);
        this.clientType = this.my_modal_popupObj['clientType'];
        this.chartInfo = configJson.charts;
        // this.chartInfo = [{
        //     "title": {
        //         "$": "Premium Timeline by Product Line",
        //         "#": "Policy YOY Comparison"
        //     },
        //     "chart_type": "YOYBarChart",
        //     "api_name": "getAgencyYearOnYearComparision",
        //     "index": 1,
        //     "showArea": false,
        //     "chart_id": "chart1",
        //     "xaxis_axislabel": "Year",
        //     "height": 300,
        //     "orient": "bottom",
        //     "rotateLabels": -45,
        //     "ticks": 12,
        //     "showMaxMin": false,
        //     "tickValues": "response[0].categories",
        //     "yaxis_axislabel": "Loss Ratio %",
        //     "axisLabelDistance": "",
        //     "margin": {
        //         "top": 20,
        //         "right": 20,
        //         "bottom": 55,
        //         "left": 60
        //     },
        //     "tabId": "tab2",
        //     "role": ["Agent", "Manager", "level_1", "level_2", "level_3", "level_4", "level_5", "level_6", "level_7"]
        // }];
        setTimeout(() => {
            this.chartInfo = this.my_modal_popupObj['chartInfo'];
        }, 1000);

        this.activeTabId = this.localStorageService.get('activeTabId');

        this.getAgentPersonalDetails();
        // getMissingAgentPersonalDetails -- agentid pass
    }
    ngAfterViewInit(){
        setTimeout(function(){
            this.loadChart = true;
        },100)
    }

    getAgentPersonalDetails = () => {
        let params = this.commonService.getParameters();
        console.log('params', params);
        let reqParams = {
            tabId: this.activeTabId,
            agentId: this.my_modal_popupObj['awnHiddenId'],
            agentMarketingCompany: this.my_modal_popupObj['agentMarketingCompany'],
            clientType: this.my_modal_popupObj['clientType'],
            attribute1: params['attribute1'],
            params2: params['param2'],
            url: 'getAgentPersonalDetails'
        }
        if(this.activeTabId === 6){
            reqParams = {
                tabId: this.activeTabId,
                agentId: this.my_modal_popupObj['id'],
                agentMarketingCompany: this.my_modal_popupObj['agentMarketingCompany'],
                clientType: this.my_modal_popupObj['clientType'],
                attribute1: params['attribute1'],
                params2: params['param2'],
                url: 'getMissingAgentPersonalDetails'
            }
        }

        this.commonService.getAgentPersonalDetails(reqParams).subscribe(res => {
            console.log('res', res);
             this.commonService.hideLoader();
            this.agentDetailsArr = res;
            this.agentDetailsObject = res[0];
        });
    }


    closeCustom = () => {
        this.activeModal.close();
        this.commonService.parametersObj = this.baseTablePrameterObj;
        // this.commonService.setParameters('param5', 'All');
        // this.commonService.setParameters('idType', 'crm');
    }

}