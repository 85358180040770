import { HostListener, Component, OnInit, Input, ViewChild, Output, EventEmitter, ComponentRef, ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
declare var $: any;
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { configJson } from '../../shared/services/dashboard-config.service';
import { CommonService } from './../../shared/services/common.service';
import { ApiConstant } from './../../shared/constants/api-constant';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { LocalstorageService } from './../services/localstorage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from '../components/table/table.service';
import { TableComponent } from '../components/table/table.component';
import { ChartsService } from '../components/chart/charts.service';
import { ExportService } from '../services/export.service';
import { forkJoin } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-table-modal',
    templateUrl: './table-modal.component.html',
    styleUrls: ['./table-modal.component.scss']
})
export class TableModalComponent implements OnInit {
    @Input() my_table_modal_object: string;
    @Input() baseTablePrameterObj: object;
    userInfo: any;
    companyFilter: string;
    showOverride: number
    isTAPRequired: string;
    userRole: string;
    activeTabId: number;
    activeSubTabId: number = 0;
    product_count_arr: any;
    my_modal_title: string;
    tableId: string;
    apiName: string;
    agentId: any;
    awnHiddenId: any;
    idType: string;
    month: string;
    level: string;
    activeAgentTab: number;
    crmId: string = '';
    appId: any; //application app id column popup related
    infoAndUnderwritingInformation: any;
    agentInformation: any;
    applicationDetailInfo: any;
    popupAgentInformation: any;
    popupAgentInformationFlag: boolean = false;
    infoAndUnderwritingInformationFlag: boolean = false;
    declinedReasonValues: any;
    isApplicationsApplicable: string;
    params: { [key: string]: any };
    policyCaseInformation = [];
    requirementsInformation = [];
    showManualUnderWriting: boolean = true;

    constructor(
        public activeModal: NgbActiveModal,
        private commonService: CommonService,
        private localStorageService: LocalstorageService,
        private modalService: NgbModal,
        private http: HttpClient,
        private renderer: Renderer2,
        private elmRef: ElementRef,
        private spinner: NgxSpinnerService,
        private tableService: TableService,
        private exportService: ExportService,
        private chartsService: ChartsService
    ) { }

    ngOnInit(): void {

        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.companyFilter = this.userInfo['companyFilter'];
        this.showOverride = this.userInfo['show_override'];
        this.isTAPRequired = this.userInfo['isTAPRequired'];
        this.isApplicationsApplicable = this.userInfo['isApplicationsApplicable'];
        this.userRole = this.userInfo['role'];
        this.declinedReasonValues = this.userInfo['declinedReasonValues'];
        $(document).ready(function () {
            $('.dropdown-toggle').dropdown();
        });

        this.commonService.showLoader();
        this.activeTabId = this.localStorageService.get('activeTabId');
        let activeSubTab = this.localStorageService.get('activeSubTab');
        if (activeSubTab && Object.keys(activeSubTab).length) {
            this.activeSubTabId = activeSubTab['id'];
        }
        this.my_modal_title = this.my_table_modal_object['title'];
        this.tableId = 'mapPopupTable';
        this.apiName = this.my_table_modal_object['apiName'];
        this.level = this.my_table_modal_object['level'];
        this.agentId = this.my_table_modal_object['agentid'];
        this.awnHiddenId = this.my_table_modal_object['awnHiddenId'];
        this.idType = this.my_table_modal_object['idType'] ? this.my_table_modal_object['idType'] : 'agent';
        this.month = this.my_table_modal_object['month'];
        this.crmId = 'AgentVizion Id';
        this.activeAgentTab = this.my_table_modal_object['activeAgentTab'];
        this.tableService.setTableConfigObj({
            tabId: this.activeTabId,
            title: '',
            apiName: this.apiName,
            id: this.tableId,
            fileNameToExport: '',
            columnDefs: []
        });

        if (this.commonService.getParameters()['temp_level']) {
            let temp = this.commonService.getParameters()['temp_level'];
            this.commonService.setParameters('level', temp),
                this.commonService.setParameters('temp_level', undefined);
        }
        this.getProductSummary(this.apiName);
        let myCompOneObj = new TableComponent(this.commonService, this.modalService, this.localStorageService, this.spinner, this.tableService, this.renderer, this.chartsService);
        let params: object = { ...this.commonService.getParameters() };
        let tableConfig = this.tableService.getTableConfigObj();
        tableConfig['apiName'] = this.apiName;
        params['param5'] = this.agentId;
        params['idType'] = this.idType;
        params['month'] = this.month;
        params['level'] = this.level;
        if (this.activeTabId === 3 && (this.activeSubTabId === 5 || this.activeSubTabId === 6 || this.activeSubTabId === 7 || this.activeSubTabId === 8)) {
            params['param5'] = this.awnHiddenId;
            params['contractDateInterval'] = '';
        }
        if (this.activeTabId === 5) {
            if (this.activeSubTabId === 13) {
                params['param5'] = this.awnHiddenId;
            }
            if (this.activeSubTabId === 22) {
                if (this.level === 'agent') {
                    params['param5'] = this.awnHiddenId;
                } else {
                    params['param5'] = this.awnHiddenId;
                }
            }
            if (this.activeSubTabId === 28) {
                params['program'] = $('#incentivePrograms .active #incentiveProgramname').text();
                tableConfig['width'] = '100%';
            }
            if (this.activeSubTabId == 27) {
                params['policyStatus'] = 'Active';
                params['filterDateType'] = 'Entry Date';
            }

        }

        if (this.activeTabId === 2) {
            if (this.apiName === ApiConstant.getPendingPolicies || this.apiName === ApiConstant.getPolicies) {
                params['param5'] = this.awnHiddenId;
            }
            if (this.apiName === ApiConstant.getTapPolicies) {
                params['param5'] = this.agentId;
            }
        }

        if (this.activeTabId === 1) {
            tableConfig['width'] = '100%';
            params['param5'] = 'All';
            if (this.apiName === ApiConstant.getStateSaleData || this.apiName === ApiConstant.getStatePoliciesData) {
                params['param6'] = this.my_table_modal_object['id'] ? this.my_table_modal_object['id'] : '';
                //tableConfig['columnDefs'] = [{ 'className': 'text-end', 'targets': [1,2] }];
            }
        }
        if (this.apiName === ApiConstant.getDebtReportPopup) {
            params['columnType'] = this.my_table_modal_object['columnType'];
        }
        params['column'] = '';
        if (this.apiName === ApiConstant.getApplications) {
            if ($('#toggleAgents .toggleActive').attr('type') === '$') {
                params['param5'] = this.awnHiddenId;
            } else if ($('#toggleAgents .toggleActive').attr('type') === '#' || $('#breadcrumb li').length > 1) {
                params['param5'] = this.awnHiddenId;
            } else {
                params['param5'] = this.agentId;
            }
        }
        if (this.apiName === ApiConstant.getLifeAndAnnuityPolicies) {
            params['param5'] = 'All';
        }
        if (this.apiName === ApiConstant.getStatePoliciesDataReport || this.apiName === ApiConstant.getStateSaleDataReport) {
            params['param6'] = this.my_table_modal_object['id'];
            params['param5'] = 'All';
        }
        if (this.apiName === ApiConstant.getCommissionStatements) {
            params['param5'] = this.awnHiddenId;
        }

        if (this.apiName === ApiConstant.getAgentReportPolicies) {
            params['contractDateInterval'] = '';
            if ($('#top25monthFilter').length === 1) {
                let contractDateInterval = $('#top25monthFilter select').val().split(' ')[0];
                params['contractDateInterval'] = contractDateInterval;
            }
        }
        if (this.my_table_modal_object['policiesEndDate']) {
            params['end_date_temp'] = this.my_table_modal_object['policiesEndDate']
        }
        if (this.my_table_modal_object['policiesStartDate']) {
            params['start_date_temp'] = this.my_table_modal_object['policiesStartDate']
        }

        // if(this.apiName === ApiConstant.getPoliciesForMissingAgent || this.apiName === ApiConstant.getPolicies){
        //     tableConfig['columnDefs'] = [{ 'className': 'text-end', 'targets': [2] }];
        // }
        this.params = params;
        // console.log('params', params, this.my_table_modal_object);
        if (this.activeSubTabId==23) {
            this.appId = this.my_table_modal_object['args']['appId'];
            let insuredName = this.my_table_modal_object['args']['insuredName']
            this.awnHiddenId = this.my_table_modal_object['args']['AWNHiddenVal']
            this.agentId = this.awnHiddenId;
            this.params['param5'] = this.agentId;
            this.params['idType'] = 'agent';
            this.openAgentInformationPopup(insuredName, this.awnHiddenId)
        } else {
            myCompOneObj.initializeTable(params, tableConfig);
        }
    }

    getKeys(obj: any): string[] {
        // Desired order of keys
        const keyOrder = ['description', 'startDate', 'endDate', 'statusCode'];
        const keys = Object.keys(obj);
        // Sort keys according to the defined order
        return keys.sort((a, b) => {
            const indexA = keyOrder.indexOf(a);
            const indexB = keyOrder.indexOf(b);
            return (indexA > -1 ? indexA : keys.length) - (indexB > -1 ? indexB : keys.length);
        });
        // return Object.keys(obj);
    }

    isObject(value: any): boolean {
        return value !== null && typeof value === 'object' && !Array.isArray(value);
    }

    isArray(value: any): boolean {
        return Array.isArray(value);
    }

    flattenObject(obj: any, parentKey = '', result = {}): any {
        const keysToExclude = ['Processing Stage Code'];

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (keysToExclude.includes(key)) {
                    continue; // Skip this key
                }
                const newKey = parentKey ? `${key}` : key;
                if (this.isObject(obj[key]) && !this.isArray(obj[key])) {
                    this.flattenObject(obj[key], newKey, result);
                } else if (this.isArray(obj[key])) {
                    obj[key].forEach((item: any, index: number) => {
                        this.flattenObject(item, `${newKey}[${index}]`, result);
                    });
                } else {
                    result[newKey] = obj[key];
                }
            }
        }
        return result;
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.closeCustom();
        }
    }

    @HostListener('click', ['$event'])
    onClick(e: any) {
        this.appId = $(e.target).attr('appId');
        let insuredName = $(e.target).attr('insured');
        let AWNHiddenVal = $(e.target).attr('AWNHiddenValue');
        if ($(e.target).attr('functionToCall') === 'showAppDetails') {
            this.openAgentInformationPopup(insuredName, AWNHiddenVal)
        }
    }

    openAgentInformationPopup(insuredName, AWNHiddenVal) {
        this.spinner.show();
        var params = this.commonService.getParameters();
        params['appId'] = this.appId;
        params['agentId'] = AWNHiddenVal;//params.param5;
        if(this.activeSubTabId == 23) {
            params['param5'] = AWNHiddenVal;
        }
        params['param11'] = 'application';
        params['idType'] = this.idType;
        if (insuredName) {
            $('.modal-title-name').html('Applicant Name: ' + insuredName);
            this.my_modal_title = 'Applicant Name: ' + insuredName;
        } else {
            $('.modal-title-name').html('Applicant Name: ');
            this.my_modal_title = 'Applicant Name: ';
        }
        this.applicationDetailInfo = [];
        let callgetAgentInformation = this.commonService.getAgentInformation(params);
        let callGetInfoAndUnderwritingInformation = this.commonService.getInfoAndUnderwritingInformation(params);
        let multipleQApiArr = [];
        this.showManualUnderWriting = configJson.showManualUnderWriting == undefined ? true : configJson.showManualUnderWriting;
        let showRequirementData = configJson.showRequirementData == undefined ? false : configJson.showRequirementData;
        if (showRequirementData) {
            let callGetMissingInformation = this.commonService.getApplicationRequirementDetails(params);
            multipleQApiArr = [callgetAgentInformation, callGetInfoAndUnderwritingInformation, callGetMissingInformation];
        } else {
            multipleQApiArr = [callgetAgentInformation, callGetInfoAndUnderwritingInformation];
        }
        forkJoin(multipleQApiArr).subscribe(response => {
            let res: any = response;
            this.spinner.hide();
            if (res[0].length && res[1].length) {
                let arr1 = res[0][0]
                let arr2 = res[1][0]
                let result = {
                    ...arr1,
                    ...arr2
                };
                this.applicationDetailInfo = [arr1, arr2];
            }
            if (showRequirementData) {
                if (res[2].length) {
                    this.requirementsInformation = res[2];
                    const groupedByProcessingStageCode = this.requirementsInformation.reduce((acc, item) => {
                        const key = item['Processing Stage Code'];
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(item);
                        return acc;
                    }, {});

                    this.policyCaseInformation = Object.keys(groupedByProcessingStageCode).map(key => ({
                        processingStageCode: key,
                        items: groupedByProcessingStageCode[key]
                    }));
                    this.applicationDetailInfo.push(this.policyCaseInformation);
                }
            }
            this.popupAgentInformation = res[0][0];
            this.infoAndUnderwritingInformation = res[1][0];
            this.popupAgentInformationFlag = true;
            this.infoAndUnderwritingInformationFlag = true;
        })
        $('#policyCountSummary, #popupContainer').hide();
        $('#applicationMainContainer').show();
    }

    // getAgentInformation = () => {
    //     var params = this.commonService.getParameters();
    //     params.appId = this.appId;
    //     params.agentId = this.appId;//params.param5;
    //     $.ajax({
    //         type: 'POST',
    //         url: 'getAgentPersonalDetails',
    //         contentType: 'application/json',
    //         data: JSON.stringify(params)
    //     }).done(function (response) {
    //         this.agentInformation = response[0];
    //         return this.agentInformation;
    //     })
    // }

    // getInfoAndUnderwritingInformation = () =>{
    //     var params = this.commonService.getParameters();
    //     params.appId = this.appId;
    //     $.ajax({
    //         type: 'POST',
    //         url: 'getApplicationDetails',
    //         contentType: 'application/json',
    //         data: JSON.stringify(params)
    //     }).done(function (response) {
    //         this.infoAndUnderwritingInformation = response.data[0];
    //         return this.infoAndUnderwritingInformation;
    //     })
    // }

    // onAppDetailsExportClick = () => {
    //     console.log('export');
    // }

    getColorClasses = (value) => {
        var color = 'green';
        if (value) {
            var declinedReasonValuesArr = this.declinedReasonValues.split(',');
            if (declinedReasonValuesArr.length) {
                declinedReasonValuesArr.forEach(function (ele) {
                    if (value === ele.trim()) {
                        color = 'red';
                    }
                })
            }
        }
        return color;
    }

    onBackButtonClick = () => {
        $('#policyCountSummary, #popupContainer').show();
        $('#applicationMainContainer').hide();
        $('.modal-title-name').html(this.my_modal_title);
    }

    closeCustom = () => {
        $('.mapTable .col-search-input').css('display', 'none');
        this.activeModal.close();
        // this.commonService.resetparameters();
        this.activeTabId != 1 && this.activeSubTabId != 10 ? this.commonService.parametersObj = this.baseTablePrameterObj : '';
        // this.commonService.setParameters('param5', 'All');
        if (this.isApplicationsApplicable === 'yes') {
            this.commonService.setParameters('param11', 'application');
        } else {
            this.commonService.setParameters('param11', 'policy');
        }
        // if($('#toggleAgents .toggleActive').attr('type') === '#'){
        //     if($('#breadcrumb li').length>1){
        //         this.commonService.setParameters('idType', 'agent');
        //     }   else {
        //         this.commonService.setParameters('idType', 'crm');
        //     }
        // }
        // this.commonService.setParameters('idType', '');
        // // this.commonService.setParameters('agentMarketingCompany', '');
        // this.commonService.setParameters('level', '');
    }

    getProductSummary(apiName) {
        this.product_count_arr = [];
        let params = { ...this.commonService.getParameters() };
        if (apiName === ApiConstant.getPolicies || apiName === ApiConstant.getTapPolicies || apiName === ApiConstant.getPoliciesForMissingAgent ||
            apiName === ApiConstant.getYearlyPolicies || apiName === ApiConstant.getAgentReportPolicies ||
            apiName === ApiConstant.getPendingPoliciesForMissingAgent || apiName === ApiConstant.getPendingPolicies ||
            apiName === ApiConstant.getAgentLapsedPolicies || apiName === ApiConstant.getLifeAndAnnuityPoliciesTap ||
            apiName === ApiConstant.getLifeAndAnnuityPolicies || apiName === ApiConstant.getCommissionStatements ||
            apiName === ApiConstant.getYearlyPoliciesTAP || apiName === ApiConstant.getApplications) {
            //var params = this.commonService.getParameters();
            if (apiName === ApiConstant.getLifeAndAnnuityPoliciesTap || apiName === ApiConstant.getLifeAndAnnuityPolicies) {
                // params['subProductPlan'] = params.subProductPlan;
            } else {
                // params['subProductPlan'] = '';
            }
            // if( this.activeTabId === 5){
            //     if( $('#pageSubTabs li.active').attr('id') === 'subtab2'){
            //         params.param1 = scope.allCarriersArr;
            //         params.param2 = 'All';
            //         var startdate = $('#incentivePrograms .active .startDate').attr('startdate');
            //         var endDate = $('#incentivePrograms .active .endDate').attr('enddate');
            //         var startdateValues = startdate.split('/');
            //         params.param3 = startdateValues[2] + '-' + startdateValues[0] + '-' + startdateValues[1];
            //         var enddatedateValues = endDate.split('/');
            //         params.param4 = enddatedateValues[2] + '-' + enddatedateValues[0] + '-' + enddatedateValues[1];
            //         params.contractDateInterval = '';
            //         params.month = '';
            //         params.idType = 'crm';
            //         params.filterDateType = 'Filter Date';
            //         params.policyStatus = 'Active';
            //         params.param5 = scope.incentiveAgentId;
            //     } else if($('#pageSubTabs li.active').attr('id') === 'subtab1' || scope.activeAgentTab === 'tab1' || scope.activeAgentTab === 'tab3'){
            //         params.contractDateInterval = '';
            //         params.idType = 'agent';
            // 		if(apiName === 'getYearlyPolicies'){
            // 			params.breadcrumbFilters = scope.breadcrumbFilters;
            // 		}
            //     } else {
            //         params.contractDateInterval = scope.contractDateInterval;
            //         params.idType = 'agent';
            //     }
            // } else {
            // }
            var productCountApiName = ApiConstant.getProductCountInfo;
            if (apiName === ApiConstant.getPendingPolicies || apiName === ApiConstant.getPendingPoliciesForMissingAgent) {
                productCountApiName = ApiConstant.getPendingProductCountInfo;
            } else if (apiName === ApiConstant.getAgentLapsedPolicies) {
                productCountApiName = ApiConstant.getLapsedProductCountInfo;
            } else if (apiName === ApiConstant.getTapPolicies || apiName === ApiConstant.getLifeAndAnnuityPoliciesTap) {
                productCountApiName = ApiConstant.getTAPProductCountInfo;
            } else if (apiName === ApiConstant.getCommissionStatements) {
                productCountApiName = ApiConstant.getCommissionProductCountInfo;
            } else if (apiName === ApiConstant.getApplications) {
                productCountApiName = ApiConstant.getApplicationProductCountInfo;
            }


            params['url'] = productCountApiName;
            params['idType'] = this.idType;
            params['month'] = '';

            if (apiName === ApiConstant.getPendingPolicies) {
                if (params['idType'] === "crm") {
                    params['param5'] = this.awnHiddenId;
                } else {
                    params['param5'] = this.awnHiddenId;
                }
                params['subProductPlan'] = '';
            } else if (apiName === ApiConstant.getLifeAndAnnuityPolicies) {
                params['param5'] = 'All';
                params['month'] = this.month;
            } else if (this.activeTabId === 6 && this.activeSubTabId === 24) {
                if (apiName === ApiConstant.getPoliciesForMissingAgent || apiName === ApiConstant.getPendingPoliciesForMissingAgent) {
                    params['missingAgentTab'] = true;
                }
                params['param5'] = this.agentId;
            } else if (this.activeTabId === 5 && this.activeSubTabId === 22) {
                params['month'] = this.month;
                params['level'] = this.level;
                params['param5'] = this.awnHiddenId;
                params['missingAgentTab'] = false;
            } else if (this.activeTabId === 5 && this.activeSubTabId === 28) {
                params['month'] = '';
                params['level'] = this.level;
                params['param5'] = this.agentId;
                params['missingAgentTab'] = false;

            } else if (this.activeTabId === 5 && this.activeSubTabId === 13) {
                params['month'] = this.month;
                params['level'] = this.level;
                params['param5'] = this.awnHiddenId;
                params['missingAgentTab'] = false;
                if (params['activeAgentTab'] === 1) {
                    params['contractDateInterval'] = '';
                }
            } else if (this.activeTabId === 3 && (this.activeSubTabId === 5 || this.activeSubTabId === 6 || this.activeSubTabId === 7 || this.activeSubTabId === 8)) {
                params['month'] = '';
                params['level'] = '';
                params['param5'] = this.awnHiddenId;
                params['missingAgentTab'] = false;
            } else if (this.activeTabId === 2 && this.activeSubTabId === 1) {
                params['param5'] = this.agentId;
                params['crmId'] = this.crmId;
                params['missingAgentTab'] = false;
                if (apiName === ApiConstant.getPolicies) {
                    if (params['idType'] === "crm") {
                        params['param5'] = this.awnHiddenId;
                    } else {
                        params['param5'] = this.awnHiddenId;
                    }
                }
                if (apiName === ApiConstant.getTapPolicies) {
                    params['param5'] = this.agentId;
                    params['subProductPlan'] = '';
                }
                if (apiName === ApiConstant.getCommissionStatements) {
                    params['param5'] = this.awnHiddenId;
                }
            } else {
                params['param5'] = this.awnHiddenId;
                params['missingAgentTab'] = false;
            }
            if (this.activeAgentTab === 3 || apiName === ApiConstant.getYearlyPolicies) {
                params['contractDateInterval'] = '';
            }
            if (this.activeAgentTab == undefined || this.activeAgentTab != 2) {
                params['contractDateInterval'] = '';
            }
            if (apiName === ApiConstant.getYearlyPolicies || apiName === ApiConstant.getPolicies) {
                params['subProductPlan'] = '';
            }
            if (this.activeSubTabId === 13) {
                params['subProductPlan'] = '';
            }
            if (this.apiName === ApiConstant.getAgentReportPolicies) {
                params['contractDateInterval'] = '';
                if ($('#top25monthFilter').length === 1) {
                    let contractDateInterval = $('#top25monthFilter select').val().split(' ')[0];
                    params['contractDateInterval'] = contractDateInterval;
                }
            }

            if (this.activeSubTabId === 27) {
                var startdate = $('#incentivePrograms .active .startDate').text();
                var endDate = $('#incentivePrograms .active .endDate').text();
                if (!startdate || !endDate) {
                    const updated_params = this.commonService.getParameters();
                    if (this.my_table_modal_object['policiesEndDate']) {
                        updated_params['end_date_temp'] = this.my_table_modal_object['policiesEndDate']
                    }
                    if (this.my_table_modal_object['policiesStartDate']) {
                        updated_params['start_date_temp'] = this.my_table_modal_object['policiesStartDate']
                    }
                    if (updated_params['start_date_temp']) {
                        params['param3'] = updated_params['start_date_temp'];
                    }
                    if (updated_params['end_date_temp']) {
                        params['param4'] = updated_params['end_date_temp'];
                    }
                } else {
                    var startdateTemp = startdate.split(' ');
                    var enddatedateValuesTemp = endDate.split(' ');
                    var startdateValues = startdateTemp[1].split('/');
                    params['param3'] = startdateValues[2] + '-' + startdateValues[0] + '-' + startdateValues[1];
                    var enddatedateValues = enddatedateValuesTemp[1].split('/');
                    params['param4'] = enddatedateValues[2] + '-' + enddatedateValues[0] + '-' + enddatedateValues[1];
                }
                // if(this.apiName == ApiConstant.getPlaquesIncentiveReport){
                params['level'] = this.level;
                params['idType'] = 'crm'
                // }
                params['param5'] = this.agentId;
                params['policyStatus'] = 'Active';
                params['filterDateType'] = 'Entry Date';
            }
            let LapsedColumnArray = ["Lapsed in 3 months", "Lapsed in 6 months", "Lapsed in 9 months", "Lapsed in 13 months"];
            if (this.my_table_modal_object['activeSubTabId'] == 39 && this.my_table_modal_object['selectedColumnName'] == "Placed Policies") {
                params['reportIdentifier'] = this.activeSubTabId;
                params['policyStatus'] = ['Active'];
                params['param2'] = this.my_table_modal_object['ProductLine'];
                params['param5'] = this.my_table_modal_object['AWNHiddenId'];
                params['clickedColumnName'] = this.my_table_modal_object['selectedColumnName'];
            } else if (this.activeSubTabId == 39 && this.my_table_modal_object['selectedColumnName'] == "Submitted Applications") {
                params['reportIdentifier'] = this.activeSubTabId;
                params['param2'] = this.my_table_modal_object['ProductLine'];
                params['param5'] = this.my_table_modal_object['AWNHiddenId'];
                params['clickedColumnName'] = this.my_table_modal_object['selectedColumnName'];
                params['applicationStatus'] = ["Expired", "Submitted"];
                params['param11'] = "application";
            } else if (this.my_table_modal_object['activeSubTabId'] == 39 && LapsedColumnArray.includes(this.my_table_modal_object['selectedColumnName'])) {
                params['reportIdentifier'] = this.activeSubTabId;
                params['policyStatus'] = ['Lapsed'];
                params['param2'] = this.my_table_modal_object['ProductLine'];
                params['param5'] = this.my_table_modal_object['AWNHiddenId'];
                params['clickedColumnName'] = this.my_table_modal_object['selectedColumnName'];
            } else {
                params['reportIdentifier'] = this.activeSubTabId;
                params['clickedColumnName'] = this.my_table_modal_object['selectedColumnName'];
            }
            this.commonService.productCountApiName(params).subscribe(res => {
                if (res) {
                    this.product_count_arr = JSON.parse(res.toString());
                } else {
                    $('#policyCountSummary').hide();
                }
            });
        } else {
            $('#policyCountSummary').hide();
        }
    }

    onMouseover = (e) => {
        $('[data-toggle="tooltip"]').tooltip();
    }

    onMouseout = (e) => {

    }

    onExportCsv = async () =>{
        if($("#applicationMainContainer").is(":visible") === true) {

            const workbook = XLSX.utils.book_new();
            // Array of keys that need to be transformed to currency
            const currencyKeys = ['premium', 'amount'];

            // Function to check if the key should be formatted as currency
            const isCurrencyKey = (key: string) => {
                return currencyKeys.some(currencyKey => key.toLowerCase().includes(currencyKey));
            };

            // Function to format a number as currency
            const formatAsCurrency = (value: any) => {
                if (typeof value === 'number') {
                    return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
                }
                return value;
            };
            const sheetNames = ['Agent', 'Application', 'Requirements-Underwriting'];
            this.applicationDetailInfo.forEach((arr, index) => {
                if (Array.isArray(arr)) {
                    // Check if this is an array of key-value pairs or objects with nested fields
                    if (arr.every(item => item.hasOwnProperty('key') && item.hasOwnProperty('value'))) {
                      // It's an array of key-value pairs
                      const flatData = arr.reduce((acc, item) => {
                        // Check if the key should be formatted and apply transformation
                        acc[item.key] = isCurrencyKey(item.key) ? formatAsCurrency(item.value) : item.value;
                        return acc;
                      }, {});
            
                      // Create a worksheet for key-value pairs
                      const worksheet = XLSX.utils.json_to_sheet([flatData]);
                      XLSX.utils.book_append_sheet(workbook, worksheet, `${sheetNames[index]}`);
                    } else if (arr.every(item => item.hasOwnProperty('processingStageCode') && item.hasOwnProperty('items'))) {
                      // Handle complex structure with nested 'items' array
                      arr.forEach((parentObj, i) => {
                        const flatItems = parentObj.items.map(item => {
                          // Format fields based on currency keys
                          const formattedItem = {};
                          for (const key in item) {
                            formattedItem[key] = isCurrencyKey(key) ? formatAsCurrency(item[key]) : item[key];
                          }
                          return formattedItem;
                        });
            
                        // Use processingStageCode as the sheet name
                        let sheetName = parentObj.processingStageCode ? parentObj.processingStageCode : `Requirement_${index + 1}_${i + 1}`;
                        sheetName = sheetName.replace(/[\/]/gi, ' ');
                        sheetName = sheetName.length > 30 ? sheetName.substring(0, 30) : sheetName;
                        // Create a worksheet from the nested items array
                        const worksheet = XLSX.utils.json_to_sheet(flatItems);
                        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
                      });
                    }
                  } else {
                    console.error(`Item at index ${index} is not an array:`, arr);
                  }
              });
          
              // Generate Excel file and download it
              const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
              this.saveAsExcelFile(excelBuffer, this.my_modal_title);

            return;
            var csv =  this.JSON2CSV(await this.simplifyData());
            var blob = new Blob(['\ufeff', csv]);
            var reader: any = new FileReader();
            reader.onload = () => {
                var is_iPad = navigator.userAgent.match(/iPad/i) !== null;
                if (is_iPad) {
                    // Chrome, Firefox, and Chrome and Safari on iOS
                    var dataUrl: any = reader.result;
                    var open = window.open(dataUrl);
                    if (open === null || typeof (open) === 'undefined') {
                        var blobUrl = URL.createObjectURL(blob);
                        window.location.href = blobUrl;
                    }
                } else {
                    var fileName = '';
                    fileName = this.my_modal_title + '.csv';
                    // works on IE and Edge to save file, would also work on Chrome, Firedox for desktop
                    FileSaver.saveAs(blob, fileName);
                }
            };
            reader.readAsDataURL(blob);
        } else {
            let fileName = this.my_modal_title;
            this.exportService.callExportToCSV({}, false, fileName, this.params);
        }
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
        FileSaver.saveAs(data, `${fileName}.xlsx`);
      }

    async simplifyData() {
        const simplifiedArray = this.applicationDetailInfo.map(data => {
            const simplifiedData: { [key: string]: any } = {};
            Object.entries(data).forEach(([key, value]: any) => {
                if (typeof value === 'object' && value !== null && 'key' in value && 'value' in value) {
                    simplifiedData[value.key] = value.value;
                } else {
                    simplifiedData[key] = value;
                }
            });
            return simplifiedData;
        })
        return simplifiedArray;
    }

    JSON2CSV = (objArray) => {
        var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let activeTabId = this.localStorageService.get('activeTabId');
        var str = '';
        var line = '';
        let q1Title = '';
        let q2Title = '';
        var baseTableColumns = ['Premium', 'YTD', 'YTD Total', 'YTD Premium',
            'Jan Premium', 'Feb Premium', 'Mar Premium', 'Apr Premium', 'May Premium', 'Jun Premium', 'Jul Premium', 'Aug Premium', 'Sep Premium', 'Oct Premium', 'Nov Premium', 'Dec Premium',
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if ($('#quote').is(':checked')) {
            for (let index in array[0]) {
                if (this.companyFilter === 'yes' && this.userRole === 'Agent') {
                    if (index != 'AWN Hidden' && index != 'Agency' && index != 'Marketing Company' && (index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName')) {
                        var value = index + '';
                        line += '"' + value.replace(/"/g, '""') + '",';
                    }
                } else {
                    if (this.activeTabId === 5 && $('#pageSubTabs .active').attr('id') === 'subtab5') {
                        if (index != 'AWN Hidden' && index != 'Marketing Company' && index != 'LevelName') {
                            var value = index + '';
                            line += '"' + value.replace(/"/g, '""') + '",';
                        }
                    } else {
                        if (index != 'AWN Hidden' && (index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName')) {
                            var value = index + '';
                            line += '"' + value.replace(/"/g, '""') + '",';
                        }
                    }
                }
            }
        } else {
            for (let index in array[0]) {
                if (this.companyFilter === 'yes' && this.userRole === 'Agent') {
                    if (index != 'AWN Hidden' && index != 'Agency' && index != 'Marketing Company' && index !== 'Q1' && index !== 'Q2' && (index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName')) {
                        line += index + ',';
                    } else if (index === 'Q1') {
                        line += '"' + q1Title + '",';
                    } else if (index === 'Q2') {
                        line += '"' + q2Title + '",';
                    }
                } else {
                    if (this.activeTabId === 5 && $('#pageSubTabs .active').attr('id') === 'subtab5') {
                        if (index != 'AWN Hidden' && index != 'Marketing Company' && index != 'LevelName' && index !== 'Q1' && index !== 'Q2') {
                            line += index + ',';
                        } else if (index === 'Q1') {
                            line += '"' + q1Title + '",';
                        } else if (index === 'Q2') {
                            line += '"' + q2Title + '",';
                        }
                    } else {
                        if (index != 'AWN Hidden' && index !== 'Q1' && index !== 'Q2' && (index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName')) {
                            line += index + ',';
                        } else if (index === 'Q1') {
                            line += '"' + q1Title + '",';
                        } else if (index === 'Q2') {
                            line += '"' + q2Title + '",';
                        }
                    }
                }
            }
        }

        line = line.slice(0, -1);
        str += line + '\r\n';

        for (let i = 0; i < array.length; i++) {
            var line = '';

            if ($('#quote').is(':checked')) {
                for (let index in array[i]) {
                    if (this.companyFilter === 'yes' && this.userRole === 'Agent') {
                        if (index != 'AWN Hidden' && index != 'Agency' && index != 'Marketing Company') {
                            var value = array[i][index] + '';
                            line += '"' + value.replace(/"/g, '""') + '",';
                        }
                    } else {
                        if (this.activeTabId === 5 && $('#pageSubTabs .active').attr('id') === 'subtab5') {
                            if (index != 'AWN Hidden' && index != 'Marketing Company') {
                                var value = array[i][index] + '';
                                line += '"' + value.replace(/"/g, '""') + '",';
                            }
                        } else {
                            if (index != 'AWN Hidden') {
                                var value = array[i][index] + '';
                                line += '"' + value.replace(/"/g, '""') + '",';
                            }
                        }
                    }
                }
            } else {
                //formats the number to the $ currency
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });

                for (let index in array[i]) {
                    if (this.userRole !== 'Agent' && this.companyFilter === 'yes') {
                        if (this.activeTabId === 5 && $('#pageSubTabs .active').attr('id') === 'subtab5') {
                            if (index != 'AWN Hidden' && index != 'Marketing Company') {
                                getExportCommon(index);
                            }
                        } else {
                            if (index != 'AWN Hidden') {
                                getExportCommon(index);
                            }
                        }
                    } else {
                        // if(this.clientType === 'carrier'){
                        if (index != 'AWN Hidden' && index != 'Marketing Company') {
                            getExportCommon(index);
                        }
                        // }

                        // else {
                        //     if (index != 'AWN Hidden' && index != 'Agency' && index != 'Marketing Company') {
                        //         getExportCommon();
                        //     }
                        // }
                    }
                }

                function getExportCommon(index) {
                    if ($('#popupContainer').length !== 1 && (activeTabId === 2 || activeTabId === 3 || activeTabId === 4 || activeTabId === 6) && (index === 'Submitted Premium' || index === 'Annualized Premium' || index === 'Commission Basis' || index === 'Commission' || index === 'Override' || index === 'Earned Premium' || index === 'TAP' || index === 'Pending Premium')) {
                        if (!array[i][index].toString().includes('$')) {
                            line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                        } else {
                            if (array[i][index] === null) {
                                var emptyStr = '';
                                line += ('"' + emptyStr) + '",';
                            } else {
                                line += ('"' + array[i][index]) + '",';
                            }
                        }
                    } else if ($('#popupContainer').length !== 1 && (activeTabId === 5)) {
                        if (array[i][index] && $('#toggleReport .toggleActive').attr('type') === '$' && !array[i][index].toString().includes('$') && (baseTableColumns.includes(index))) {
                            line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                        } else if (array[i][index] && !array[i][index].toString().includes('$') && (index === 'Jan Premium' || index === 'Feb Premium' || index === 'Mar Premium' || index === 'Apr Premium' || index === 'May Premium' || index === 'Jun Premium' || index === 'Jul Premium' || index === 'Aug Premium' || index === 'Sep Premium' || index === 'Oct Premium' || index === 'Nov Premium' || index === 'Dec Premium' || index === 'YTD Premium' || index === 'Q1' || index === 'Q2' || index === 'Change')) {
                            if ($('#toggleReport .toggleActive').attr('type') === '#' && (index === 'Q1' || index === 'Q2' || index === 'Change')) {
                                line += ('" ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            } else {
                                line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            }
                        } else if (array[i][index] && !array[i][index].toString().includes('$') && (index === 'Percentage') || (array[i][index] === 0 && index === 'Percentage')) {
                            line += ('"' + new Intl.NumberFormat().format(array[i][index]) + ' %') + '",';
                        } else {
                            if (array[i][index] === 0 && $('#toggleReport .toggleActive').attr('type') === '$' && (index === 'Jan' || index === 'Feb' || index === 'Mar' || index === 'Apr' || index === 'May' || index === 'Jun' || index === 'Jul' || index === 'Aug' || index === 'Sep' || index === 'Oct' || index === 'Nov' || index === 'Dec' || index === 'YTD' || index === 'Q1' || index === 'Q2' || index === 'Change')) {
                                line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            } else if ($('#pageSubTabs .active').attr('id') === 'subtab4' && (index === 'Premium' || index === '1 Premium' || index === '2 Premium' || index === '3 Premium' || index === '4 Premium')) {
                                line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            } else if (array[i][index] === 0 && (index === 'Jan Premium' || index === 'Feb Premium' || index === 'Mar Premium' || index === 'Apr Premium' || index === 'May Premium' || index === 'Jun Premium' || index === 'Jul Premium' || index === 'Aug Premium' || index === 'Sep Premium' || index === 'Oct Premium' || index === 'Nov Premium' || index === 'Dec Premium' || index === 'YTD Premium')) {
                                line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            } else {
                                if (array[i][index] === null) {
                                    var emptyStr = '';
                                    line += ('"' + emptyStr) + '",';
                                } else {
                                    line += ('"' + array[i][index]) + '",';
                                }
                            }
                        }
                    } else if ($('#popupContainer').length === 1 && ['Split %', 'Commission Rate', 'Commission Split', 'Target Premium', 'Submitted Premium', 'Total Premium', 'Commission Rate', 'Commission Basis', 'TAP', 'Commission', 'Annualized Premium'].indexOf(index) >= 0) {
                        if (index === 'Split %' || index === 'Commission Split' || index === 'Commission Rate') {
                            line += ('"' + new Intl.NumberFormat().format(array[i][index]) + ' %') + '",';
                        } else if (!array[i][index].toString().includes('$')) {
                            line += ('"' + formatter.format(array[i][index]).replace(/^(\D+)/, '$1 ')) + '",';
                        } else {
                            if (array[i][index] === null) {
                                var emptyStr = '';
                                line += ('"' + emptyStr) + '",';
                            } else {
                                line += ('"' + array[i][index]) + '",';
                            }
                        }
                    } else {
                        if (array[i][index] === null) {
                            var emptyStr = '';
                            line += ('"' + emptyStr) + '",';
                        } else {
                            line += ('"' + array[i][index]) + '",';
                        }
                    }
                }
            }

            line = line.slice(0, -1);
            str += line + '\r\n';
        }
        return str;
    };

}
