import { HostListener, Component, OnInit, Input, ViewChild, Output, EventEmitter, ComponentRef, ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
declare var $: any;
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { configJson } from '../../shared/services/dashboard-config.service';
import { CommonService } from './../../shared/services/common.service';
import { ApiConstant } from './../../shared/constants/api-constant';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { LocalstorageService } from './../services/localstorage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { TableComponentComponent} from './../components/table-component/table-component.component';
import { HelperService } from '../services/helper.service';
@Component({
  selector: 'app-incentive-points-modal',
  templateUrl: './incentive-points-modal.component.html',
  styleUrls: ['./incentive-points-modal.component.scss']
})
export class IncentivePointsModalComponent implements OnInit {

  @Input() incentive_modal_object: string;
  // @ViewChild(TableComponentComponent, {static: false}) tableComponent: TableComponentComponent;
  agentIncentivePoints: any;
  data: any;
  ranges: any;
  currentPointsValue: any;
  targetPoints: number;
  activeTabId: any;
  agentName: string;
  title: string;
  rangeArray = [];
  progressType = 'points';
  premiumTarget = 1;
  currentPremium = 1;
  agentId: string;
  currentProgramName: string;
  agentLevel: string;
  noDataMessage = 'No data available';
  legendMessages = ['6 Day, 5 Night (All Inclusive) trip for two to Riviera Maya',
  '6 Day, 5 Night (All Inclusive) trip for two to Riviera Maya & $250 cash bonus',
  '6 Day, 5 Night (All Inclusive) trip for two to Riviera Maya & $500 cash bonus']

  constructor(
    public activeModal: NgbActiveModal,
    private commonService: CommonService,
    private localStorageService: LocalstorageService,
    private modalService: NgbModal,
    private http: HttpClient,
    private renderer: Renderer2,
    private elmRef: ElementRef,
    private spinner: NgxSpinnerService,
    public _helperService: HelperService
  ) { }

  ngOnInit(): void {
    // console.log(this.incentive_modal_object);
    this.activeTabId = this.localStorageService.get('activeTabId');
    this.title = this.incentive_modal_object['title'];
    this.agentId = this.incentive_modal_object['agentid'];
    this.currentProgramName = this.incentive_modal_object['currentProgram']?.trim();
    this.agentLevel = this.incentive_modal_object['agentLevel'];
    // if(['Sales incentives ARIS', 'Sales incentives Americare'].includes(this.incentive_modal_object['currentProgram']?.trim())&&this.incentive_modal_object['agentLevel']!='Agent'){
    //   this.noDataMessage = 'Progress chart is not available for FMO, ASM and DSM level';
    // }
     if (['Manager SIP ARIS'].includes(this.incentive_modal_object['currentProgram']?.trim())&&this.incentive_modal_object['agentLevel']!='level_3' && this.incentive_modal_object['agentLevel']!='level_1'){
      this.noDataMessage = 'Progress chart is not available for FMO, ASM and Agent level';
    } else this.callPointsAPI();
  }

  callPointsAPI(): void {
    var incentiveparams: any = {};
    this.progressType = 'premium';

    if (['Manager’s Quarterly Bonus', 'ASM New Agent Year-End Bonus', 'New Manager Quarterly Bonus'].includes(this.currentProgramName)) {
      this.agentName = this.incentive_modal_object['title'].replace("Points for ", '');
      this.title = this.agentName;
      incentiveparams = {...incentiveparams, ...this.commonService.getParameters()};
      incentiveparams.asm = this.agentName;
      incentiveparams.from_date = this.commonService.getParameters()['start_date_temp'];
      incentiveparams.to_date = this.commonService.getParameters()['end_date_temp'];
      incentiveparams.tabId = this.activeTabId;
      this.commonService.getASMTarget(incentiveparams).subscribe(res => {
        this.agentIncentivePoints = res; //[{"title":"ZAP*****JAN","x`":"Points","ranges":[150,200,180],"measures":[61]}]
        // this.ranges = res[0].ranges;
        // var max = this.ranges[this.ranges.length - 1];
        // this.data = res[0];
        // var currentPoints = res[0].measures[0] * 100 / max;
        this.currentPointsValue = "$" + res[0]['premium'];
        this.currentPremium = res[0]['premium'];
        this.rangeArray.push('$0');
        if(res[1]&&res[1] instanceof Array&&res[1][0]&&res[1][0]['min_points']){
          this.legendMessages = [];
          for(let i in res[1]){
            this.rangeArray.push('$' + res[1][i]["min_points"])
            this.legendMessages.push(res[1][i]["reward"]?res[1][i]["reward"]:''); 
          }
        } else {
          this.rangeArray.push('$' + ((res[0]['quarter_target']?res[0]['quarter_target']:res[0]['premium']) * .75).toFixed(2))
          this.rangeArray.push('$' + ((res[0]['quarter_target']?res[0]['quarter_target']:res[0]['premium']) * .9).toFixed(2))
          this.rangeArray.push('$' + ((res[0]['quarter_target']?res[0]['quarter_target']:res[0]['premium']) * 1).toFixed(2))
          this.premiumTarget = res[0]['quarter_target'];
        }
      });
    } else {
      if (['Fast Start Bonus ARIS', 'Fast Start Bonus Americare', 'Fast Start Bonus'].includes(this.currentProgramName)) {
        incentiveparams.isCumulative = this.commonService.getParameters()['isCumulative'];
        incentiveparams.weekNo = this.commonService.getParameters()['weekNo'];
      }
      else if (['Monthly/Quarterly Bonus'].includes(this.currentProgramName)) {
        incentiveparams.isMonthly = this.commonService.getParameters()['isMonthly'];
      }
      else if (['Convention Standings ARIS'].includes(this.currentProgramName)) {
        this.progressType = 'points';
        incentiveparams.level = this.agentLevel;
      }
      else if (['Sales incentives ARIS', 'Sales incentives Americare', 'Manager SIP ARIS'].includes(this.currentProgramName)) {
        this.progressType = 'premium';
        incentiveparams.level = this.agentLevel;
      } else {
        this.progressType = 'points';
        incentiveparams.level = this.agentLevel;
      }
      incentiveparams.from_date = this.commonService.getParameters()['start_date_temp'];
      incentiveparams.to_date = this.commonService.getParameters()['end_date_temp'];
      incentiveparams.tabId = this.activeTabId,
        incentiveparams.param5 = this.incentive_modal_object['agentid'],
        incentiveparams.program = this.currentProgramName?this.currentProgramName:$('#incentivePrograms .active #incentiveProgramname').text().trim();

      this.commonService.getAgentIncentivePoints(incentiveparams).subscribe(res => {
        if (['Fast Start Bonus ARIS', 'Fast Start Bonus Americare',
          'Fast Start Bonus', 'Monthly/Quarterly Bonus', 'Production Allowance',
          'Convention Standings Americare', 'Sales incentives ARIS', 'Sales incentives Americare', 'Manager SIP ARIS'].includes(this.currentProgramName)) {
          this.progressType = 'premium';
          this.currentPointsValue = "$" + res[0]['premium'];
          this.currentPremium = res[0]['premium'];
          this.rangeArray.push('$0');
          this.rangeArray.push('$' + ((res[0]['target']?res[0]['target']:res[0]['premium']) * .75).toFixed(2))
          this.rangeArray.push('$' + ((res[0]['target']?res[0]['target']:res[0]['premium']) * .9).toFixed(2))
          this.rangeArray.push('$' + ((res[0]['target']?res[0]['target']:res[0]['premium']) * 1).toFixed(2))
          this.premiumTarget = res[0]['target'];
        } else {
          this.agentIncentivePoints = res; //[{"title":"ZAP*****JAN","x`":"Points","ranges":[150,200,180],"measures":[61]}]
          this.rangeArray.push(0);
          this.ranges = [res[0].target];
          if(res[1]&&res[1] instanceof Array&&res[1][0]&&res[1][0]['min_points']){
            this.legendMessages = [];
            for(let i in res[1]){
              this.rangeArray.push(res[1][i]["min_points"])
              this.legendMessages.push(res[1][i]["reward"]?res[1][i]["reward"]:''); 
            }
          } else {
            this.rangeArray.push(((this.ranges[0]?this.ranges[0]:res[0].points!=undefined ? res[0].points : res[0].premium) * .75).toFixed(2))
            this.rangeArray.push(((this.ranges[0]?this.ranges[0]:res[0].points!=undefined ? res[0].points : res[0].premium) * .9).toFixed(2))
            this.rangeArray.push(((this.ranges[0]?this.ranges[0]:res[0].points!=undefined ? res[0].points : res[0].premium) * 1).toFixed(2))
            this.legendMessages[0]='75%'
            this.legendMessages[1]='90%'
            this.legendMessages[2]='100%'
          }
          // var max = this.ranges[this.ranges.length - 1];
          this.targetPoints = res[0].target;
          this.data = res[0];
          // var currentPoints = res[0].measures[0] * 100 / max;
          this.currentPointsValue = res[0].points!=undefined ? res[0].points : res[0].premium;
          this.rangeArray.forEach(e=>console.log(this.getRangePercentage(e)))
        }
        // var str = '<div class="bulletChartContainer"><div class="bulletChartLabel"><span class="agentPoints">' + data[0].measures[0] + ' Points</span></div><div class="barsContainer"><div class="currentPoints" style="width:' + currentPoints + '%"></div>';
        // ranges.forEach(function (range, index) {
        //     width = range * 100 / max;
        //     str = str + '<div class="chartLevel' + index + '" style="width:' + width + '%"><div class="bulletChartxaxis">' + range + '</div></div>'
        // });
        // str = str + '</div><div class="bulletChartLegends"><div class="chartLegend"><span class="lagendColor0"></span><span class="lagendText">6 Day, 5 Night (All Inclusive) trip for two to Riviera Maya</span></div><div class="chartLegend"><span class="lagendColor1"></span><span class="lagendText">6 Day, 5 Night (All Inclusive) trip for two to Riviera Maya & $250 cash bonus</span></div><div class="chartLegend"><span class="lagendColor2"></span><span class="lagendText">6 Day, 5 Night (All Inclusive) trip for two to Riviera Maya & $500 cash bonus</span></div></div></div>'
      });
    }
  }

  myCurrentPointsStyles() {
    if (this.agentIncentivePoints && this.agentIncentivePoints.length) {
      let max = this.ranges[this.ranges.length - 1];
      return this.agentIncentivePoints[0].measures[0] * 100 / max + '%';
    }
  }

  convertIncentiveToPercentage(forStyle = false) {
    let temp = this.targetPoints!=undefined?this.targetPoints/100: 2.4;
    let currentPos = this.currentPointsValue;
    if (this.progressType == 'premium') {
      temp = this.premiumTarget / 100;
      currentPos = this.currentPremium;
    }
    let convertedIncentive = currentPos && temp != 0 ? (currentPos / temp).toFixed(2) : '0';
    if (forStyle && parseFloat(convertedIncentive) > 100) {
      convertedIncentive = '100';
    }
    return convertedIncentive + '%';
  }

  myStyles(range) {
    let max = this.ranges[this.ranges.length - 1];
    return range * 100 / max + '%';
  }

  getRangePercentage(value: number | string) {
    if(typeof value == 'string'){
      value = value.replace(/\$/, '');
    }
    let maxVal = this.rangeArray[this.rangeArray.length - 1];
    if(typeof maxVal == 'string'){
      maxVal = Number(maxVal.replace(/\$/, ''));
    }
    return (Number(value) / maxVal)*100+"%";
  }


}
