import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';

  const routes: Routes = [
    {
      path: '', component: HomeComponent, canActivate : [AuthGuard], children: [
        {
          path: 'agency',
          loadChildren: () => import(`../agency/agency.module`)
            .then(m => m.AgencyModule),
            canActivate: [AuthGuard]
        },
        {
          path: 'agents/:id',
          loadChildren: () => import(`../agents/agents.module`)
            .then(m => m.AgentsModule),
            canActivate: [AuthGuard]
        },
        {
          path: 'alerts/:id',
          loadChildren: () => import(`../alerts/alerts.module`)
            .then(m => m.AlertsModule),
            canActivate: [AuthGuard]
        },
        {
          path: 'analytics/:id',
          loadChildren: () => import(`../analytics/analytics.module`)
            .then(m => m.AnalyticsModule),
            canActivate: [AuthGuard]
        },
        {
          path: 'reports/:id',
          loadChildren: () => import(`../reports/reports.module`)
            .then(m => m.ReportsModule),
            canActivate: [AuthGuard]
        },
        {
          path: 'missingagents',
          loadChildren: () => import(`../missing-agents/missing-agents.module`)
            .then(m => m.MissingAgentsModule),
            canActivate: [AuthGuard]
        },
        {
          path: 'support',
          loadChildren: () => import(`../support/support.module`)
            .then(m => m.SupportModule),
            canActivate: [AuthGuard]
        },
        {
          path: 'dashboard',
          loadChildren: () => import(`../child-iframe/child-iframe.module`)
            .then(m => m.ChildIframeModule),
            canActivate: [AuthGuard]
        }
        // {
        //   path: '**',
        //   loadChildren: () => import(`../agency/agency.module`)
        //     .then(m => m.AgencyModule),
        //     canActivate: [AuthGuard]
        // }
      ]
    },
  ];

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
export class CoreRoutingModule {
}
