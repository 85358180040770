
<app-header-selectors></app-header-selectors>
<div class="mb-3 heightvhForgot1">
    <div class="col-lg-12 col-sm-12 col-md-12">
        <div>
        <form [formGroup]="cpform">
<!--             <div>
                <div class="patentPendingContainer text-align-center">U.S. Patent D917,520</div>
                <img src="assets/images/AgentVizion.png" width="201" class="cp-av-logo">
                <span class="appVersion w-100">Version: {{app_version}}</span>
            </div> -->


            <div class="text-align-center text-center">
                <img src="assets/images/AgentVizion.png" width="120" class="login-footer-img"><br>
                <div class="line"></div><br>
                <span class="appVersion top-border">Version: {{app_version}}</span>
                <div class=" mb-3  patentPendingContainer">U.S. Patent D917,520</div>
            </div>


            <div class="login-form-wrap">
                <div class="form-content-wrap">
                    <h2 class="cp-label">Change Password</h2>
                    <div id="flashMessagesContainer" class="flash-msg-container">
                        <flash-messages></flash-messages>
                    </div>
                    <div *ngIf="toggleUserFlag" id="toggleUserContainer" class="margin-bottom-10">
                        <ul id="toggleUser" class="nav nav-tabs" role="tablist">
                            <li class="nav-item" (click)="emailOrNPNTab('email')">
                                <a class="nav-link active" id="email-tab" data-toggle="tab" href="#email" role="tab" aria-controls="email" aria-selected="true">EMAIL USER</a>
                            </li>
                            <li class="nav-item" (click)="emailOrNPNTab('npn')">
                                <a class="nav-link" id="npn-tab" data-toggle="tab" href="#npn" role="tab" aria-controls="npn" aria-selected="false">NPN USER</a>
                            </li>
                        </ul>
                    </div>
                    <div class="form-group" id="emailContainer" >
                        <label>Email</label>
                        <input type="email" formControlName="email" class="form-control"
                        [(ngModel)]="user['email']" disabled="true"
                            placeholder="Enter email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email is invalid</div>
                        </div>
                    </div>

                    <div class="form-group" id="npnContainer" *ngIf="npnFlag">
                        <label for="number">NPN</label>
                        <input
                            formControlName="number"
                            id="number"
                            type="number"
                            class="form-control"
                            placeholder="Enter npn"
                            (change)="checkNpnOtp(user)"
                            (keypress)="numberOnly($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.number.errors }">
                        <div *ngIf="submitted && f.number.errors" class="invalid-feedback">
                            <div *ngIf="f.number.errors.required">Number is required.</div>
                            <div *ngIf="f.number.errors.pattern">Enter only number.</div>
                        </div>
                    </div>
                    <div id="password1" class="form-group position-relative">
                        <label>Old Password</label>
                        <input
                            type="password"
                            formControlName="password1"
                            class="form-control password"
                            value=""
                            [ngClass]="{ 'is-invalid': submitted && f.password1.errors }"
                            autocomplete="false"
                            id="old_password"
                        />
                            <!-- <i class="fa fa-eye show-password" id="toggle_old_password" 
                           style="cursor: pointer" (click)="showPassword('toggle_old_password','old_password')"></i> -->
                        <!-- style="pointer-events: none;" -->
                        <div *ngIf="submitted && f.password1.errors" class="invalid-feedback">
                          <div *ngIf="f.password1.errors.required">Old Password is required</div>
                        </div>
                      </div>

                    <div id="password" class="form-group position-relative">
                        <label>New Password</label>
                        <input
                            type="password"
                            formControlName="password"
                            class="form-control"
                            id="new_password"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                            <!-- <i class="fa fa-eye show-password" id="toggle_old_password" 
                           style="cursor: pointer" (click)="showPassword('toggle_new_password','new_password')"></i> -->
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.pattern">
                            Password must be 8-15 characters containing 1 capital, 1 lower and 1 special character (!,@,#,,%,&,*)
                            </div>
                            <div *ngIf="f.password.errors.maxlength">
                            Password must not exceed 15 characters
                            </div>
                        </div>
                    </div>

                    <div id="confirmPassword" class="form-group position-relative">
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            formControlName="confirmPassword"
                            class="form-control"
                            id="confirm_password"
                            [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"/>
                            <!-- <i class="fa fa-eye show-password" id="toggle_old_password" 
                           style="cursor: pointer" (click)="showPassword('toggle_confirm_password','confirm_password')"></i> -->
                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.confirmPassword.errors.required">
                            Confirm Password is required
                            </div>
                            <div *ngIf="f.confirmPassword.errors.matching">
                            New Password does not match
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group">
                        <label for="otp">OTP</label>
                        <a id="confirmResendOtp" style="float: right;" class="cursor-pointer" (click)="resendOtp()">Resend OTP</a>
                        <input type="text" name="otp" class="form-control" id="otp" placeholder="Enter OTP" required="">
                    </div> -->
                    <div id="changePwOtpContainer" class="form-group">
                        <label>OTP</label>
                        <a id="confirmResendOtp" style="float: right;" class="cursor-pointer" (click)="resendOtp(user)">Resend OTP</a>
                        <input
                            formControlName="otp"
                            id="otp"
                            type="tel"
                            class="form-control"
                            placeholder="Enter otp"
                            (keypress)="numberOnly($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.otp.errors }" maxlength="6">
                        <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                            <div *ngIf="f.otp.errors.required">Otp is required.</div>
                            <div *ngIf="f.otp.errors.pattern">Enter only number.</div>
                        </div>
                        <br>
                        <br>
                        <div class="form-actions">
                            <button id="confirmOtpBtn" type="submit" class="btn-custom btn" (click)="confirmpassword()" data-loading-text="<i style='font-size: 20px;' class='fa fa-spinner fa-spin'></i> Submit">Change Password</button>
                            <button id="confirmOtpCancelBtn" type="submit" class="btn-custom btn" style="margin-left: 7px;" (click)="goTologinPage()" (keyup-enter)="goTologinPage()" >Cancel</button>
                        </div>
                    </div>
                    <div id="cpCancelAndSubmitContainer" class="form-group cp-cancel-and-submit-container mt-3">
                        <button type="submit" class="btn-custom btn" (click)="onSubmit()">Submit</button>
                        <button type="submit" class="btn-custom btn" (click)="goTologinPage()" (keyup-enter)="goTologinPage()">Cancel</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</div>
<app-footer-selectors></app-footer-selectors>
