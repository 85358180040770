import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalstorageService } from '../../services/localstorage.service';
import { configJson } from '../../../shared/services/dashboard-config.service';
import { CommonService } from './../../../shared/services/common.service';

@Component({
  selector: 'app-alert-ticker',
  templateUrl: './alert-ticker.component.html',
  styleUrls: ['./alert-ticker.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class AlertTickerComponent implements OnInit {

    getMarqueeList: Array<object> = [];
    showFlash: Boolean = false;
    showAlertTicker: Boolean = true;
    shortMessage: string = '';
    longMessage: string = '';
    activeTabId: number;
    clientType: string = '';

    constructor(config: NgbModalConfig,
                private modalService: NgbModal,
                private commonService: CommonService,
                private localStorageService: LocalstorageService) {
        config.backdrop = 'static';
        config.keyboard = false;
     }

    ngOnInit(): void {
        this.activeTabId = this.localStorageService.get('activeTabId');
        this.clientType = configJson.clientType;
        this.showFlash = true;
        this.showAlertTicker = true;
        this.getFlashmessages();
        // this.getMarqueeList = [{"id":1,"short_message":"UHC Reporting Changes","long_message":"UHC abruptly removed key fields without notice from the App Submitted Production Report that AgentVizion requires for processing.  A change was made to process a different report the Carrier has available, but will only show contracts when they become effective.  We are currently working with our business partners and contacts to revert the changes."}]
    }

    getFlashmessages(){
        let objparams = {
            tabId: this.activeTabId
        }
        this.commonService.getFlashmessages(objparams).subscribe(res => {
            this.getMarqueeList = res;
        });
    }

    onMarquueCancel(){
        $('#marqueeId').css('visibility','hidden');
        $('#updatesBtn').hide();
        $('#updatedId, #updatesBtn').hide();
        $('#showUpdatesBtn').show();
    };

    onMarquueShow() {
        $('#marqueeId').css('visibility','visible');
        $('#showUpdatesBtn').hide();
        $('#updatesBtn').show();
        $('#updatedId, #updatesBtn').show();
    };

    onMarqueeClick(content, mOption){
        this.modalService.open(content,mOption);
        this.shortMessage = mOption.short_message;
        this.longMessage = mOption.long_message;
    }

}
