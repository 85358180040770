<div *ngIf="showModalPopup" class="modal px-2" id="idleTimeoutPrompt">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div *ngIf="agentPopupDetailsObj && agentDetailsObject" class="modal-header modal-title">
        <span id="popupHeader" *ngIf="agentPopupDetailsObj['clientType'] === 'agency' && activeSubTabId !== 4">
          <h4><b>Agent Id: {{agentPopupDetailsObj.id}} ({{getAgentNameValue(agentDetailsObject, 'Carriers')}})</b></h4>
        </span>
        <span id="popupHeader"
          *ngIf="agentDetailsObject && agentPopupDetailsObj['clientType'] === 'agency' && activeSubTabId === 4">
          <h4><b>{{getAgentNameValue(agentDetailsObject, 'Name')}}</b></h4>
        </span>
        <span id="popupHeader" *ngIf="agentPopupDetailsObj['clientType'] === 'carrier' && activeSubTabId === 4">
          <h4><b>{{agentPopupDetailsObj['popupHeading']}}: {{agentPopupDetailsObj.agentORAgentName}}</b></h4>
        </span>
        <span id="popupHeader" *ngIf="agentPopupDetailsObj['clientType'] === 'carrier' && activeSubTabId !== 4">
          <h4><b>{{agentPopupDetailsObj['popupHeading']}}: {{agentPopupDetailsObj.id}}</b></h4>
        </span>

        <button type="button" aria-label="Close" class="btn-close" (click)="closeCustom()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body p-0">
        <div *ngIf="agentPopupDetailsObj && agentPopupDetailsObj['agentMoreDetails'] === 'no' && agentDetailsObject"
          class="row po_boxbg m-0">
          <div class="col-12 p-3 agent-detail-border">
            <div class="hdr">{{agentPopupDetailsObj['crm']}} Details: </div>
            <div class="row" style="margin-top: 5px;">
              <ng-container *ngFor="let item of agentDetailsObject; let i = index">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important" *ngIf="i % 3 === 0">
                  <div class="agent-info">
                    <div class="d-flex">
                      <h4 class="agent-info-field-name">{{ item.key }}: </h4>
                      <h4 style="word-break: break-all;">&nbsp;{{ item.value }}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important" *ngIf="i % 3 === 1">
                  <div class="agent-info">
                    <div class="d-flex">
                      <h4 class="agent-info-field-name">{{ item.key }}: </h4>
                      <h4 style="word-break: break-all;">&nbsp;{{ item.value }}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important" *ngIf="i % 3 === 2">
                  <div class="agent-info">
                    <div class="d-flex">
                      <h4 class="agent-info-field-name">{{ item.key }}: </h4>
                      <h4 style="word-break: break-all;">&nbsp;{{ item.value }}</h4>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- <ng-container *ngFor="let item of agentDetailsObject; let i = index">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0-important" *ngIf="i % 2 === 0">
                  <div class="agent-info">
                    <div class="d-flex">
                      <h4 class="agent-info-field-name">{{ item.key }}: </h4>
                      <h4>&nbsp;{{ item.value }}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0-important" *ngIf="i % 2 !== 0">
                  <div class="agent-info">
                    <div class="d-flex">
                      <h4 class="agent-info-field-name">{{ item.key }}: </h4>
                      <h4>&nbsp;{{ item.value }}</h4>
                    </div>
                  </div>
                </div>
              </ng-container> -->
              <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0-important">
                <div class="agent-info">
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">{{agentPopupDetailsObj['crm']}}: </h4>
                    <h4>&nbsp;{{agentDetailsObject['CRM Id']}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">Name: </h4>
                    <h4>&nbsp;{{agentDetailsObject.name}}</h4>
                  </div>
                  <div *ngIf="agentPopupDetailsObj['clientType'] === 'carrier'" class="d-flex">
                    <h4 class="agent-info-field-name">Companies: </h4>
                    <h4>&nbsp;{{agentDetailsObject.Carriers}}</h4>
                  </div>
                  <div *ngIf="agentPopupDetailsObj['clientType'] === 'agency'" class="d-flex">
                    <h4 class="agent-info-field-name">Carriers: </h4>
                    <h4>&nbsp;{{agentDetailsObject.Carriers}}</h4>
                  </div>
                </div>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0-important">
                <div class="agent-info">
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">Agency: </h4>
                    <h4>&nbsp;{{agentDetailsObject.DirectUpline}}</h4>

                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">Contact No.: </h4>
                    <h4>&nbsp;{{agentDetailsObject.number}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name" style="word-break: break-all;">Email: </h4>
                    <h4>&nbsp;{{agentDetailsObject.address}}</h4>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div *ngIf="agentPopupDetailsObj &&  agentPopupDetailsObj['agentMoreDetails'] === 'yes' && agentDetailsObject"
          class="row po_boxbg m-0">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 agent-detail-border p-3" style="border:0px;">
            <div class="hdr">{{agentPopupDetailsObj['crm']}} Details: </div>
            <div class="row" style="margin-top: 5px;">
              <ng-container *ngFor="let item of agentDetailsObject; let i = index">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important" *ngIf="i % 3 === 0">
                  <div class="agent-info">
                    <div class="d-flex">
                      <h4 class="agent-info-field-name">{{ item.key }}: </h4>
                      <h4 style="word-break: break-all;">&nbsp;{{ item.value }}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important" *ngIf="i % 3 === 1">
                  <div class="agent-info">
                    <div class="d-flex">
                      <h4 class="agent-info-field-name">{{ item.key }}: </h4>
                      <h4 style="word-break: break-all;">&nbsp;{{ item.value }}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important" *ngIf="i % 3 === 2">
                  <div class="agent-info">
                    <div class="d-flex">
                      <h4 class="agent-info-field-name">{{ item.key }}: </h4>
                      <h4 style="word-break: break-all;">&nbsp;{{ item.value }}</h4>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important">
                <div class="agent-info">
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">{{agentPopupDetailsObj['crm']}}: </h4>
                    <h4>&nbsp;{{agentDetailsObject['CRM Id']}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">Name: </h4>
                    <h4>&nbsp;{{agentDetailsObject.name}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">NPN: </h4>
                    <h4>&nbsp;{{agentDetailsObject.NPN}}</h4>
                  </div>
                  <div class="d-flex" *ngIf="agentPopupDetailsObj['clientType'] === 'carrier'">
                    <h4 class="agent-info-field-name">Companies: </h4>
                    <h4>&nbsp;{{agentDetailsObject.Carriers}}</h4>
                  </div>
                  <div class="d-flex" *ngIf="agentPopupDetailsObj['clientType'] === 'agency'">
                    <h4 class="agent-info-field-name">Carriers: </h4>
                    <h4>&nbsp;{{agentDetailsObject.Carriers}}</h4>
                  </div>
                </div>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important">
                <div class="agent-info">
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">Agency: </h4>
                    <h4>&nbsp;{{agentDetailsObject.DirectUpline}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">Contact No: </h4>
                    <h4>&nbsp;{{agentDetailsObject.number}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">State: </h4>
                    <h4>&nbsp;{{agentDetailsObject.state}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">Email: </h4>
                    <h4 style="word-break: break-all;">&nbsp;{{agentDetailsObject.address}}</h4>
                  </div>
                </div>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important">
                <div class="agent-info">
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">Course Name: </h4>
                    <h4>&nbsp;{{agentDetailsObject.course_name}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">License Number: </h4>
                    <h4>&nbsp;{{agentDetailsObject.license_number}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">License Type: </h4>
                    <h4>&nbsp;{{agentDetailsObject.license_type}}</h4>
                  </div>
                  <div class="d-flex">
                    <h4 class="agent-info-field-name">License Status: </h4>
                    <h4>&nbsp;{{agentDetailsObject.license_status}}</h4>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div *ngIf="activeTabId==3 && activeSubTabId ==4" id="showTooltip" class="performance-popup-show-tooltip">
          <table
            style='font-size: 12px;background-color: white;margin-left: 320px;margin-top: 33%;position: absolute;border: 2px solid #80808052; z-index: 999;display: block;padding-left: 7px;'>
            <thead style="font-weight: 400px;font-family: 'Montserrat', sans-serif;">
              <tr style='border-bottom: 1px solid #dddddd; height: 25px ; font-weight: 700;'>
                <td style="padding-left: 0px;border-right: 1px solid #ccc;">Category</td>
                <th>{{getMonthAndYear(agentPopupDetailsObj.selectedMonth, curr_year)}}</th>
                <th>{{getMonthAndYear(agentPopupDetailsObj.selectedMonth, last_year)}}</th>
                <th>Variance (#)</th>
                <th>Variance (%)</th>
                <th>
                  <button id="cancelTooltip" type="button" class="close" aria-hidden="true"
                    (click)="cancelTooltip()">×</button>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="agentPopupDetailsObj.tooltipData">
              <tr *ngFor="let category of agentPopupDetailsObj.tooltipData" style='height: 25px;'>
                <td
                  style="font-weight: 700;text-align: left;padding-left: 0px !important;border-right: 1px solid #ccc;">
                  {{category.sub_category}}</td>
                <td *ngIf="agentPopupDetailsObj.performanceToggleType === '#'">
                  {{category['CY_Policies'] |number : '1.0-0'}}</td>
                <td *ngIf="agentPopupDetailsObj.performanceToggleType === '#'">
                  {{category['LY_Policies'] | number : '1.0-0'}}</td>
                <td *ngIf="agentPopupDetailsObj.performanceToggleType === '#'"
                  [ngClass]="{ 'green-color': category['Variance_Policies'] > 0, 'red-color': category['Variance_Policies'] < 0 }">
                  {{getMyColor(category['Variance_Policies']) | number : '1.0-0'}} </td>
                <td *ngIf="agentPopupDetailsObj.performanceToggleType === '#'"
                  [ngClass]="{ 'green-color': category['Variance_Percent_Policies'] > 0, 'red-color': category['Variance_Percent_Policies'] < 0 }">
                  {{getMyColor(category['Variance_Percent_Policies']) |number : '1.0-0'}}</td>
                <td *ngIf="agentPopupDetailsObj.performanceToggleType === '$'" class="text-end">
                  ${{category['CY_Premium'] | number : '1.0-0'}}</td>
                <td *ngIf="agentPopupDetailsObj.performanceToggleType === '$'" class="text-end">
                  ${{category['LY_Premium'] | number : '1.0-0'}}</td>
                <td *ngIf="agentPopupDetailsObj.performanceToggleType === '$'" class="text-end"
                  [ngClass]="{ 'green-color': category['Variance_Premium'] > 0, 'red-color': category['Variance_Premium'] < 0  }">
                  ${{getMyColor(category['Variance_Premium']) | number : '1.0-0'}} </td>
                <td class="get-my-color" *ngIf="agentPopupDetailsObj.performanceToggleType === '$'"
                  [ngClass]="{ 'green-color': category['Variance_Percent_Premium'] > 0, 'red-color': category['Variance_Percent_Premium'] < 0 }">
                  {{getMyColor(category['Variance_Percent_Premium']) | number : '1.0-0'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 px-0">
        <div class="row mx-3 my-2 d-flex justify-content-end">
          <div *ngIf="agentPopupDetailsObj.idType === 'crm' && activeTabId==3 && activeSubTabId ==4"
            class="col-6 col-md-4 col-lg-2 px-1 my-1">
            <ng-multiselect-dropdown id="carrier_agency_multiselect" [settings]="carrierOrAgencyDropdownSettings"
              [data]="carrierOrAgencyDropdownList" [(ngModel)]="carrierOrAgencySelectedItems"
              [placeholder]="'Select carrier/agency'" (onSelect)="carrierOrAgencyOnItemSelect($event)"
              (onSelectAll)="carrierOrAgencyOnSelectAll($event)" (onDeSelect)="carrierOrAgencyOnDeSelect($event)"
              (onDeSelectAll)="carrierOrAgencyOnDeSelectAll($event)">
            </ng-multiselect-dropdown>
          </div>
          <div
            *ngIf="clientType === 'carrier' && lineOfBusinessFilter === 'yes' && activeTabId==3 && activeSubTabId ==4"
            class="col-6 col-md-4 col-lg-2 my-1 px-1">
            <select style="background-position-y: 2px;font-size: 13px;" class="form-control height-28"
              [(ngModel)]="modalLOB" (change)="popupLobChanged(modalLOB, agentDetailsObject)" required>
              <option>All</option>
              <option *ngFor="let lob of popupLineOfBusiness" [ngValue]="lob.category">{{lob.category}}</option>
            </select>
          </div>
          <div *ngIf="activeTabId==3 && activeSubTabId ==4" class="col-6 col-md-4 col-lg-2 my-1 px-1">
            <select style="background-position-y: 2px;font-size: 13px;" class="form-control height-28"
              [(ngModel)]="modalProduct" (change)="popupProductChanged(modalProduct, agentDetailsObject)" required>
              <option>All</option>
              <option *ngFor="let product of popupProducts" [ngValue]="product.category">{{product.category}}</option>
            </select>
          </div>
          <div *ngIf="activeTabId==3 && activeSubTabId ==4" class="col-6 col-md-4 col-lg-2 my-1 px-1">
            <ng-multiselect-dropdown id="policy_multiselect" [settings]="policyDropdownSettings"
              [placeholder]="'Select Policystatus'" [data]="policyDropdownList" [(ngModel)]="policySelectedItems"
              (onSelect)="policyOnItemSelect($event)" (onSelectAll)="policyOnSelectAll($event)"
              (onDeSelect)="policyOnDeSelect($event)" (onDeSelectAll)="policyOnDeSelectAll($event)">
            </ng-multiselect-dropdown>
          </div>
          <div class="col-6 col-md-4 col-lg-2 px-1 my-1 d-flex position-relative">
            <!-- <div class="date-label-style control-label" for="date">Fr:</div>
                    <input type="text"
                      class="form-control date cursor-pointer"
                      #popfromdp="bsDatepicker"
                      bsDatepicker
                      value="{{ fromDate | date:'dd-MMM-yy' }}"
                      style="pointer-events: none;border-left: none;border-radius: inherit; padding-left: 0px;"
                      (bsValueChange)="changePopupChartData($event, 'fromDate')"
                      [(bsValue)]="fromDate"
                      [bsConfig]="{showWeekNumbers:false}">
                    <span class="input-group-addon" (click)="popfromdp.toggle()">
                      <span class="fa fa-calendar pointer"></span>
                    </span> -->
            <div class="date-label-style control-label" for="date">Fr:</div>
            <input type="text" class="form-control date cursor-pointer overflow-ellipsis selector-date-input"
              #dp="bsDatepicker" bsDatepicker (bsValueChange)="changePopupChartData($event, 'fromDate')"
              [(bsValue)]="agentPopupDetailsObj.fromDate" [bsConfig]="{showWeekNumbers:false}">
            <span class="input-group-addon" (click)="dp.toggle()">
              <span class="fa fa-calendar pointer"></span>
            </span>
            <!-- value="{{ agentPopupDetailsObj.fromDate | date:'dd-MMM-yy' }}" -->
          </div>
          <div class="col-6 col-md-4 col-lg-2 my-1 px-1 d-flex position-relative">
            <div class="date-label-style control-label" for="date">To:</div>
            <input type="text" class="form-control date cursor-pointer overflow-ellipsis selector-date-input"
              #poptodp="bsDatepicker" bsDatepicker (bsValueChange)="changePopupChartData($event, 'toDate')"
              [(bsValue)]="agentPopupDetailsObj.toDate" [bsConfig]="{showWeekNumbers:false}">
            <span class="input-group-addon" (click)="poptodp.toggle()">
              <span class="fa fa-calendar pointer"></span>
            </span>
            <!-- value="{{ agentPopupDetailsObj.toDate | date:'dd-MMM-yy' }}" -->
          </div>
          <div *ngIf="isTAPRequired === 'yes' && activeSubTabId === 4"
            class="col-6 col-md-4 col-lg-2 my-1 px-1 position-relative">
            <ui-switch id="performanceSapTapToggle" checkedTextColor="#ffffff" uncheckedTextColor="#ffffff"
              uncheckedLabel="SAP" checkedLabel="TAP" [(ngModel)]="performanceSapTapEnable"
              (change)="onPerformanceSapTapToggleon($event, agentPopupDetailsObj)"></ui-switch>
          </div>
        </div>

        <div class="card mt-3 m-5 mainpanal" style="margin: 0rem 1rem !important;">
          <!-- <div class="card-header"> -->
          <!-- <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12" style="padding:0;margin-bottom: 10px;">
                    <div class="col-xs-12 col-sm-12 col-lg-2 padding-right-0" id="performanceCarrierContainer">
                        <div id="carrierId" ng-dropdown-multiselect="" options="object.getCarrierData"
                            selected-model="object.carrierDataModel"  events="{ onItemSelect: onCarrierSelect, onItemDeselect:onCarrierDeSelect, onSelectAll: onCarrierSelectAll, onDeselectAll:onCarrierDeSelectAll}" checkboxes="true"
                            extra-settings="object.carrierDataSettings" translation-texts="object.carrierCustomTexts">
                        </div>
                    </div> -->
          <!-- <div *ngIf="clientType === 'carrier'" class="col-xs-12 col-sm-12 col-lg-2 padding-right-0">
                        <select style="background-position-y: 2px;font-size: 13px;" class="form-control height-28" ng-model="modalLOB"
                            ng-change="lobChanged(modalLOB, object)" required>
                            <option disabled selected>Select Product</option>
                            <option>All</option>
                            <option ng-repeat="lob in object.lineOfBusiness">{{lob.category}}</option>
                        </select>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-lg-2 padding-right-0">
                        <select style="background-position-y: 2px;font-size: 13px;" class="form-control height-28"
                            ng-model="modalProduct" ng-change="anlyticsProductChanged(modalProduct, object)" required>
                            <option disabled selected>Select Product Type</option>
                            <option>All</option>
                            <option ng-repeat="product in object.products">{{product.category}}</option>
                        </select>
                    </div> -->
          <!-- <div class="col-xs-12 col-sm-12 col-lg-2 padding-right-0">
                        <div id="policystatus" ng-dropdown-multiselect="" options="object.popupPolicyStatus"
                            selected-model="object.policyStatusModel"  events="{ onItemSelect: onPolicySelect, onItemDeselect:onPolicyDeSelect, onSelectAll: onPolicySelectAll, onDeselectAll:onPolicyDeSelectAll}" checkboxes="true"
                            extra-settings="object.policyStatusSettings" translation-texts="object.policyStatusCustomTexts">
                        </div>
                    </div>             -->

          <!-- <div> -->
          <!-- <span ng-show="clientType === 'carrier' && isTAPRequired === 'yes'" id="performancePopupBtn" class="col-xs-2 col-sm-2 col-lg-2" style="position: relative; width: 7%;">
                            <switch  ng-model="performanceTapYesNo" ng-change="onPopupToggleChange(performanceTapYesNo, object)" on="SAP" off="TAP" class="switch wide" style="margin-left: -17px;z-index: 1;"></switch>
                        </span> -->
          <!-- <button style="height: 27px;border-radius: 5px; color: white;line-height:1" type="button"
                            class="btn btn-success"> Go </button> -->
          <!-- </div> -->
          <!-- </div> -->
          <div class="card-body row m-0 p-0">
            <div class="col-12">
              <div class="panel panel-default row m-0">
                <div class="col-xs-12 col-md-12 col-lg-12" style="border-bottom: 1px solid #EEE;">
                  <div class="row mx-0">
                    <div class="col px-0">
                      <div id="toggle" class="toggleBtn" style="padding: 0;">
                        <ul class="nav nav-tabs top-tab popup-top-tab" id="businessByToggleBtn">
                          <li *ngIf="isApplicationsApplicable === 'yes'" class="active cursor-pointer"
                            api="getPendingBusiness" type="applications">
                            <a type="applications" class="nav-link ng-binding popupTab"
                              (click)="updatePieCharts('applications', $event, false)"
                              style="padding: 10px 0px;">Applications (#) </a>
                          </li>
                          <li [ngClass]="{ 'active': isApplicationsApplicable === 'no' }" class="cursor-pointer"
                            api="getPendingBusiness" type="#">
                            <a type="#" class="nav-link ng-binding popupTab"
                              (click)="updatePieCharts('#', $event, false)" style="padding: 10px 0px;">Policy (#)</a>
                          </li>
                          <!-- <li class="active" api="getPendingBusiness" class="cursor-pointer" type="#">
                                                      <a type="#" class="nav-link ng-binding popupTab active" style1="padding: 5px 0px;"
                                                      (click)="updatePieCharts('#', $event, false)">Policy (#)</a>
                                                  </li> -->
                          <li type="$" class="cursor-pointer">
                            <a type="$" class="nav-link ng-binding popupTab" style="padding: 10px 0px;"
                              (click)="updatePieCharts('$', $event, false)">Premium ($)</a>
                          </li>
                          <li *ngIf="showOverride === 1" class="cursor-pointer" api="getPendingBusiness"
                            type="commission">
                            <a *ngIf="clientType === 'carrier'" type="commission" style="padding: 10px 0px;"
                              class="nav-link ng-binding popupTab"
                              (click)="updatePieCharts('commission', $event, false)">Commission ($)</a>
                            <a *ngIf="clientType !== 'carrier'" type="commission" style="padding: 10px 0px;"
                              class="nav-link ng-binding popupTab"
                              (click)="updatePieCharts('commission', $event, false)">Override ($)</a>
                          </li>
                          <!-- <li *ngIf="showOverride === 1" api="getPendingBusiness"
                                                        type="commission">
                                                        <a *ngIf="clientType === 'carrier'" href="" type="commission"
                                                            class="nav-link ng-binding popupTab" style="padding: 5px 0px;"
                                                            ng-click="updatePieCharts('commission', $event, false)">Commission ($)</a>
                                                        <a *ngIf="clientType !== 'carrier'" href="" type="commission"
                                                            class="nav-link ng-binding popupTab" style="padding: 5px 0px;"
                                                            ng-click="updatePieCharts('commission', $event, false)">Override ($)</a>
                                                    </li> -->
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-body d-flex flex-column">
                  <div class="row mx-0 mt-3">
                    <div class="col-6 d-flex align-items-center flex-column" id="pieChart1">
                      <div class="">
                        <h5 *ngIf="isApplicationsApplicable === 'yes' && clientType === 'carrier'"
                          style="text-align: center;font-size: 18px;color: #4cae4c;"><b>Applications by Agency:</b></h5>
                        <h5 *ngIf="isApplicationsApplicable === 'no' && clientType === 'carrier'"
                          style="text-align: center;font-size: 18px;color: #4cae4c;"><b>Policies by Agency:</b></h5>
                        <!-- <h5 *ngIf="clientType === 'carrier'" style="text-align: center;font-size: 18px;color: #4cae4c;"><b>Policies by
                                                    Agency:</b></h5> -->
                        <h5 *ngIf="clientType === 'agency'" style="text-align: center;font-size: 18px; color: #4cae4c;">
                          <b>Policies by
                            Carrier:</b>
                        </h5>
                        <nvd3 [options]="agentPopupDetailsObj.agentCarrierGraphOptions" id="performancepieChart1"
                          [data]="agentPopupDetailsObj.agentCarrierGraphdata"></nvd3>
                      </div>
                    </div>
                    <div class="col-6 d-flex align-items-center flex-column" id="pieChart2">
                      <div class="">
                        <!-- <h5 style="text-align: center;font-size: 18px;color: #4cae4c;"><b>Policies by Product Line:</b></h5> -->
                        <h5 *ngIf="isApplicationsApplicable === 'no'" id="pieChart2label"
                          style="text-align: center;font-size: 18px;color: #4cae4c;"><b>Policies by Product Line:</b>
                        </h5>
                        <h5 *ngIf="isApplicationsApplicable === 'yes'" id="pieChart2label"
                          style="text-align: center;font-size: 18px;color: #4cae4c;"><b>Applications by Product
                            Line:</b></h5>
                        <nvd3 [options]="agentPopupDetailsObj.agentGraphOptions"
                          [data]="agentPopupDetailsObj.agentGraphdata" id="performancepieChart2"></nvd3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activeTabId==3 && activeSubTabId ==4" id="yoyComparisionContainer"
          class="card mt-3 m-5 p-2 mainpanal" style="margin: 1rem 1rem !important;">
          <div class="card-body" style="padding: 0px;">
            <div class="col-xs-12 col-sm-12 col-lg-12">
              <div class="panel panel-default row mx-0">
                <div style="padding-left: 10px;font-size: 18px; color: #4cae4c;padding-top: 10px"><b>YOY Comparison</b>
                </div>
                <div id="performanceChartToggle" class="col-xs-12 col-sm-12 col-lg-12 toggleBtn">
                  <ul class="nav nav-tabs top-tab popup-top-tab">
                    <!-- <li class="active" type="#" style="margin-right: 15px">
                        <a type="#" class="nav-link ng-binding popupTab" style="padding: 5px 0px;"
                        ng-click="updateLineCharts('#', $event)">Policy (#)</a>
                      </li>
                      <li type="$" style="margin-right: 15px">
                        <a type="$" class="nav-link ng-binding popupTab" style="padding: 5px 0px;"
                            ng-click="updateLineCharts('$', $event)">Premium ($)</a>
                      </li> -->
                    <li class="active cursor-pointer" type="#">
                      <a id="yoy_policy_tab" class="nav-link ng-binding popupTab" style="padding: 10px 0px;"
                        (click)="updateYOYLineCharts('#', $event); cancelTooltip();">Policy (#)</a>
                    </li>
                    <li class="cursor-pointer" type="$">
                      <a id="yoy_premium_tab" class="nav-link ng-binding popupTab" style="padding: 10px 0px;"
                        (click)="updateYOYLineCharts('$', $event); cancelTooltip();">Premium ($)</a>
                    </li>
                  </ul>
                </div>
                <div class="panel-body">
                  <nvd3 [options]="agentPopupDetailsObj.yoyCompLineOptions"
                    [data]="agentPopupDetailsObj.yoyCompLineData" id="performanceYoyCompLineChart">
                  </nvd3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mx-3 my-2 mainpanal p-2">
          <div class="panel panel-default row mx-0 my-2">
            <div class="col-12">
              <div class="row mx-0 mb-2">
                <div class="col-4 col-md-2 p-0">
                  <div
                    *ngIf="activeTabId!=3 && activeSubTabId !=4 && agentPopupDetailsObj && agentPopupDetailsObj['products']"
                    class="">
                    <select style="background-position-y: 2px;font-size: 13px;" class="form-control height-28"
                      [(ngModel)]="agentPopupDetailsObj['selectedProduct']"
                      (change)="productChanged(agentPopupDetailsObj.selectedProduct, agentPopupDetailsObj.awnHiddenId, '', false, 'getProductsByAgent')"
                      required>
                      <option>All</option>
                      <option *ngFor="let option of agentPopupDetailsObj['products']">{{option.category}}</option>
                    </select>
                    <!-- <ul class="list-unstyled selectFilter" style="border: 1px #cecece solid;height: 30px;height: auto;max-height: 190px;overflow-y: auto;">
                                          <li class="option" value="All" (click)="productChanged(agentPopupDetailsObj.selectedProduct, '{{agentPopupDetailsObj.id}}', agentPopupDetailsObj.agentMarketingCompany)">All</li>
                                          <li class="option"  value="option.category">{{option.category}}</li>
                                      </ul> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <ul class="nav nav-tabs top-tab popup-top-tab" id="popupTabs">
                <!-- <li class="active cursor-pointer" api="getAgentSales" style="margin-right: 15px;">
                                        <a id="agent_sales_tab" class="nav-link ng-binding popupTab active" style="padding: 5px 0px;"
                                          (click)="policyOrPremiumChanged($event, agentPopupDetailsObj.selectedProduct, agentPopupDetailsObj.awnHiddenId)">Premium ($)</a>
                                    </li>
                                    <li api="getPendingBusiness" style="margin-right: 15px;" class="cursor-pointer">
                                        <a id="agent_pending_tab" class="nav-link ng-binding popupTab" style="padding: 5px 0px;"
                                          (click)="policyOrPremiumChanged($event, agentPopupDetailsObj.selectedProduct, agentPopupDetailsObj.awnHiddenId)">Pending Policies</a>
                                    </li> -->

                <li id="Premium_tab" class="active" *ngIf="showPremiumSubtab === 'yes'" api="getAgentSales">
                  <a id="agent_sales_tab" class="nav-link ng-binding popupTab cursor-pointer" style="padding: 10px 0px;"
                    (click)="policyOrPremiumChanged($event, agentPopupDetailsObj.selectedProduct, agentPopupDetailsObj.awnHiddenId, 'getAgentSales', false, 'agent_sales_tab','Premium_tab')">Premium
                    ($)</a>
                </li>
                <li id="Pending_tab" *ngIf="isApplicationsApplicable === 'no'" api="getPendingBusiness">
                  <a id="agent_pending_tab" class="nav-link ng-binding popupTab cursor-pointer"
                    style="padding: 10px 0px;"
                    (click)="policyOrPremiumChanged($event, agentPopupDetailsObj.selectedProduct, agentPopupDetailsObj.awnHiddenId, 'getPendingBusiness',false, 'agent_pending_tab','Pending_tab')">Pending</a>
                </li>
                <li id="Submitted_tab" *ngIf="isApplicationsApplicable === 'yes' && showPopupSubmittedSubTab === 'yes'"
                  class="active" api="getAgentApplicationSales">
                  <a id="agent_pending_tab" class="nav-link ng-binding popupTab cursor-pointer"
                    style="padding: 10px 0px;"
                    (click)="policyOrPremiumChanged($event, agentPopupDetailsObj.selectedProduct, agentPopupDetailsObj.awnHiddenId, 'getAgentApplicationSales',false, 'agent_pending_tab','Submitted_tab')">Submitted</a>
                </li>
                <li id="Pending_tab2" *ngIf="isApplicationsApplicable === 'yes' && showPopupSubmittedSubTab === 'no'"
                  api="getPendingBusiness">
                  <a id="agent_pending_tab" class="nav-link ng-binding popupTab cursor-pointer"
                    style="padding: 10px 0px;"
                    (click)="policyOrPremiumChanged($event, agentPopupDetailsObj.selectedProduct, agentPopupDetailsObj.awnHiddenId, 'getPendingBusiness',false, 'agent_pending_tab','Pending_tab2')">Pending</a>
                </li>
              </ul>
            </div>
            <div class="panel-body col-12">
              <nvd3 [options]="agentPopupDetailsObj.agentSalesOptions" [data]="agentPopupDetailsObj.agentSalesData"
                id="performanceLinePlusBarChart">
              </nvd3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="col-xs-12 col-sm-12 col-lg-12" style="padding-left: 0px;padding-right: 0px;">
  <div id="breadcrumb" *ngIf="activeTabId !== 4 && activeTabId !== 6 && activeTabId !== 7">
    <ul style="list-style: none;padding: 0px;display: flex;margin-bottom: 0.5rem;">
      <li #breadcrumbDiv><a (click)="getHomeBreadCrumbData()" class="cursor-pointer"><span style="font-size: 12px;"
            class="fa fa-home"> </span></a></li>
    </ul>
  </div>
  <div id="terminatedAgentRecords" *ngIf="(activeTabId === 2 && activeSubTabId !== 2)"
    style="margin-bottom: 5px;display:flex;font-style: italic;">
    <div style="height: 12px;width: 12px;border-radius: 10px;background-color: #FFBF00;"></div>
    <div style="font-size: 14px;padding-left: 5px;line-height: 1;">Terminated Agent Records</div>
  </div>
  <div *ngIf="apiName=='getManagerQuarterlyIncentiveReport'"
    style="margin-bottom: 5px;display:flex;font-style: italic;">
    <div style="height: 12px;width: 12px;border-radius: 10px;background-color: #aeffb4;"></div>
    <div style="font-size: 14px;padding-left: 5px;line-height: 1;">New Recruited Agent</div>
    <div class="ms-2" style="height: 12px;width: 12px;border-radius: 10px;background-color: #ffff4f;"></div>
    <div style="font-size: 14px;padding-left: 5px;line-height: 1;">Agent On Pace</div>
  </div>
  <div *ngIf="apiName=='getSipIncentiveReport'" style="margin-bottom: 5px;display:flex;font-style: italic;">
    <div style="font-size: 14px;padding-left: 5px;line-height: 1;">Progress chart is only applicable for Agent level.
    </div>
  </div>
  <div *ngIf="apiName=='getManagerSipIncentiveReport'" style="margin-bottom: 5px;display:flex;font-style: italic;">
    <div style="font-size: 14px;padding-left: 5px;line-height: 1;">Progress chart is only applicable for Manager level.
    </div>
  </div>
  <div *ngIf="activeTabId === 2 && activeSubTabId === 1">
    <div id="agentNameTooltip"
      style="float: right;font-size:14px;color: #9BADB5;position: relative;top: -25px;margin-bottom: -22px;">
      <div id="agentNameTooltipSpan" class="info-circle" (mouseover)="onMouseover($event)" type="button"
        data-toggle="tooltip" data-placement="left"
        title="Choose any combination of agents on the current page to export by clicking on their name(s). This feature does not allow selecting agents across multiple pages."
        style="z-index:1;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-info-circle-fill" viewBox="0 0 16 16">
          <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </svg>
      </div>
    </div>
  </div>
  <div id="terminatedAgentRecords"
    *ngIf="(activeTabId === 3 && activeSubTabId !== 3) || (activeTabId === 5 && activeSubTabId !== 27)"
    style="margin-bottom: 5px;display:flex;font-style: italic;">
    <div style="height: 12px;width: 12px;border-radius: 10px;background-color: #FFBF00;"></div>
    <div style="font-size: 14px;padding-left: 5px;line-height: 1;">Terminated Agent Records</div>
  </div>
  <div #tableContainer id="tableContainer" class="table-mrg tabel_scroll overflow-auto" style="white-space: nowrap;" (mouseover)="onMouseover($event)">
    <table id="table2" class="display" width="100%"></table>
    <div id="noDataAvailable" class='no-data' style="display:none;font-size:14px;">No Data Available</div>
  </div>
  <!-- <table #tableContainer id="tableContainer" class="table-mrg"></table> -->
</div>