import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface CallerDetails {
  component: string;
  functionName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ButtonClickService {
  private buttonClickSubject = new Subject<CallerDetails>();

  // Method to trigger button click event with caller details
  public buttonClick(component: string, functionName: string): void {
    const callerDetails: CallerDetails = { component, functionName };
    this.buttonClickSubject.next(callerDetails);
  }

  // Observable to subscribe to button click events
  public onButtonClick(): Observable<CallerDetails> {
    return this.buttonClickSubject.asObservable();
  }
}
