<!-- <div class="login" *ngIf="showLogoutPage">
    <div class="row w-100 m-0">
        <div class="col-5 p-0">
                <img class="login w-100"  src="assets/images/AgentVizionLogo.png" style="height:100vh" >
        </div>
        <div class="col-7 position-relative login-right-container login p-0" style="margin-top: 7rem">
    
            <div class="d-flex justify-content-center align-items-center">
                <div class="login-form ">
                    <div class="login-img-title">
                        <div><img src="assets/{{logo}}" class="logo"></div>
                        <div class="logout-center">
                            <p>You have successfully been logged out!</p>
                        </div>
                    </div>               
                    <div class="text-align-center">
                        <img src="assets/images/AgentVizion.png" width="120" class="login-footer-img">
                        <div class="line "></div>                        
                    </div>
    
                </div>
    
            </div>
            <div class="d-flex justify-content-center position-absolute align-items-end w-100" style="bottom: 0;background-color: #ccc;">
                <div class="footer-text ">
                    <div class="">
                        <div class="login-footer-patent-left">
                            <a target="_blank" href="http://www.magnifact.com/">
                                <span><img src="assets/images/NHMLogo.png"></span>
                            </a>
                        </div>
                        <div class="login-footer-patent-right">
                            <p>
                            AgentVizion is a registered trademark of Magnifact, LLC., and is protected by U.S. Patent D917,520. Any and all material in this product and related documentation is deemed the intellectual property of Magnifact, LLC., and contains information which is privileged, confidential and prohibited from disclosure or unauthorized use under applicable law.</p>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    
    </div> -->


<div class="position-relative" *ngIf="showLogoutPage">
    <div class="logout-background-image-container"></div>
    <div class="position-fixed" style="bottom: 0;">
        <img src="../../../assets/images/Rectangle.png" alt="" class="w-100">
    </div>
    <div class="d-flex flex-column overflow-auto" style="height: 100vh;">
        <div class="d-flex login-right-container align-items-center">
            <div class="background-white login-card position-relative d-flex flex-column text-center">
                <div class="login-img-title">
                    <!-- <div><img src="assets/images/iptiq.jpg" class="logo"></div> -->
                    <div><img src="assets/{{logo}}" class="logo"></div>
                </div>
                <div class="text-white p-3 mx-5 logout-msg-container position-relative">
                    <div class="logout-tickmark-icon-container">
                        <img src="assets/images/icon_Tick mark@3x.png" alt="">
                    </div>
                    <div>You have been successfully logged out.</div>
                </div>
                <div class="text-center bottom-logo-container mt-5">
                    <img src="assets/images/AgentVizion.png" width="90" class="login-footer-img">
                    <hr>
                    <span class="appVersion top-border">Version: {{app_version}}</span>
                    <div class="patentPendingContainer">U.S. Patent D917,520</div>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-end w-100 h-auto login-footer-patent">
            <div class="footer-text ">
                <div class="py-2 row m-0">
                    <div
                        class="login-footer-patent-left pr-2 pl-0 col-12 col-md-2 d-flex align-items-center justify-content-center">
                        <a target="_blank" href="http://www.magnifact.com/">
                            <span><img src="assets/images/NHMLogo.png" class="w-100"></span>
                        </a>
                    </div>
                    <div class="login-footer-patent-right px-2 col-12 col-md-8 text-justify d-flex align-items-center">
                        <p class="my-0">
                            AgentVizion is a registered trademark of Magnifact, LLC., and is protected by U.S. Patent
                            D917,520. Any and all material in this product and related documentation is deemed the
                            intellectual property of Magnifact, LLC., and contains information which is privileged,
                            confidential and prohibited from disclosure or unauthorized use under applicable law.</p>
                    </div>
                    <div class="col-6 col-md-1 px-1 d-flex align-items-center justify-content-end">
                        <img src="assets/images/apple_store.png" alt="applestore" class="w-100"
                            style="max-width: 100px; border-radius: 5px;">
                    </div>
                    <div class="col-6 col-md-1 px-1 d-flex align-items-center">
                        <img src="assets/images/google_playstore.png" alt="playstore" class="w-100"
                            style="max-width: 100px; border-radius: 5px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>