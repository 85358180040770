import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/shared/services/common.service';
import { CommonConstant } from './../../shared/constants/common-constant';
import { configJson } from '../../shared/services/dashboard-config.service';
import { filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

declare var firebase: any;
declare function isFirebaseSWLoaded(): void;

export class TableConfig {
  tableId: string = '';
  apiName: string = '';
  exportName: string = '';
  columnDefs: Array<object> = [];
  scrollY: string = '';
  paging: string = '';
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [CommonConstant]
})
export class HomeComponent implements OnInit, AfterViewInit {
  isSelectorLoaded: any = false;
  SSOLogin: any;
  isHeaderLoaded: boolean = false;
  activeRoute = [];
  firebaseConfig = {
    apiKey: "AIzaSyBzrJKfIx1GTBQXT5I77-oxJ5MDxJ-K6B0",
    authDomain: "agentvizion-a86b1.firebaseapp.com",
    projectId: "agentvizion-a86b1",
    storageBucket: "agentvizion-a86b1.appspot.com",
    messagingSenderId: "402130589295",
    appId: "1:402130589295:web:a5e0773d70121363e35f9a"
  };
  messaging;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    isFirebaseSWLoaded();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // this.currentRoute = event.url;          
      console.log(event);
      this.activeRoute = event.url.split('/');
    });
  }

  ngOnInit(): void {
    if (!firebase.apps.length) {
      firebase.initializeApp(this.firebaseConfig);
    } else {
      firebase.app(); // if already initialized, use that one
    }
    this.messaging = firebase.messaging();
    // window.addEventListener('load', function() {
    // Registration was successful
    this.messaging.requestPermission().then(m => {
      this.getDeviceToken();
    }).catch((e) => {
      console.log("error in getting permission", e)
    });
    // });
  }

  ngAfterViewInit(): void {
    this.commonService.checkSelectorLoaded.subscribe(res => {
      this.isSelectorLoaded = res;
    })
  }

  ngOnDestroy() {
    if (this.commonService.checkSelectorLoaded) {
      this.isSelectorLoaded = false;
    }
  }

  updateUserDeviceToken(token) {
    let params = {
      device_type: 'web',
      device_token: token,
      device_name: this.getUserBrowserName(),
    }
    this.commonService.updateDeviceToken(params).subscribe(data => {
      console.log(data);
    }, (err: HttpErrorResponse) => {
      console.log('failed to update device token')
    })
  }

  getUserBrowserName() {
    // Get the user agent string
    let userAgent = navigator.userAgent;

    // Parse the user agent string
    let browserName = userAgent.match(/(Chrome|Firefox|Safari|Opera|Edge)/)[0];

    // Check the browser name
    // if (browserName === "Chrome") {
    //   console.log("You are using Chrome");
    // } else if (browserName === "Firefox") {
    //   console.log("You are using Firefox");
    // } else if (browserName === "Safari") {
    //   console.log("You are using Safari");
    // } else if (browserName === "Opera") {
    //   console.log("You are using Opera");
    // } else if (browserName === "Edge") {
    //   console.log("You are using Edge");
    // } else {
    //   console.log("Unknown browser");
    // }
    return browserName;
  }

  retryCount = 0;
  getDeviceToken() {
    this.retryCount++;
    this.messaging.getToken().then(t => {
      // console.log(t);
      this.updateUserDeviceToken(t);
    }).catch((e) => {
      console.log("error in getting token retrying==>", this.retryCount, e);
      if (this.retryCount <= 5) {
        setTimeout(() => this.getDeviceToken(), 1000 * (this.retryCount))
      }
    });
  }
}
