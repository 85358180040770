
export default class Helper {

 public static convertToNumber(value) {
    if(typeof value === 'string') {
      return parseFloat(value.replace(/,/g, ''))
    }
    return parseFloat(value);
  }
}
