import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Notification, NotificationType } from "./notification";
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

notifications: Notification[] = [];
private _subscription: Subscription;

constructor(private modalService: NgbModal, private _notificationSvc: NotificationService) { }

private _addNotification(notification: Notification) {
  this.notifications.push(notification);
  if (notification.timeout !== 0) {
    setTimeout(() => this.close(notification), notification.timeout);
  }
}

ngOnInit() {
  this._subscription = this._notificationSvc.getObservable().subscribe(notification => {
    console.log(notification);
    this._addNotification(notification)
  });
}

ngOnDestroy() {
  this._subscription.unsubscribe();
}

close(notification: Notification) {
  this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
}

className(notification: Notification): string {
    let style: string;
    switch (notification.type) {

      case NotificationType.success:
        style = 'success';
        break;

      case NotificationType.warning:
        style = 'warning';
        break;

      case NotificationType.error:
        style = 'error';
        break;

      default:
        style = 'info';
        break;
    }
    return style;
}

}
