
<app-header-selectors></app-header-selectors>
<div class="mb-3 heightvhForgot1">
    <div class="col-lg-12 col-sm-12 col-md-12">
        <div>
            <form [formGroup]="fpform">
<!--                 <div>
                    <div class="patentPendingContainer text-align-center">U.S. Patent D917,520</div>
                    <img src="assets/images/AgentVizion.png" width="201" class="fp-av-logo">
                    <span class="appVersion">Version: {{app_version}}</span>
                </div> -->

                <div class="text-align-center text-center">
                    <img src="assets/images/AgentVizion.png" width="120" class="login-footer-img"><br>
                    <div class="line"></div><br>
                    <span class="appVersion top-border">Version: {{app_version}}</span>
                    <div class=" mb-3  patentPendingContainer">U.S. Patent D917,520</div>
                </div>

                <div *ngIf="isExpired">
                    <div class="fix" style="color: red; text-align: center;">
                        <STRONG>This link has been expired</STRONG>
                        <a href='/login'> (Go to Home Page)</a>
                    </div>
                </div>

                <div *ngIf="!isExpired" class="login-form-wrap">
                    <div class="form-content-wrap">
                        <h2 class="text-center">Forgot Password</h2>
                        <div id="flashMessagesContainer" class="flash-msg-container">
                            <flash-messages></flash-messages>
                        </div>
                        <div class="form-group" id="userNameContainer" >
                            <label>Email</label>
                            <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email is invalid</div>
                            </div>
                        </div>
                        <!-- <div class="form-group" id="userNameContainer">
                            <label for="username">Email</label>
                            <input type="email" name="email" class="form-control" id="email1" placeholder="email" required="" value='{{emailId}}' disabled>
                        </div> -->
                        <div id="forgotnewPwd" class="form-group">
                            <label>New Password</label>
                            <input
                                type="password"
                                formControlName="password"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                                <div *ngIf="f.password.errors.pattern">
                                    Password must be 8-15 characters containing 1 capital, 1 lower and 1 special character (!,@,#,,%,&,*)
                                </div>
                                <div *ngIf="f.password.errors.maxlength">
                                    Password must not exceed 15 characters
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group" ng-class="{ 'has-error': form.newpassword.$dirty && form.newpassword.$error.required }">
                            <label for="newpassword">New Password</label>
                            <input type="password" name="newpassword" maxlength="15" ng-change="hideFlashMsg()" ng-pattern="'(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,15}'" id="newpassword" class="form-control" ng-model="user.newpassword" required
                            />
                            <span style="color: #b30000" ng-show="form.newpassword.$dirty && form.newpassword.$invalid" class="help-block" ng-class="has-error">Passwords must be 8-15 characters containing at least 1 uppercase, 1 lowercase, and a special character (!@,#,$,%,&,*) </span>
                        </div> -->
                        <div class="form-group">
                            <label>Confirm Password</label>
                            <input
                                type="password"
                                formControlName="confirmPassword"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"/>
                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors.required">
                                Confirm Password is required
                                </div>
                                <div *ngIf="f.confirmPassword.errors.matching">
                                New Password does not match
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group" ng-class="{ 'has-error': form.cpassword2.$dirty && form.cpassword2.$error.required }">
                            <label for="cpassword2">Confirm Password</label>
                            <input type="password" name="cpassword2" maxlength="15" id="cpassword2" class="form-control" ng-change="hideFlashMsg()" ng-model="user.cpassword2" required />
                            <span style="color: #b30000" ng-show="user.newpassword != user.cpassword2" ng-class="has-error">
                            </span>
                        </div> -->
                        <br>
                        <div id="forgotPasswordBtnsContainer" class="form-group fp-cancel-and-submit-container">
                            <button type="submit" id="forgotPasswordSubmit" class="btn-custom btn" (click)="onForgotSubmit()" data-loading-text="<i style='font-size: 20px;' class='fa fa-spinner fa-spin'></i> Submit">Submit</button>
                            <button type="submit" class="btn-custom btn" (click)="goTologinPage()">Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<app-footer-selectors></app-footer-selectors>