<div>
	<div class="modal-header">
		<h4 class="modal-title modal-title-name" style="width:100%;margin-left: 8px;">{{my_modal_title}}</h4>
		<div>
			<div class="dropdown dropleft export cursor-pointer">
				<svg id="dropdownMenuButton" (mouseover)="onMouseover($event)" (mouseleave)="onMouseout($event)"
					data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="16"
					height="16" fill="currentColor" class="bi bi-three-dots-vertical three_dots dropdown-toggle"
					viewBox="0 0 16 16">
					<path
						d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
				</svg>
				<div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
					<!-- <div style="padding:5px;color: #2273BC;" class="glyphicon glyphicon-new-window"></div> -->
					<div class="export-style" (click)="onExportCsv()"> <i class="fa fa-file"></i> Export</div>
				</div>
			</div>
		</div>
		<button type="button" aria-label="Close" class="btn-close" (click)="closeCustom()">
			<i class="fa fa-times" aria-hidden="true"></i>
		</button>
	</div>
	<div class="modal-body mainpanal" style="margin:0 !important;padding-top: 2px;min-height: 230px;max-height: calc(100vh - 130px);overflow: scroll;" (mouseover)="onMouseover($event)">
		<div id="policyCountSummary">
			<ng-container *ngFor="let option of product_count_arr">
				<div *ngIf="option.product">
					<span style="color:#2c5e91;font-weight: 700;"> {{option.product}}:&nbsp;</span>
					<span style="font-weight: bold;"> {{option.product_count | number}} </span>
				</div>
			</ng-container>
		</div>
		<!-- <div id="policyCountSummaryy" style="display: flex;">
			<div *ngFor="let option of product_count_arr" style="display:flex;margin-left: 10px;font-size: 14px;">
				<div style="color:#2c5e91;font-weight: 700;"> {{option.product}}:&nbsp;</div>
				<div style="font-weight: bold;"> {{option.product_count | number}}  &nbsp; &nbsp;</div><br>
			</div>
		</div> -->
		<div class="panel-body modal-body table-responsive" id="popupContainer" style="padding: 10px;padding-top:0px;">
			<table #mapPopupTable class="table no-wrap" id="mapPopupTable" style="white-space: nowrap;"></table>
		</div>
		<div *ngIf="popupAgentInformationFlag" id="applicationMainContainer">
			<div *ngIf="![23].includes(this.activeSubTabId)" class="my-2">
				<button id="popupBackButton" type="button" class="btn btn-primary px-3 py-1 background-primary3"
					(click)="onBackButtonClick()">Back</button>
				<!-- <button style="margin: 10px 15px 10px 15px;" id="popupBackButton" type="button" class="btn btn-primary green-bgColor white-color" (click)="onAppDetailsExportClick()">Export</button> -->
			</div>
			<div id="appDetailsContainer" class="">
				<div class="accordion" id="accordionExample">
					<div class="accordion-item">

						<h2 class="accordion-header" id="headingOne">
							<button class="accordion-button" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
								Agent Information
							</button>
						</h2>
						<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
							data-bs-parent="#accordionExample">
							<div class="accordion-body p-1">
								<div class="row m-0 po_boxbg" style="background-color: transparent;border: none;">
									<ng-container *ngFor="let item of popupAgentInformation; let i = index">
										<div class="col-4 p-0" *ngIf="i % 3 === 0">
											<div class="agent-info">
												<div class="d-flex">
													<h4 class="agent-info-field-name">{{ item.key }}: </h4>
													<h4 class="h4-word-break">&nbsp;{{ item.value }}</h4>
												</div>
											</div>
										</div>
										<div class="col-4 p-0" *ngIf="i % 3 === 1">
											<div class="agent-info">
												<div class="d-flex">
													<h4 class="agent-info-field-name">{{ item.key }}: </h4>
													<h4 class="h4-word-break">&nbsp;{{ item.value }}</h4>
												</div>
											</div>
										</div>
										<div class="col-4 p-0" *ngIf="i % 3 === 2">
											<div class="agent-info">
												<div class="d-flex">
													<h4 class="agent-info-field-name">{{ item.key }}: </h4>
													<h4 class="h4-word-break">&nbsp;{{ item.value }}</h4>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>

					</div>
					<div class="accordion-item" *ngIf="infoAndUnderwritingInformationFlag">

						<h2 class="accordion-header" id="headingTwo">
							<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
								Application Information
							</button>
						</h2>
						<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
							data-bs-parent="#accordionExample">
							<div class="accordion-body p-1">
								<div class="row m-0 po_boxbg" style="background-color: transparent;border: none;">
									<ng-container *ngFor="let item of infoAndUnderwritingInformation; let i = index">
										<div class="col-4 p-0" *ngIf="i % 3 === 0">
											<div class="agent-info">
												<div class="d-flex">
													<h4 class="agent-info-field-name">{{ item.key }}: </h4>
													<h4 class="h4-word-break">&nbsp;{{ item.value }}</h4>
												</div>
											</div>
										</div>
										<div class="col-4 p-0" *ngIf="i % 3 === 1">
											<div class="agent-info">
												<div class="d-flex">
													<h4 class="agent-info-field-name">{{ item.key }}: </h4>
													<h4 class="h4-word-break">&nbsp;{{ item.value }}</h4>
												</div>
											</div>
										</div>
										<div class="col-4 p-0" *ngIf="i % 3 === 2">
											<div class="agent-info">
												<div class="d-flex">
													<h4 class="agent-info-field-name">{{ item.key }}: </h4>
													<h4 class="h4-word-break">&nbsp;{{ item.value }}</h4>
												</div>
											</div>
										</div>
									</ng-container>
									<!-- <div class="col-4 p-0">
										<div class="agent-info">
											<div class="d-flex">
												<h4 class="agent-info-field-name">App ID: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['App-ID']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Entry: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Entry']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">App Status: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['App Status']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Product: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.Product}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Carrier: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.Carrier}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Face Amount: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Face Amount'] | currency:
													"USD"}}</h4>
											</div>
										</div>
									</div>
									<div class="col-4 p-0">
										<div class="agent-info">
											<div class="d-flex">
												<h4 class="agent-info-field-name">Rider: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.Rider | currency: "USD"}}
												</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Annual Premium: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Annual Premium'] |
													currency: "USD"}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Mode: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.Mode}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Modal Premium: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Modal Premium'] |
													currency: "USD"}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Insured Name: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Insured Name']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Insured Address: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Insured Address']}}</h4>
											</div>
										</div>
									</div>
									<div class="col-4 p-0">
										<div class="agent-info">
											<div class="d-flex">
												<h4 class="agent-info-field-name">City: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.City}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">State: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.State}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Zip Code: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Zip Code']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Page Last Visited: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Page Last Visited']}}
												</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Issue: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Issue']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Effective: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Effective']}}</h4>
											</div>
										</div>
									</div> -->
								</div>
							</div>
						</div>

					</div>
					<!-- <div class="accordion-item" *ngIf="infoAndUnderwritingInformationFlag && showManualUnderWriting">

						<h2 class="accordion-header" id="headingThree">
							<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
								Manual Underwriting Information
							</button>
						</h2>
						<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
							data-bs-parent="#accordionExample">
							<div class="accordion-body p-1">
								<div class="row m-0 po_boxbg" style="background-color: transparent;border: none;">
									 <div class="col-4 p-0">
										<div class="agent-info">
											<div class="d-flex">
												<h4 class="agent-info-field-name">Rate/Risk Class: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Rate/Risk Class']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Case Status: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Case Status']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Completion Date: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Completion Date']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Final UW Decision: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Final UW Decision']}}
												</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Entry: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Underwriting Entry']}}
												</h4>
											</div>
										</div>
									</div>
									<div class="col-4 p-0">
										<div class="agent-info">
											<div class="d-flex">
												<h4 class="agent-info-field-name">Declined Reason: </h4>
												<h4
													[ngStyle]="{'color':getColorClasses(infoAndUnderwritingInformation?.['Declined Reason'])}">
													&nbsp; {{infoAndUnderwritingInformation?.['Declined Reason']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Requirements: </h4>
												<h4 title="{{infoAndUnderwritingInformation?.Requirements}}"
													style="width: 240px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;text-decoration: none;color: #626B78 !important;">
													&nbsp; {{infoAndUnderwritingInformation?.Requirements}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Billing Category: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.['Billing Category']}}</h4>
											</div>
											<div class="d-flex">
												<h4 class="agent-info-field-name">Notes: </h4>
												<h4>&nbsp; {{infoAndUnderwritingInformation?.Notes}}</h4>
											</div>
										</div>
									</div>
									<div class="col-4 p-0">
										<div class="agent-info">
										</div>
									</div> 
								</div>
							</div>
						</div>

					</div>-->
					<div class="accordion-item" *ngIf="policyCaseInformation.length > 0">

						<h2 class="accordion-header" id="headingFour">
							<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
								Requirements/Underwriting Information
							</button>
						</h2>
						<div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
							data-bs-parent="#accordionExample">
							<div class="accordion-body p-0">
								<div class="row m-0 po_boxbg" style="background-color: transparent;border: none;" *ngIf="policyCaseInformation.length > 0">
								<div class="accordion" id="accordionNested">
									<div class="accordion-item" *ngFor="let stage of policyCaseInformation; let i = index">
										<h3 class="accordion-header" id="nestedHeading{{i}}">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
											[attr.data-bs-target]="'#collapse' + i"
											aria-expanded="true"
											[attr.aria-controls]="'collapse' + i">
												{{ stage.processingStageCode }}
											</button>
										</h3>
										<div id="collapse{{i}}" class="accordion-collapse collapse" [class.show]="i === 0"  [attr.aria-labelledby]="'#heading' + i" data-bs-parent="#accordionNested">
      										<div class="accordion-body">
												<div class="row m-0">
													<div class="col-12">
													  <!-- Handle items array -->
													  <ng-container *ngFor="let item of stage.items">
														<div class="row m-0">
														  <div class="col-4 p-0" *ngFor="let key of getKeys(flattenObject(item))">
															<div class="agent-info">
															  <div class="d-flex">
																  <h4 class="agent-info-field-name">{{ key }}:</h4>
																  <h4 class="text-truncate" [attr.data-toggle]="flattenObject(item)[key]?.length > 25 ? 'tooltip' : null" [attr.title]="flattenObject(item)[key]?.length > 25 ? flattenObject(item)[key] : null" style="max-width: 180px;">&nbsp;{{ flattenObject(item)[key] }}</h4>
															  </div>
															</div>
														  </div>
														</div>
														<hr>
													  </ng-container>
													</div>
												  </div>
											</div>
										</div>
										
									</div>
								</div>
								
									<!-- <div *ngFor="let stage of policyCaseInformation">
										<h3>{{ stage.processingStageCode }}</h3>
										
										<div class="row">
										  <div class="col-12 mb-3">
											<ng-container *ngFor="let item of stage.items">
											  <div class="row">
												<div class="col-4 p-0" *ngFor="let key of getKeys(flattenObject(item))">
												  <div class="agent-info">
													<div class="d-flex">
														<h4 class="agent-info-field-name">{{ key }}:</h4>
														<h4 class="h4-word-break">&nbsp;{{ flattenObject(item)[key] }}</h4>
													</div>
												  </div>
												</div>
											  </div>
											  <hr>
											</ng-container>
										  </div>
										</div>
									  </div> -->
									  
									  
									<!-- <ng-container *ngFor="let item of requirementsInformation; let i = index">
										<div class="col-4 p-0">
											<div class="agent-info">
												<div class="d-flex">
													<h4 class="agent-info-field-name">Description: </h4>
													<h4 class="h4-word-break">&nbsp;{{ item.description }}</h4>
												</div>
											</div>
										</div>
										<div class="col-4 p-0">
											<div class="agent-info">
												<div class="d-flex">
													<h4 class="agent-info-field-name">Status Code: </h4>
													<h4 class="h4-word-break">&nbsp;{{ item.statusCode }}</h4>
												</div>
											</div>
										</div>
										<div class="col-4 p-0">
											<div class="agent-info">
												<div class="d-flex">
													<h4 class="agent-info-field-name">Processing Stage Code: </h4>
													<h4 class="h4-word-break">&nbsp;{{ item.processingStageCode }}</h4>
												</div>
											</div>
										</div>
										<hr>
									</ng-container> -->
								</div>
							</div>
						</div>

					</div>
				</div>
				<!-- <div class="row m-0 po_boxbg"> -->
				<!-- <div class="col-12 px-0 py-2 hdr">Agent Information</div>
					<ng-container *ngFor="let item of popupAgentInformation; let i = index">
						<div class="col-4 p-0" *ngIf="i % 3 === 0">
						  <div class="agent-info">
							<div class="d-flex">
							  <h4 class="agent-info-field-name">{{ item.key }}: </h4>
							  <h4>&nbsp;{{ item.value }}</h4>
							</div>
						  </div>
						</div>
						<div class="col-4 p-0" *ngIf="i % 3 === 1">
						  <div class="agent-info">
							<div class="d-flex">
							  <h4 class="agent-info-field-name">{{ item.key }}: </h4>
							  <h4>&nbsp;{{ item.value }}</h4>
							</div>
						  </div>
						</div>
						<div class="col-4 p-0" *ngIf="i % 3 === 2">
						  <div class="agent-info">
							<div class="d-flex">
							  <h4 class="agent-info-field-name">{{ item.key }}: </h4>
							  <h4>&nbsp;{{ item.value }}</h4>
							</div>
						  </div>
						</div>
					  </ng-container> -->
				<!-- <div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">AgentVizion Id: </h4> <h4>&nbsp; {{popupAgentInformation["CRM Id"]}} </h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Name: </h4> <h4>&nbsp; {{popupAgentInformation.name}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">NPN: </h4> <h4>&nbsp; {{popupAgentInformation.NPN}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Companies: </h4> <h4>&nbsp; {{popupAgentInformation.Carriers}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Agency: </h4> <h4>&nbsp; {{popupAgentInformation.AgencyName}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Contact No: </h4> <h4>&nbsp; {{popupAgentInformation.number}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">State: </h4> <h4>&nbsp; {{popupAgentInformation.state}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Email: </h4> <h4>&nbsp; {{popupAgentInformation.address}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Course Name: </h4> <h4>&nbsp; {{popupAgentInformation.course_name}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">License Number: </h4> <h4>&nbsp; {{popupAgentInformation.license_number}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">License Type: </h4> <h4>&nbsp; {{popupAgentInformation.license_type}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">License Status: </h4> <h4>&nbsp; {{popupAgentInformation.license_status}}</h4>
							</div>
						</div>
					</div> -->
				<!--</div>
				 <div *ngIf="infoAndUnderwritingInformationFlag" class="row m-0 po_boxbg">
					<div class="col-12 px-0 py-2 hdr">Application Information</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">App ID: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['App-ID']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Entry: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Entry']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">App Status: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['App Status']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Product: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Product}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Carrier: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Carrier}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Face Amount: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Face Amount'] | currency: "USD"}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Rider: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Rider | currency: "USD"}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Annual Premium: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Annual Premium'] | currency: "USD"}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Mode: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Mode}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Modal Premium: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Modal Premium'] | currency: "USD"}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Insured Name: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Insured Name']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Insured Address: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Insured Address']}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
					<div class="agent-info">
						<div class="d-flex">
							<h4 class="agent-info-field-name">City: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.City}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">State: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.State}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">Zip Code: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Zip Code']}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">Page Last Visited: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Page Last Visited']}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">Issue: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Issue']}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">Effective: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Effective']}}</h4>
						</div>
					</div>
					</div>
				</div>
				<div *ngIf="infoAndUnderwritingInformationFlag" class="row m-0 po_boxbg">
					<div class="col-12 px-0 py-2 hdr">Manual Underwriting Information</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Rate/Risk Class: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Rate/Risk Class']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Case Status: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Case Status']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Completion Date: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Completion Date']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Final UW Decision: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Final UW Decision']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Entry: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Underwriting Entry']}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Declined Reason: </h4> <h4
								[ngStyle]="{'color':getColorClasses(infoAndUnderwritingInformation?.['Declined Reason'])}">&nbsp; {{infoAndUnderwritingInformation?.['Declined Reason']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name" >Requirements: </h4>
								<h4 title="{{infoAndUnderwritingInformation?.Requirements}}" style="width: 240px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;text-decoration: none;color: #626B78 !important;">&nbsp; {{infoAndUnderwritingInformation?.Requirements}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Billing Category: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Billing Category']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Notes: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Notes}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
						</div>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</div>