<flash-messages></flash-messages>
<div class="main subscriptionOuterContainer" ui-view>
    <div class="subscriptionContainer" id="subscriptionFilterContainer">
         <form action="/charge" method="POST">
            <div class="form-group col-md-12">
                <div id="subscriptionBox"><p class="msg">Start using AgentVizion today!</p></div>
            </div>
            <div id="subscribeFormContainer">
                <div class="form-group col-md-12">
                    <label for="subscriptionType">Select your registration type </label>
                    <div id="subscriptionType">
                        <label class="subscriptionTypeLabel"><input id="monthly" class="margin-top-2" type="radio" name="subscriptionType" [(ngModel)]="subscriptionProperties.subscriptionType" value="Monthly" checked="checked" (click)="resetValue()">&nbsp; Monthly</label>
                        <label class="subscriptionTypeLabel"><input id="yearly" class="margin-top-2" type="radio" name="subscriptionType"[(ngModel)]="subscriptionProperties.subscriptionType" value="Yearly" (click)="resetValue()">&nbsp; Yearly</label>
                    </div>
                </div>
                <!-- <div class="form-group col-md-12" style='display:none'>
                    <label for="subscriptionCount">Select number of registrations you need: </label>
                    <input id="subscriptionCount" class="form-control" name="subscriptionCount" [(ngmodel)]="subscriptionProperties.subscriptionCount" (click)="updatePrice($event)" ng-keypress="keyPressHandler($event)">
                </div> -->
                <div id="subscriptionQuantityContainer" class="form-group col-md-12" style='display:none'>
                    <label for="subscriptionCount">Select number of registrations you need </label>
                    <select id="subscriptionCount" class="form-control" name="subscriptionCount" [(ngModel)]="subscriptionProperties.subscriptionCount" (click)="onChangeSubscriptionCount()">
                        <option ng-value="subscriptionProperties.subscriptionCount">{{subscriptionProperties.subscriptionCount}}</option>
                        <!-- <option ng-repeat="quantity in quantityList" ng-value="quantity.quantity">{{quantity.quantity}}</option> -->
                    </select>
                    <!-- <span ng-if="subscriptionProperties.subscriptionCount" class="display-subscription-amount">Total Subscription Amount : {{displaySubscriptionTotalAmount}}</span> -->
                </div>
                <div class="form-group col-md-12" style='display:none'>
                    <label for="applicationType">Select your application type </label>
                    <div id="applicationType">
                        <!-- <label class="applicationTypeLabel"><input id="web" class="margin-top-2" type="radio" name="applicationType" [(ngmodel)]="subscriptionProperties.applicationType" ng-value="'AgentVizion (Web)'" value="web" checked="checked" (click)="resetValue()">&nbsp; AgentVizion (Web)</label>
                        <label class="applicationTypeLabel"><input id="mob" class="margin-top-2" type="radio" name="applicationType" [(ngmodel)]="subscriptionProperties.applicationType" ng-value="'AgentVizion2Go (Mobile App)'" value="mob" (click)="resetValue()" disabled>&nbsp; AgentVizion2Go (Mobile App coming soon...)</label> -->
                        <label class="applicationTypeLabel"><input id="both" class="margin-top-2" type="radio" name="applicationType" [(ngModel)]="subscriptionProperties.applicationType" ng-value="'Both'" value="both" (click)="resetValue()">&nbsp; Both</label>
                    </div>
                </div>
                <div id="couponCodeContainer" class="form-group col-md-12" style="display: none;">
                    <label for="couponCode" class="couponCodeLabel">
                        <span data-title="Coupons are generally issued during an FMO event. If your account registration period or status qualifies you for a coupon, please contact your FMO representative.">
                            <span class="glyphicon glyphicon-info-sign helpTip" ng-mouseover="showCouponMsg()" ng-mouseleave="hideCouponMsg()"></span>
                        </span>
                    Enter coupon code </label>
                    <input id="couponCode" class="form-control" text="radio" name="couponCode" [(ngModel)]="subscriptionProperties.couponCode" ng-value="" value="" autocomplete="off" ng-keypress="keyPressHandler($event)" ng-blur="validateCoupon($event)">
                    <!-- <p class="couponMsg">Coupons are generally issued during an FMO event. If your account registration period or status qualifies you for a coupon, please contact your FMO representative.</p> -->
                    <div class="fix" style="text-align: center;">
                        <!-- <STRONG><flash:messages></flash:messages></STRONG> -->                      
                    </div>
                </div>
                <div class="form-group col-md-12">
                        <div id="confirmSubscriptoin" class="btn btn-primary green-bgColor white-color" (click)="onConfirmation()">Register</div>
                </div>
            </div>
            <div id="subscribeConfirmationContainer">
                <div class="form-group col-md-12">
                    <p><b>Registration type</b> : {{subscriptionProperties.subscriptionType}}</p>
                    <!-- <p><b>Coupon applied</b> : {{subscriptionProperties.couponApplied}}</p> -->
                </div>
                <div class="form-group col-md-12">
                    <button id="backToSubscriptoinForm" class="btn btn-primary green-bgColor white-color mx-1" (click)="backToSubscriptoinForm()">Back</button>
                    <script src="https://js.stripe.com/v3"></script>
                    <button id="checkout-button" (click)="pay()" class="btn btn-primary green-bgColor white-color mx-1">Register</button>
                    <!-- <script
                        src="https://checkout.stripe.com/checkout.js"
                        class="stripe-button"
                        data-key={{dataValue}}
                        data-locale="auto"
                        data-description=""
                        data-name="AgentVizion"
                        data-image="images/AgentVizionSmall.png"
                        data-label="Register"
                        data-email={{email}}
                        data-panel-label="Submit"
                        data-allow-remember-me=false
                        data-currency="usd">
                    </script> -->
                    <br/>
                </div>
            </div>
        </form>
    </div>
    <div class="subscriptionContainer" id="subscriptionFormContainer">
        <span>No refund will be issued upon cancellation. You will continue to have system access for the period you have been billed for.</span>
        <p>If you have any questions or feedback please <a href="mailto:billing@magnifact.com">email us</a>.</p>
    </div>
    <!-- </br></br></br></br> -->

    <!-- <div id="spinner">
        <img src="/images/ajax-loader.gif" class="ajax-loader" />
    </div> -->
</div>
<router-outlet ></router-outlet>
