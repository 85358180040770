// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  // baseURL: 'https://devfmo.agentvizion.com/'
  //  baseURL: 'https://devcarrier.agentvizion.com/'
  // baseURL: 'https://iptiq-staging.agentvizion.com/'
  // baseURL: 'https://fgl.agentvizion.com/'
  // baseURL: 'https://ademo.agentvizion.com/'
  // baseURL: 'https://nhm-staging.agentvizion.com/'
  // baseURL: 'https://wma-staging.agentvizion.com/'
  // baseURL: 'https://pfs-staging.agentvizion.com/'
  // baseURL: 'https://cdemo.agentvizion.com/'
  baseURL: 'api/',
  browserKey: 'mySecretKey'
  // baseURL: 'http://localhost:9001/'
  // baseURL: 'http://172.52.90.45:9000/'
  // baseURL: 'http://172.31.26.253:9000/'
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.