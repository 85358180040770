import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit{
  registrationQuantityFlag : boolean = true;
  quantityList = [
    {'quantity': 10, 'quantityAmount': 1000},
    {'quantity': 25, 'quantityAmount': 1300},
    {'quantity': 50, 'quantityAmount': 1500}];
    email = '';

  subscriptionProperties: any = {
      applicationType: 'Both',
      subscriptionType: 'Monthly',
      subscriptionCount: 1,
      subscriptionGroupCount: '',
      couponCode: '',
      couponApplied: 'None'
  };
  stripeKey: any;
  handler: any;

  constructor( private route: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private authService: AuthService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService) { 
      this.loadStripe();
    }

ngOnInit(): void {
    $('.navbar').show();
    $('.navbar').css({display: 'block'});
    $('#logout').html('Logout');

    this.authService.getClientConfig().subscribe(response => {
      $('#logoImage').attr('src', response[0]?.logo);      
    });
  // var str;

  this.authService.getUserData().subscribe((response:any) => {
    this.email = response.email;
    this.stripeKey = response.stripePublishableKey;
    var role;
    var loggedinrole;
    if (response.role === 'Agent') {
        role = response.role;
        loggedinrole = response.role;
    } else if (response.role !== 'Manager') {
        role = 'Agency';
        loggedinrole = 'Agency';
    }
    $('#loggedUserName').html(response.name + ' ('+loggedinrole+') !'); 

    // const checkoutButton = document.querySelector('#checkout-button');
    // checkoutButton.addEventListener('click', () => {
    //   stripe.redirectToCheckout({
    //     mode: "payment",
    //     lineItems: [{ price: '12', quantity: 1}]
    //     // successUrl: `${window.location.href}/success`,
    //     // cancelUrl: `${window.location.href}/failure`,
    //   });
      // var handler = stripe..configure({
      //   key: 'pk_test_qknfhkgHhKz63MM06NYUuPu100XS5PA85',
      //   locale: 'auto',
      //   token: function (token: any) {
      //     // You can access the token ID with `token.id`.
      //     // Get the token ID to your server-side code for use.
      //     console.log(token)
      //     alert('Token Created!!');
      //   }
      // });
      
      // handler.open({
      //   name: 'Demo Site',
      //   description: '2 widgets',
      //   amount: 100
      // });
    // }); 
 
    // switch($location.$$search.page) {
    //   case 'trialWelcomePage':
    //       str = '<p class="msg">Start your 14-day trial of AgentVizion today!</p><p class="trialMsg">You will be asked to submit your payment details after clicking on \'Register\' button below, however your payment for '+role+' registration will not be charged till after the trial period.</p><p>';
    //       break;
    //   case 'startSubscriptipn':
    //       str = '<p class="msg">Start using AgentVizion today!</p></br>';
    //       break;
    //   }
    // $('#subscriptionBox').html(str);
  });
}

pay() {  
  this.spinner.show();
  var handler = (<any>window).StripeCheckout.configure({
    key: this.stripeKey,
    locale: 'auto',
    token: (token: any) =>{
      this.spinner.show();
      // Get the token ID to your server-side code for use.
      // alert('Token Created!!');
      var stripeToken = token.id;
      if(stripeToken){
        var coupon = this.subscriptionProperties.couponCode? this.subscriptionProperties.couponCode: '';
        let reqObj =  {
          couponCode: coupon,
          applicationType:this.subscriptionProperties.applicationType,
          subscriptionType:this.subscriptionProperties.subscriptionType.toLowerCase(),
          subscriptionCount:this.subscriptionProperties.subscriptionCount,
          stripeEmail: this.email,
          stripeToken: stripeToken,
          stripeTokenType: token.type
      }
        // let formData = new FormData();
        // Object.keys(reqObj).forEach(key=>{
        //   formData.append(key, reqObj[key]);
        // })
        this.authService.stripeCharge(reqObj).subscribe(res=>{
          if(res && res['msg']=='payment recieved') {
            this.commonService.getTabData({}).subscribe(res => {
              this.commonService.tabData = res;             
              let tabName = this.commonService.tabData[0].tab_name.toLowerCase();
              this.authService.acceptEula({ 'param1': this.email, 'param2': '' }).subscribe(res =>{
                      this.router.navigate(['app/'+tabName], { queryParams: { } });
              });
            });
          } else {
            this.flashMessagesService.show(res['message'], { cssClass: 'alert-warning', timeout: 20000, closeOnClick:true, showCloseBtn: true });
          }
        })
      }
    }
  });

  handler.open({
    name: 'AgentVizion',
    // description: '2 widgets',
    // amount: 100
  });

}

loadStripe() {
      
  // if(!window.document.getElementById('stripe-script')) {
  //   var s = window.document.createElement("script");
  //   s.id = "stripe-script";
  //   s.type = "text/javascript";
  //   s.src = "https://js.stripe.com/v3/";
  //   window.document.body.appendChild(s);
  // }    
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_qknfhkgHhKz63MM06NYUuPu100XS5PA85f',
          locale: 'auto',
          token: (token: any) =>{
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            console.log(token)
            alert('Payment Success!!');
          }
        });
      }
        
      window.document.body.appendChild(s);
    }
}

validateCoupon = function (e) {
    var coupon = this.subscriptionProperties.couponCode; //$(e.target).val();
    if (coupon) {
      let reqObj =  {
        coupon: coupon,
        applicationType:this.subscriptionProperties.applicationType,
        subscriptionType:this.subscriptionProperties.subscriptionType.toLowerCase(),
        subscriptionCount:this.subscriptionProperties.subscriptionCount
    }
      this.authService.verifyCoupon(reqObj).subscribe(response=>{
        if (response.length) {
          $('.fix').css({color: 'green'});
          this.showCouponDetails(response[0].base_price, response[0].discount_amount);
         this.subscriptionProperties.couponApplied = coupon;
      } else {
          $('.fix').css({color: 'red'});
          // flash('Invalid coupon code');
          this.flashMessagesService.show('Invalid coupon code', { cssClass: 'alert-danger', timeout: 2500, closeOnClick:true, showCloseBtn: true });
         this.subscriptionProperties.couponApplied = 'None';
      }
      })
    } else {
       this.subscriptionProperties.couponApplied = 'None';
        // flash('');
    }
};

showCouponDetails(price, discount_amount) {
    var amountToPay = Math.round(price - discount_amount);
    var string = '';
    if (this.subscriptionProperties.subscriptionType.toLowerCase() === 'monthly') {
        string = 'You save $'+ discount_amount.toLocaleString('en')+' on your registration. Pay only $'+amountToPay.toLocaleString('en')+'/month for your first 12 months.';
    } else if (this.subscriptionProperties.subscriptionType.toLowerCase() === 'yearly') {
        string = 'You save $'+ discount_amount.toLocaleString('en')+' on your registration. Pay only $'+amountToPay.toLocaleString('en')+' for your first yearly billing cycle.';
    }
    // flash('Coupon applied ('+string+')');
}

showGroupCouponDetails(price, discount_amount) {
    var amountToPay = Math.round(price - discount_amount);
    var string = '';
    if (this.subscriptionProperties.subscriptionType.toLowerCase() === 'monthly') {
        string = 'You save $'+ discount_amount.toLocaleString('en')+' on your registration. Pay only $'+amountToPay.toLocaleString('en')+'/month for your first 12 months.';
    } else if (this.subscriptionProperties.subscriptionType.toLowerCase() === 'yearly') {
        string = 'You save $'+ discount_amount.toLocaleString('en')+' on your registration. Pay only $'+amountToPay.toLocaleString('en')+' for your first yearly billing cycle.';
    }
    // flash(string);
}

updatePrice = function () {
    if (this.subscriptionProperties.couponCode.length) {
       this.validateCoupon();
    }
};

//disable enter key from 'coupon code' in input
keyPressHandler = function (e) {
    if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
    }
};

resetValue = function () {
   this.resetCouponValue();
    $('#couponCode').val('');
};

resetCouponValue = function () {
   this.subscriptionProperties.couponCode = '';
   this.subscriptionProperties.couponApplied = 'None';
    // flash('');
};

onChangeSubscriptionCount = function () {
  let reqObj = {
    subscriptionType:this.subscriptionProperties.subscriptionType.toLowerCase(),
    quantity:this.subscriptionProperties.subscriptionGroupCount
  }
  this.authService.getSubscriptionPriceDetail(reqObj).subscribe(res=>{
    if (res.length) {
      this.registrationQuantityFlag = false;
      this.showGroupCouponDetails(res[0].base_price, res[0].discount_amount);
    }
  })
};

goToSubscriptionForm = function () {
    $('html, body').animate({
        scrollTop: $('.subscriptionOuterContainer').offset().top
    }, 500);
};

onConfirmation = function () {
    $('#subscribeFormContainer').hide();
    $('#subscribeConfirmationContainer').show();
};

backToSubscriptoinForm = function () {
    $('#subscribeConfirmationContainer').hide();
    $('#subscribeFormContainer').show();
};

showCouponMsg = function () {
    $('.couponMsg').show();
};

hideCouponMsg = function () {
    $('.couponMsg').hide();
};
}