    import { AfterViewChecked, Component, OnInit } from '@angular/core';
    import { Routes, RouterModule, Router } from '@angular/router';
    import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
    import $ from 'jquery';
    import { FlashMessagesService } from 'angular2-flash-messages';
    import { AuthService} from '../auth.service';
    import Validations from './../../shared/utils/validations';
    import { HttpErrorResponse } from '@angular/common/http';
    import { LocalstorageService } from 'src/app/shared/services/localstorage.service';

    @Component({
        selector: 'app-forgot-password',
        templateUrl: './forgot-password.component.html',
        styleUrls: ['./forgot-password.component.scss']
    })
    export class ForgotPasswordComponent implements OnInit, AfterViewChecked {
        user: object = {

        }

        fpform: FormGroup;
        submitted = false;
        toggleUser: string = 'yes';
        toggleUserFlag: Boolean = false;
        emailId: string = '';
        routeParams: object = {
            url: '',
            queryParam: ''
        }
        logo: string;
        app_version: number;
        newpassword: string = '';
        confirmPassword: string = '';
        IsForgotPasswordLinkValid: Boolean = false;
        isExpired: boolean = false;
        identifier: string= ''
        jwtToken: string = '';

        constructor(
            private router: Router,
            private flashMessagesService: FlashMessagesService,
            private authService: AuthService,
            private formBuilder: FormBuilder,
            private localStorageService: LocalstorageService,
        ) { }
        
        ngAfterViewChecked(): void {
            this.fpform.patchValue({'email': this.emailId});
        }

        ngOnInit() {
            $('.navbar').hide();
            $('#password').focus();

            this.fpform = this.formBuilder.group(
                {
                    email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
                    npn: ['', [ Validators.required,Validators.minLength(10),Validators.pattern("^[0-9]*$")]],
                    number: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
                    password: ['', [Validators.required,Validators.minLength(8),
                        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
                    confirmPassword: ['', Validators.required],
                    acceptTerms: [false, Validators.requiredTrue]
                },
                {
                    validators: [Validations.match('password', 'confirmPassword')]
                }
            );

            setTimeout(()=>{
                $('#forgotnewPwd input').val('');
            }, 1000)
            this.identifier = window.location.href.split('?a=')[1];
            //Get IsForgotPasswordLinkValid from DB
            this.checkForResetPassFlag(this.identifier);
            this.getClientConfig();
        }

        get f(): { [key: string]: AbstractControl } {
            return this.fpform.controls;
        }

        onForgotSubmit(): void {
            this.submitted = true;

            if (!this.fpform.get('email').errors && !this.fpform.get('password').errors && !this.fpform.get('confirmPassword').errors) {
                this.forgotSubmit();
                //return;
            } else {
               // this.forgotSubmit();
            }
            // console.log(JSON.stringify(this.fpform.value, null, 2));
        }

        onReset(): void {
            this.submitted = false;
            this.fpform.reset();
        }

        flashMessagesFunction(msg: any, alert: any){
            //this.flashMessagesService.show(msg, { cssClass: 'alert-danger', timeout: 1000000, closeOnClick:true, showCloseBtn: true });
            if(alert === 'danger'){
                this.flashMessagesService.show(msg, { cssClass: 'alert-danger', closeOnClick:true, showCloseBtn: true });
            } else {
                this.flashMessagesService.show(msg, { cssClass: 'alert-success', closeOnClick:true, showCloseBtn: true});
            }
        }

        //Get logo from DB
        getClientConfig(){
            this.authService.getClientConfig().subscribe(response => {
                this.logo = response[0][0].logo;
                this.app_version = response[0][0].app_version;
                this.toggleUser = response[0][0].toggle_user;
                if (this.toggleUser === 'yes') {
                    this.toggleUserFlag = true;
                    $('#toggleUserContainer').show();
                    $('#userNameContainer, #npnContainer, #forgetNpnContainer').hide();
                    $('#emailUserNameContainer').show();
                } else {
                    this.toggleUserFlag = false;
                    $('#toggleUserContainer, #forgetNpnContainer, #otpContainer').hide();
                    $('#userNameContainer').show();
                }
            });
        }

        checkForResetPassFlag(identifier) {
            let params = {
                key: identifier
            }
            this.authService.checkForResetPassFlag(params).subscribe(response => {
                console.log(response);
                if(response?.data.length>0 && response?.data[0]?.timestamp_valid == 1){
                    this.emailId = response.data[0].email;
                    this.fpform.patchValue({'email': response.data[0].email});
                    this.jwtToken = response.token;
                } else {
                    this.isExpired = true;
                }
            },(err: HttpErrorResponse)=>{
                this.isExpired = true;
            });
        };

        redirectToLoginPage() {
            const routerState = this.router.routerState.snapshot;
            this.router.navigate([this.routeParams['url']], { queryParams: { } });
        }

        //route to login page
        goTologinPage = function() {
            this.routeParams = {
                url: "/login",
                queryParam: ''
            }
            this.redirectToLoginPage()
        };

        forgotSubmit() {
            this.emailId = this.fpform.get('email')?.value;
            this.newpassword = this.fpform.get('password')?.value;
            this.confirmPassword = this.fpform.get('confirmPassword')?.value;
            if (this.emailId.length && this.newpassword.length && this.confirmPassword.length) {
                if (this.newpassword === this.confirmPassword) {
                    //param1 email id removed
                    this.authService.forgotPasswordChange({ 'param1': '', 'param2': this.newpassword, 'forgot_password_key': this.identifier, authToken: this.jwtToken }).subscribe((response:any) => {
                        if (response === '0') {
                            this.flashMessagesFunction('Username or Password not found.', 'danger');
                        } else {
                            // this.delete_cookie('agentvizion-' + this.emailId.toString().toLowerCase(), this.emailId);
                            this.flashMessagesFunction('Password successfully reset. Please log in using new password.', 'success');
                            $('#forgotPasswordBtnsContainer').addClass('btns-inactive');
                            //$('#forgotPasswordSubmit').button('loading');
                            setTimeout(() => {
                                this.localStorageService.remove('_rt');
                                this.localStorageService.remove("_t");
                                this.localStorageService.remove("userTabInfo");
                                this.localStorageService.remove("userInfo");
                                this.localStorageService.remove("activeTabId");
                                this.localStorageService.remove("activeSubTab");
                                this.router.navigate(['/login'], { queryParams: { } });
                                $('#forgotPasswordBtnsContainer').removeClass('btns-inactive').hide();
                                //$('#forgotPasswordSubmit').button('reset');
                            }, 5000);
                        }
                    });
                } else {
                    var msg = 'New Password does not match';
                    this.flashMessagesFunction(msg, 'danger');
                }
            } else {
                this.flashMessagesFunction('Please fill the mandatory fields', 'danger');
            }
        };

        delete_cookie(name, email) {
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;secure;';
            this.authService.setCookieOtp({ 'userName': email, 'otp': '' }).subscribe(response => {})
        }

        // hideFlashMsg(){
        //     this.newpassword = this.fpform.get('password')?.value;
        //     this.confirmPassword = this.fpform.get('confirmPassword')?.value;
        //     if (this.newpassword === this.confirmPassword) {
        //         $('.fix').hide();
        //     } else {
        //         $('.fix').show();
        //     }
        // };

    }
