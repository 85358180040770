import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  convertNumberToCommaSeparated(number, checkFormatFlag) {
    if (checkFormatFlag) {
        if ($('#toggleReport button.toggleActive').attr('type') === '$') {
            return '$' + number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        } else {
            return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        }
    } else {
        return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
};
}
