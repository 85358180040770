import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  // filters
  private show_lob: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_lob$: Observable<any> = this.show_lob.asObservable();

  private show_agency: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_agency$: Observable<any> = this.show_agency.asObservable();

  private show_carrier: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_carrier$: Observable<any> = this.show_carrier.asObservable();

  private show_product_type: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_product_type$: Observable<any> = this.show_product_type.asObservable();

  private show_from_date: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_from_date$: Observable<any> = this.show_from_date.asObservable();

  private show_to_date: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_to_date$: Observable<any> = this.show_to_date.asObservable();

  private show_filter_by: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_filter_by$: Observable<any> = this.show_filter_by.asObservable();

  private show_application_status: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_application_status$: Observable<any> = this.show_application_status.asObservable();

  private show_policy_status: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_policy_status$: Observable<any> = this.show_policy_status.asObservable();

  private show_company: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_company$: Observable<any> = this.show_company.asObservable();

  private show_for_year: BehaviorSubject<any> = new BehaviorSubject<any>({show: true, className:''});
  show_for_year$: Observable<any> = this.show_for_year.asObservable();

  constructor() { }

  setShowLob(value: FilterProperties) {
    this.show_lob.next(value);
  }
  setShowAgency(value: FilterProperties) {
    this.show_agency.next(value);
  }
  setShowCarrier(value: FilterProperties) {
    this.show_carrier.next(value);
  }
  setShowProductType(value: FilterProperties) {
    this.show_product_type.next(value);
  }
  setShowFromDate(value: FilterProperties) {
    this.show_from_date.next(value);
  }
  setShowToDate(value: FilterProperties) {
    this.show_to_date.next(value);
  }
  setShowFilterBy(value: FilterProperties) {
    this.show_filter_by.next(value);
  }
  setShowApplicationStatus(value: FilterProperties) {
    this.show_application_status.next(value);
  }
  setShowPolicyStatus(value: FilterProperties) {
    this.show_policy_status.next(value);
  }
  setShowCompany(value: FilterProperties) {
    this.show_company.next(value);
  }
  setShowForYear(value: FilterProperties) {
    this.show_for_year.next(value);
  }
}

interface FilterProperties {
  className: string,
  show: boolean
}
