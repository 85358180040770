export class ApiConstant {

    public static getTabData = 'getTabDetails';
    public static getMarketerCompanies = 'getMarketerCompanies';
    public static getLineOfBusiness = 'getLineOfBusiness';
    public static getProducts = 'getProducts';
    public static getPolicyStatus = 'getPolicyStatus';
    public static getcarriers = 'getcarriers';
    public static getAgency = 'getAgency';
    public static getRssNews = 'getRssNews';
    public static getFlashmessages = 'getFlashMessages';
    public static getInfolables = 'infolabels';
    public static getApplicationStatus = 'getApplicationStatus';
    public static getSaleTypeList = 'getSaleTypeList';

    public static filterDate = 'Filter Date';

    public static checkLastUpdatedDate = 'checkLastUpdatedDate';
    public static checkAgentsLastUpdatedDate = 'checkAgentsLastUpdatedDate';
    public static updateUserActivity = 'updateUserActivity';
    public static saveSassionData = 'saveSassionData';
    public static getSavedSession = 'getSavedSession';
    public static getAVURLs = 'getAVURLs';
    public static saveLastAppType = 'saveLastAppType';
    public static getLastAppType = 'getLastAppType';
    public static syncDevice = 'syncDevice';
    public static getUserOptList = 'getUserOptList';
    public static getUserPreferenceList = 'getUserPreferenceList';
    public static getScreenApplicableNotificationCategories = 'getScreenApplicableNotificationCategories';
    public static updateUserOptNotification = 'updateUserOptNotification';
    public static updateUserPreferenceList = 'updateUserPreferenceList';
    public static getNotificationsUnreadCount = 'getNotificationsUnreadCount';
    public static updateAllNotificationsReadStatus = 'updateAllNotificationsReadStatus';
    public static getNotificationsList = 'getNotificationsList';
    public static updateClearNotificationFlag = 'updateClearNotificationFlag';
    public static updateReadUnreadStatus = 'updateReadUnreadStatus';
    public static removeNotificationCount = 'removeNotificationCount';

    //agency
    public static getStatePoliciesData = 'getStatePoliciesData';
    public static getStateSaleData = 'getStateSaleData';
    public static getViewsInfolabels = 'getViewsInfolabels';

    //analytics
    public static getHighPerformers = 'getHighPerformers';
    public static getHighPerformersTap = 'getHighPerformersTap';
    public static getLowPerformers = 'getLowPerformers';
    public static getLowPerformersTap = 'getLowPerformersTap';
    public static getPendingByAgent = 'getPendingByAgent';
    public static getPendingByAgentTap = 'getPendingByAgentTap';
    public static getAgentLapsedPolicies = 'getAgentLapsedPolicies';
    public static getAgentLapsedPoliciesTap = 'getAgentLapsedPoliciesTap';
    public static getLapsedPolicies = 'getLapsedPolicies';
    public static getLapsedPoliciesTap = 'getLapsedPoliciesTap';
    public static getAgencyYearOnYearComparision = 'getAgencyYearOnYearComparision';
    public static getAgencyAnalyticsReport = 'getAgencyAnalyticsReport';
    public static getProductsComparision = 'getProductsComparision';
    public static getPopupBusinessByProductLineTap = 'getPopupBusinessByProductLineTap';
    public static getPopupBusinessByCarriersTap = 'getPopupBusinessByCarriersTap';
    public static getPopupAgentSalesTap = 'getPopupAgentSalesTap';
    public static getYearOnYearComparisionTap = 'getYearOnYearComparisionTap';
    public static getExpiringPolicies = 'getExpiringPolicies';
    public static getTerminatedPolicies = 'getTerminatedPolicies';

    //reports
    public static getAgencyYearlyReport = 'getAgencyYearlyReport';
    public static getManagerQuarterlyIncentiveReport = 'getManagerQuarterlyIncentiveReport';
    public static getProductionAllowanceIncentiveReport = 'getProductionAllowanceIncentiveReport';
    public static getASMTarget = 'getASMTarget';
    public static getAgentWeeklyFastStartBonus = 'getAgentWeeklyFastStartBonus';
    public static getAgentYearlyReport = 'getAgentYearlyReport';
    public static getMapDataReport = 'getMapDataReport';
    public static getAgentHighestMonthReport = 'getAgentHighestMonthReport';
    public static getNewAgentsReport = 'getNewAgentsReport';
    public static getAgentsIncDecReport = 'getAgentsIncDecReport';
    public static getReportDashboard = 'getReportDashboard';
    public static getSubmittedWeeklyReport = 'getSubmittedWeeklyReport';
    public static getSubmittedMonthlyReport = 'getSubmittedMonthlyReport';
    public static getAgentTapDownlineReport = 'getAgentTapDownlineReport';
    public static downloadTapDownlineAgentHierarchy = 'downloadTapDownlineAgentHierarchy';
    public static getIncentivePrograms = 'getIncentivePrograms';
    public static getIncentiveProgramAgents = 'getIncentiveProgramAgents';
    public static getAgentIncentivePoints = 'getAgentIncentivePoints';
    public static getPointsDistribution = 'getPointsDistribution';
    public static getStateSaleDataReport = 'getStateSaleDataReport';
    public static getStatePoliciesDataReport = 'getStatePoliciesDataReport';
    public static getAgencyYearlyReportSales = 'getAgencyYearlyReportSales';
    public static getAgentYearlyReportSales = 'getAgentYearlyReportSales';
    public static getBookOfBusiness = 'getBookOfBusiness';
    public static getFastStartBonusIncentiveReport = 'getFastStartBonusIncentiveReport';
    public static getFastStartBonusForTrainer = 'getFastStartBonusForTrainer';
    public static getAgentsForTrainer = 'getAgentsForTrainer';
    public static getReferralBonusIncentiveReport = 'getReferralBonusIncentiveReport';
    public static getReferredAgents = 'getReferredAgents';
    public static getAgentBonusIncentiveReport = 'getAgentBonusIncentiveReport';
    public static getConventionIncentiveReportAris  = 'getConventionIncentiveReportAris';
    public static getConventionIncentiveReportAmericare  = 'getConventionIncentiveReportAmericare';
    public static getRsmBonusIncentiveReport  = 'getRsmBonusIncentiveReport';
    public static getAgentRecruitmentReport  = 'getAgentRecruitmentReport ';
    public static getAgentRecruitmentInfolabels  = 'getAgentRecruitmentInfolabels '; 
    public static getManagerSipIncentiveReport  = 'getManagerSipIncentiveReport';
    public static getQuarterlyProductMixBonus  = 'getQuarterlyProductMixBonus';
    public static getSipIncentiveReport  = 'getSipIncentiveReport';  
    public static getPlaquesIncentiveReport  = 'getPlaquesIncentiveReport';  
    public static getWeeklyAwardIncentiveReport  = 'getWeeklyAwardIncentiveReport';  
    public static getWeeklyAwardYear  = 'getWeeklyAwardYear';
    public static getWeeklyPremiumByAgent  = 'getWeeklyPremiumByAgent';
    public static getRepLast4WeekProduction  = 'getRepLast4WeekProduction';
    public static getMonthlyAwardYearList  = 'getMonthlyAwardYearList';
    public static getMonthlyAwardsNameList  = 'getMonthlyAwardsNameList';
    public static getMonthlyAwardIncentiveReport  = 'getMonthlyAwardIncentiveReport';
    public static getWeekListByYear  = 'getWeekListByYear';
    public static getBiWeeklyDateRange  = 'getBiWeeklyDateRange';
    public static getConventionPointsDetailExport  = 'getConventionPointsDetailExport';
    public static getBrokerageBucks = 'getBrokerageBucks';
    public static getAgentPersistencyTrackerReport = 'getAgentPersistencyTrackerReport';
    public static getDebtBalanceReport = 'getDebtBalanceReport';
    public static getDebtMonthlyBarChartReport= 'getDebtMonthlyBarChartReport';
    public static getDebtMonthlyAgentReport= 'getDebtMonthlyAgentReport';
    public static getDebtYear= 'getDebtYear';
    public static getDebtBalanceSummaryPieReport = 'getDebtBalanceSummaryPieReport';
    public static getASMAgentYearEndBonusIncentiveReport = 'getASMAgentYearEndBonusIncentiveReport';
    public static getManagerWeeklyProductionIncentiveReport = 'getManagerWeeklyProductionIncentiveReport';
    public static getAgentvizionDownloadList = 'getAgentvizionDownloadList';
    public static getNewManagerQuarterlyIncentiveReport  = 'getNewManagerQuarterlyIncentiveReport';

    //alert tab default values
    public static alertRadioBtnValue = 'Past Alerts';
    public static alertDropDownValue = 'Past Quarter';
    public static selectedInfolabalValue = 'Lapsed Policies';
    public static getPolicyAlertsInfolabels = 'getPolicyAlertsInfolabels';
    public static getApplicationAlertsInfolabels = 'getApplicationAlertsInfolabels';
    public static getPolicyAlerts = 'getPolicyAlerts';

    //agent tab
    public static getAgentDetails = 'getAgentDetails';
    public static getAgentDetailsTap = 'getAgentDetailsTap';
    public static getAgentSummary = 'getAgentSummary';
    public static getAgentSummaryTap = 'getAgentSummaryTap';
    public static getCommissionData = 'getCommissionData';
    public static getAgentSummaryDetails = 'getAgentSummaryDetails';
    public static getAgentSummaryDetailsTap = 'getAgentSummaryDetailsTap';
    public static getAgentSummaryPersonalDetails = 'getAgentSummaryPersonalDetails';
    public static getAgentContactDetails = 'getAgentContactDetails';
    public static getAgentApplicationDetails = 'getAgentApplicationDetails';
    public static getAgentSummaryPersonalDetailsRollupDrill = 'getAgentSummaryPersonalDetailsRollupDrill';
    public static getAgentApplicationSummaryRollUpDrill = 'getAgentApplicationSummaryRollUpDrill';
    public static getAgentTapDownlineReportSales = 'getAgentTapDownlineReportSales';
    public static getProductsByAgent = 'getProductsByAgent';
    public static getInfoAndUnderwritingInformation = 'getApplicationDetails';
    public static getApplicationDetails = 'getApplicationDetails';
    public static getAgentInformation = 'getAgentPersonalDetails';
    public static getApplicationProductCountInfo = 'getApplicationProductCountInfo';
    public static getApplications = 'getApplications';
    public static getApplicationsExport = 'getApplicationsExport'
    public static getAgentApplicationSummaryRollUp = 'getAgentApplicationSummaryRollUp';
    public static getAgentSummaryPersonalDetailsRollup = 'getAgentSummaryPersonalDetailsRollup';
    public static agentNpnDownlineHierarchyDownload = 'agentNpnDownlineHierarchyDownload';
    public static getAgentPaymentDetails = 'getAgentPaymentDetails';
    public static getPaymentStatus = 'getPaymentStatus';
    public static getPaymentPolicies = 'getPaymentPolicies';
    public static getAgentPaymentSummary = 'getAgentPaymentSummary';
    public static getAgentPaymentSummaryDetails = 'getAgentPaymentSummaryDetails';
    public static getPaymentsExport  = 'getPaymentsExport';
    public static getPoliciesExport = 'getPoliciesExport';
    public static getDebtReportPopup = 'getDebtReportPopup';
    public static getRTSDetails = 'getRTSDetails';
    public static getRTScarriers = 'getRTScarriers';
    public static getApplicationRequirementDetails = 'getApplicationRequirementDetails';

    //popup api's
    public static agentPersonalDetailsApi = 'getAgentPersonalDetails';
    public static businessByProductLineApi = 'getBusinessByProductLine';
    public static businessByCarriersApi = 'getBusinessByCarriers';
    public static agentSalesApi = 'getAgentSales';
    public static getAgentApplicationSales = 'getAgentApplicationSales';
    public static getPolicies = 'getPolicies';
    public static getPendingPolicies = 'getPendingPolicies';
    public static getPendingPoliciesForMissingAgent = 'getPendingPoliciesForMissingAgent';
    public static getTapPolicies = 'getTapPolicies';
    public static getLifeAndAnnuityPoliciesTap = 'getLifeAndAnnuityPoliciesTap';
    public static getAgentPersonalDetailsTap = 'getAgentPersonalDetailsTap';
    public static getBusinessByProductLineTap = 'getBusinessByProductLineTap';
    public static getBusinessByCarriersTap = 'getBusinessByCarriersTap';
    public static getAgentSalesTap = 'getAgentSalesTap';
    public static getPoliciesForMissingAgent = 'getPoliciesForMissingAgent';
    public static getCommissionStatements = 'getCommissionStatements';
    public static getYearlyPolicies = 'getYearlyPolicies';
    public static getAgentReportPolicies = 'getAgentReportPolicies';
    public static getLifeAndAnnuityPolicies = 'getLifeAndAnnuityPolicies';
    public static getYearlyPoliciesTAP = 'getYearlyPoliciesTAP';
    public static getAgentPersonalDetails = 'getAgentPersonalDetails';
    public static getPopupAgentApplicationSales = 'getPopupAgentApplicationSales';
    public static getPopupAgentApplicationSalesTap = 'getPopupAgentApplicationSalesTap';

    //missing agents
    public static getMissingAgentPersonalDetails = 'getMissingAgentPersonalDetails';
    public static getBusinessByProductLineForMissingAgents = 'getBusinessByProductLineForMissingAgents';
    public static getBusinessByCarriersForMissingAgents = 'getBusinessByCarriersForMissingAgents';
    public static getAgentSalesForMissingAgents = 'getAgentSalesForMissingAgents';

    //popup product count api
    public static getProductCountInfo = 'getProductCountInfo';
    public static getPendingProductCountInfo = 'getPendingProductCountInfo';
    public static getLapsedProductCountInfo = 'getLapsedProductCountInfo';
    public static getTAPProductCountInfo = 'getTAPProductCountInfo';
    public static getCommissionProductCountInfo = 'getCommissionProductCountInfo';

    //getMissingAgent
    public static getMissingAgentDetails = 'getMissingAgentDetails';
    public static getMissingAgentsData = 'getMissingAgentsData';

    //support tab
    public static getFAQs = 'getFAQs';
    public static getCountryCodes = 'getCountryCodes';
    public static getSupportIssuesTypeList = 'getSupportIssuesTypeList';
    public static updateFeedbackRequest = 'updateFeedbackRequest';

    //sap/tap params
    public static agentsTabTapValue: string = 'agentsTabTapValue';
    public static analyticsTabTapValue: string = 'analyticsTabTapValue';
    public static reportsTabTapValue: string = 'reportsTabTapValue';
    public static mapDataTapValue: string = 'mapDataTapValue';
    public static byCarrierTapValue: string = 'byCarrierTapValue';
    public static byProductTapValue: string = 'byProductTapValue';
    public static stackedPlacedToggleVal: string = 'stackedPlacedToggleVal';
    public static multiBarPlacedToggleVal: string = 'multiBarPlacedToggleVal';
    public static mapPlacedToggleVal: string = 'mapPlacedToggleVal';
}
