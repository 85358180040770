import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreRoutingModule } from '../core/core-routing.module';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { StripeRoutingModule } from './stripe-routing.module';

@NgModule({
  declarations: [
    SubscribeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlashMessagesModule.forRoot(),
    NgxSpinnerModule,
    CoreRoutingModule,
    StripeRoutingModule
  ]
})
export class StripeModule { }
