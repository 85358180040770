import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardConfigService {

  private static _dashboard_config;
  private _intervalCheck;

  public async getDashboardConfig() {
    if (!DashboardConfigService._dashboard_config || Object.keys(DashboardConfigService._dashboard_config).length==0) {
      await this._getDashboardConfig();
      return DashboardConfigService._dashboard_config;
    } else return DashboardConfigService._dashboard_config;
  }

  constructor(
    private _http: HttpClient,
  ) {
    this._checkConfig();
  }

  private _checkConfig(){
      configJson = DashboardConfigService._dashboard_config;
  }

  private async _getDashboardConfig() {
    let res = await new Promise((resolve, reject) => {
      this._http.get('/dashboard-config/'+new Date().toISOString()).subscribe(response => {
        DashboardConfigService._dashboard_config = response;
        configJson = response;
        resolve(response);
      }, (error: HttpErrorResponse) => {
        resolve({});
      })
    })
    return res;
  }
}

export var configJson: { [key: string]: any };
