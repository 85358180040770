
<!-- <link rel="stylesheet" type="text/css" href="../../../app/scss/custom/theme1/new_style.css" /> -->
<app-header></app-header>
<div style="background: #F3F4F8">
    <app-alert-ticker *ngIf="activeRoute.includes('agency')"></app-alert-ticker>
    <app-selectors></app-selectors>    
    <div *ngIf="isSelectorLoaded"> 
        <router-outlet ></router-outlet>
    </div>
    <!-- All children will be inserted here -->
</div>
<app-footer></app-footer>