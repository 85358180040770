import { Injectable } from '@angular/core';
import { ModalComponent} from './modal.component';
// import { from, Observable } from 'rxjs';


@Injectable()
export class ModalService {

  // registeredDialog: ModalComponent;

  // register(dialog: ModalComponent) {
  //   this.registeredDialog = dialog;
  // }

  // open() {
  //       const modalRef = this.modalService.open(ModalComponent, {size: 'xl', backdrop: 'static', ariaLabelledBy: 'modal-basic-title'});
  //       modalRef.componentInstance.my_modal_title = 'I your title';
  //       modalRef.componentInstance.my_modal_content = this.sanitizer.bypassSecurityTrustHtml(
  //           ' <div><b>Last updated: May 17, 2019</b>'+
  //           '<b>Last updated: May 17, 2019</b></div>'
  //           )
  //   }

  // show() {
  //   return new Promise<void>((resolve, reject) => {

  //     this.registeredDialog.show();
  //     this.registeredDialog.onOk.subscribe(() => {
  //       this.registeredDialog.hide();
  //       resolve();
  //     });
  //     this.registeredDialog.onCancel.subscribe(() => {
  //       this.registeredDialog.hide();
  //       reject();
  //     });

  //   });
  // }

}