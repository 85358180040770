<div class="notifications">
	<div *ngFor="let notification of notifications" class="notification" [ngClass]="className(notification)">
		<ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
	</div>
</div>

<ng-template #notificationTpl let-notification="notification">
    <div class="title" fxLayout="row" fxLayoutAlign="space-between center">
		<div class="notificationTitle">{{notification.title}}</div>
        <button class="btn-close" type="button" (click)="close(notification)">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="message">
        {{notification.message}}
    </div>
</ng-template>