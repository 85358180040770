import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from '../../services/common.service';
import { LocalstorageService } from '../../services/localstorage.service';
import { HelperService } from '../../services/helper.service';
import { ExportService } from '../../services/export.service';

@Component({
  selector: 'app-weekly-fast-start-bonus-modal',
  templateUrl: './weekly-fast-start-bonus-modal.component.html',
  styleUrls: ['./weekly-fast-start-bonus-modal.component.scss'],
})
export class WeeklyFastStartBonusModalComponent implements OnInit {
  @Input() weekly_fast_start_bonus_modal_object: string;
  activeTabId: any;
  title: string;
  agentId: string;
  weekNo: string;
  weekData: any;
  tableHeader: string[];
  currencyColumns = ['Target', 'Premium', 'Reward'];
  params: {[key: string]: any};

  constructor(
    public activeModal: NgbActiveModal,
    private commonService: CommonService,
    private localStorageService: LocalstorageService,
    public _helperService: HelperService,
    private exportService: ExportService
  ) { }

  ngOnInit(): void {
    this.activeTabId = this.localStorageService.get('activeTabId');
    this.title = this.weekly_fast_start_bonus_modal_object['title'];
    this.agentId = this.weekly_fast_start_bonus_modal_object['agentid'];
    this.weekNo = this.weekly_fast_start_bonus_modal_object['weekNo'];
    console.log(this.weekly_fast_start_bonus_modal_object, "this week no");
    // this.title = this.title+' for '+this.agentId;
    this.callWeeklyFastStartBonusAPI();
  }

  callWeeklyFastStartBonusAPI(): void {
    this.params = {
      param5: this.agentId,
      tabId: this.activeTabId,
      param3: this.weekly_fast_start_bonus_modal_object['param3'],
      param4: this.weekly_fast_start_bonus_modal_object['param4'],
      program: this.weekly_fast_start_bonus_modal_object['incentive_modal_object']?.name
    };

    this.commonService
      .getAgentWeeklyFastStartBonus(this.params)
      .subscribe((res) => {
        // set response here
        // console.log(res);
        if (res[0]) {
          this.tableHeader = Object.keys(res[0])
        }
        this.weekData = res;
      });
  }

  formateCurrency(amount) {
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(parseFloat(amount.toString().replaceAll("$", "").replaceAll(",", "")));
  }

  onExportCsv = () => {
    let fileName = this.title +' for '+ this.agentId;
    this.exportService.callExportToCSV({apiName: 'getAgentWeeklyFastStartBonus'}, true, fileName, this.params);
  }
}
