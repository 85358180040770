import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './../../../app/shared/services/common.service';
import { ApiConstant } from './../../../app/shared/constants/api-constant';
import { AuthService } from './../../../app/auth/auth.service';
import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { LocalstorageService } from '../../shared/services/localstorage.service';
import { configJson } from '../../shared/services/dashboard-config.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as convert from 'xml-js'
import { DataLoadStatusComponent } from 'src/app/data-load-status/data-load-status.component';
import { iAVPaginationConfig } from 'av-table/lib/av-table-data.model';
import { DatePipe } from '@angular/common';
import { Subscription, interval } from 'rxjs';
import { startWith, switchMap, takeWhile } from 'rxjs/operators';

declare var $: any;
declare function update_child(key:string, id:string): void;
// interface ICache { [ key: string ]: BehaviorSubject<any>; }

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
    subscriptionDetail: {
        [key: string]: any;
    } = {};
    SSOLogin = configJson.ssoLogin;
    localStorageChanges$ = this.localStorageService.changes$;
    routeParams: object = {
        url: '',
        queryParam: ''
    }
    userInfo: object;
    userRole: string = '';
    tabsData: any = [];
    myFeedsList: Array<object> = [];
    socialMediaFeedUrls: Array<any> = [];
    listOfApisArr = [];
    selectedTab: string;
    tabsData1: object;
    routeLinkData: object = { 1: 'app/agency', 2: 'app/agents', 3: 'app/analytics', 4: 'app/alerts', 5: 'app/reports', 6: 'app/missingagents', 7: 'app/support' }
    loginUserRole: string;
    logo: any;
    clientType: string = '';
    isHeaderLoaded: boolean = false;
    showCardsPopup: boolean = false;
    registrationDetails: any = [];
    avLiteUrl = '';
    session_id;
    notificationOpts;
    isSwitchOn: boolean = true;
    notificationControl;
    showNotificationControl: boolean = false;
    showNotificationBell: boolean = false;
    emailArray: [];
    notificationArray: [];
    disableEmailCategoryText: string = 'Email notifications have been disabled.'
    disableWebCategoryText: string = 'Web notifications have been disabled.'
    showMoreOptions;
    NotificationsUnreadCount;
    notificationContentData: [];
    reqParams: any = {
        pageNo: 0,
        offset: 100
    }
    paginationConfig: iAVPaginationConfig = {
        currentRecordStart: 1, currentRecordEnd: 100, offset: 100, totalRecords: 100
    };
    successMsgFlag: boolean = false;
    successMsg: string = 'Updated Successfully!!';
    private pollingSubscription: Subscription;
    mediumArray = [
        'Email',
        'Web',
        'Mob',
        'SMS'
    ];
    NotificationLabels;
    userNotificationControls = [];
    screenNotificationCategories = [];
    user_medium_selection;
    user_selection;
    disabledClientTooltipText = 'Disabled by Client. Please contact Admin.';
    categoryTooltipText = 'Category is not appliable for this medium';
    magnifactNotificationControls = [];
    showStripePages = false;

    constructor(
        private router: Router,
        config: NgbModalConfig,
        private modalService: NgbModal,
        private commonService: CommonService,
        private localStorageService: LocalstorageService,
        private http: HttpClient,
        private spinner: NgxSpinnerService,
        private authService: AuthService,
        private flashMessagesService: FlashMessagesService,
        private route: ActivatedRoute,
        private datePipe: DatePipe
    ) {
        config.backdrop = 'static';
        config.keyboard = false;
        this.router.events
            .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
            .subscribe(event => {
                if (
                    event.id === 1 &&
                    event.url === event.urlAfterRedirects
                ) {
                    // here your code when page is refresh
                    // console.log('else refresh..........')
                    this.getClientConfig();
                }
            })
    }

    ngOnInit() {
        let headerInterval = setInterval(async () => {
            let userTabData = localStorage.getItem('userTabInfo');
            let userInfo = localStorage.getItem('userInfo');
            if (userTabData && userInfo) {
                clearInterval(headerInterval);
                this.isHeaderLoaded = true;
                this.session_id = this.route.snapshot.queryParamMap.get('session_id');
                await this.getAVUrls();
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.userRole = this.userInfo['role'];
                this.tabsData = JSON.parse(localStorage.getItem('userTabInfo'));
                if (this.userRole === 'Agent') {
                    this.tabsData.forEach(function (tab) {
                        switch (tab['tab_name']) {
                            case 'Agency':
                                tab.tab_name = 'My Business';
                                break;
                            case 'Carrier':
                                tab.tab_name = 'My Business';
                                break;
                            case 'Agents':
                                tab.tab_name = 'My Details';
                                break;
                            case 'Analytics':
                                tab.tab_name = 'My Analytics';
                                break;
                            case 'Alerts':
                                tab.tab_name = 'My Alerts';
                                break;
                            case 'Reports':
                                tab.tab_name = 'My Reports';
                                break;
                            case 'Registration':
                                tab.tab_name = 'My Registration';
                                break;
                        }
                    })
                }
                this.selectedTab = this.tabsData[0]['tab_id'];
                this.loginUserRole = '';
                this.clientType = configJson.clientType;
                this.commonService.setParameters('clientType', this.clientType);
                // this.socialMediaFeedUrls = [{"source_type":"rssfeed", "news_feed_URL":"https://integritymarketing.com/feed"},{"source_type":"facebook", "news_feed_URL":"https://www.juicer.io/api/feeds/magnifactsocialmediafeeds?per=10&filter=613212"},{"source_type":"youtube", "news_feed_URL":"https://www.juicer.io/api/feeds/magnifactsocialmediafeeds?per=30&filter=631425,631424,629253"},{"source_type":"Twitter", "news_feed_URL":"https://www.juicer.io/api/feeds/magnifactsocialmediafeeds?per=20&filter=613213,629252"}]
                this.getNewsFeedUrls();
                if (this.userRole !== 'Manager' && this.userRole !== 'Agent') {
                    this.loginUserRole = 'Agency';
                } else {
                    this.loginUserRole = this.userRole;
                }
                // setTimeout(()=>{
                    this.logo = this.commonService.getLogoPath();
                    this.showStripePages = this.commonService.getStripePages() == 1 ? true : false;
                    this.notificationControl = this.commonService.getNotificationControl();
                    this.showNotificationControl = this.getEnableAccessForUser();
                    this.showNotificationBell = this.getEnableAccessForBell();
                // }, 1000);
                setTimeout(() => {
                    let activeTabId = this.localStorageService.get('activeTabId');
                    $('#pageTabs li').removeClass('active');
                    $('#pageTabs #' + activeTabId + '.nav-item').addClass('active');
                })

                $(document).ready(function () {
                    $('.dropdown-toggle').dropdown();
                });
                this.showMoreOptions = configJson['moreOptions'];
                this.NotificationLabels = configJson['NotificationLabels'];
                this.pollingSubscription = interval(60000) // Poll every 60 seconds, change as needed
                    .pipe(
                        startWith(0),
                        switchMap(() => this.commonService.getNotificationsUnreadCount()),
                        takeWhile(() => this.showNotificationBell)
                    )
                    .subscribe((res: any) => {
                        this.NotificationsUnreadCount = res[0]?.notification_count;
                    });
            }
            this.commonService.resetSelectorPosition();
        }, 0)
    }

    onMouseover = (e) => {
        $('[data-toggle="tooltip"]').tooltip();
    }

    onMouseout = (e) => {

    }

    getClientConfig() {
        this.authService.getClientConfig().subscribe(response => {
            this.logo = response[0].logo;
            localStorage.setItem('app_version', response[0].app_version.toString());
            this.commonService.setLogoPath(this.logo);
            this.commonService.setStripePages(response[0]?.stripe_applicable);
            this.commonService.clientName = response[0].client_name;
            let notificationArray = response[0].notification_control.split(',');
            // Convert array to key-value pairs
            let notification_control = notificationArray.map(item => {
                const [key, value] = item.split('=');
                return { key, value: +value };
            });
            this.showStripePages = response[0]?.stripe_applicable == 1 ? true : false;
            this.commonService.setNotificationControl(notification_control);
            this.notificationControl = this.commonService.getNotificationControl();
            this.showNotificationControl = this.getEnableAccessForUser();
            this.showNotificationBell = this.getEnableAccessForBell();
        });
    }

    getCallAllFeedsApis() {
        if (this.socialMediaFeedUrls.length) {
            this.spinner.show();
            this.listOfApisArr = [];
            this.myFeedsList = [];
            for (let ele of this.socialMediaFeedUrls) {
                if ((ele.source_type === 'rssfeed' && ele.news_feed_URL)) {
                    // this.getFacebookNews(ele.news_feed_URL);
                    this.http.get('/getFeeds/' + new Date().toISOString() + '?url=' + ele.news_feed_URL, { responseType: 'text' }).subscribe(results => {
                        if (results) {
                            let xml;
                            try {
                                xml = JSON.parse(
                                    convert.xml2json(results, {
                                        compact: true,
                                        trim: true,
                                    })
                                );
                            } catch (e) { }
                            if (xml) {
                                console.log(xml);
                                if (xml['rss']['channel']['item'].length) {
                                    xml['rss']['channel']['item'].forEach((item) => {
                                        this.myFeedsList.push({
                                            source: { source: 'rssfeed' },
                                            url: item.link?._text,
                                            title: item.title?._text,
                                            id: item.id,
                                            description: item.description?._cdata,
                                            pubDate: new Date(item.pubDate?._text),
                                            dc_creator: item['dc:creator']?._cdata
                                        });
                                    });
                                    this.sortBy('pubDate');
                                    console.log('xml', ele.source_type, this.myFeedsList)
                                }
                            }
                        }
                        this.spinner.hide();
                    })
                } else if (['facebook', 'youtube', 'twitter', 'linkedin', 'instagram'].includes(ele.source_type.toLowerCase()) && ele.news_feed_URL) {
                    this.http.get(ele.news_feed_URL).subscribe(results => {
                        if (results) {
                            if (results['posts']['items'].length) {
                                results['posts']['items'].forEach((item) => {
                                    this.myFeedsList.push({
                                        source: item.source,
                                        url: item.full_url,
                                        title: item.unformatted_message,
                                        id: item.id,
                                        description: item.message,
                                        pubDate: new Date(item.external_created_at),
                                        dc_creator: item.poster_name
                                    });
                                });
                                this.sortBy('pubDate');
                                console.log('other', ele.source_type, this.myFeedsList)
                            }
                        }
                        this.spinner.hide();
                    })
                }
            }
        }
    }

    getRssNews(rssUrl) {
        if (rssUrl) {
            // this.http.get('/dashboard_config.json', {"headers": new HttpHeaders().set('applicationType', 'json')})
            // return this.http.get('https://pfs-staging.agentvizion.com/getRssNews')
            // this.http.get().subscribe(response => {
            //     console.log('1111', response)
            // })
            //.subscribe(response => {
            // var reqParams = {
            //     url: rssUrl
            // }
            // this.commonService.getRssNews(reqParams).subscribe(response => {
            //     console.log('1111', response)
            // });
            // deferred.resolve(response);
            // console.log('1111', response)

            // if (response !== null) {
            //     if (response && response.items) {
            //         response.items.forEach(function (rssItem) {
            //             this.myFeedsList.push({
            //                 source: 'rssfeed',
            //                 url: rssItem.url,
            //                 title: rssItem.title,
            //                 description: rssItem.description,
            //                 pubDate: new Date(rssItem.pubDate),
            //                 dc_creator: rssItem.dc_creator
            //             });
            //         });
            //     }
            // }
            // });
            // return deferred.promise;
        } else {
            var staticResponse = [{
                source: 'rssfeed',
                url: 'https://businessrecord.com/Content/Culture/Culture/Article/Annuity-executive-Blunt-and-wife-donate-1-million-for-philanthropy-professorship/170/832/90451',
                title: 'Annuity executive Blunt and wife donate $1 million for philanthropy professorship',
                description: 'Christopher Blunt, president and CEO of Fidelity & Guaranty Life Insurance Co. in Des Moines, and his wife, Gretchen Nickel, have donated $1 million to the American College of Financial Services. The gift will be used to establish the Blunt-Nickel Professorship in Philanthropy at the college, which is located in King of Prussia, Pa. The gift enables expansion of the American College Initiatives in Philanthropy program. The Blunt-Nickel Professorship will initially focus on convening, connecting and educating corporate leaders and entrepreneurs in transition from “success to significance.” “Gretchen and I are proud to support the American College of Financial Services,” Blunt said in a statement. “Education has been a pillar of our philanthropy and we look forward to the impact the Professorship will have on leaders who desire to learn how philanthropy can be used to benefit society, and find purpose and fulfillment in life.” The college will appoint Blunt as its inaugural Philanthropist in Residence this summer, an advisory role to the Blunt-Nickel Professor of Philanthropy. Blunt is a three-time alumni of the college, and served on its board of trustees from 2009 to 2015 and returned to the board in 2018. He was inducted into the college’s Alumni Hall of Fame in 2018. Nickel is a partner at MFP Technology Services, an international wholesale distributor of IT products. Read a Business Record Insider profile of Blunt.',
                pubDate: new Date('2020-06-19T15:30:49.000-07:00'),
                dc_creator: 'Business Record'
            }];
            // deferred.resolve(staticResponse);
            this.myFeedsList.push({
                source: 'rssfeed',
                url: 'https://businessrecord.com/Content/Culture/Culture/Article/Annuity-executive-Blunt-and-wife-donate-1-million-for-philanthropy-professorship/170/832/90451',
                title: 'Annuity executive Blunt and wife donate $1 million for philanthropy professorship',
                description: '<p>Christopher Blunt, president and CEO of Fidelity & Guaranty Life Insurance Co. in Des Moines, and his wife, Gretchen Nickel, have donated $1 million to the American College of Financial Services. The gift will be used to establish the Blunt-Nickel Professorship in Philanthropy at the college, which is located in King of Prussia, Pa.</p>',
                pubDate: new Date('2020-06-19T15:30:49.000-07:00'),
                dc_creator: 'Business Record'
            }, {
                source: 'rssfeed',
                url: 'https://www.thinkadvisor.com/2020/06/02/fidelity-national-closes-on-fidelity-guaranty-life/',
                title: 'Fidelity National Closes on Fidelity & Guaranty Life',
                description: '<p>The U.S. individual annuity now has a major new player: Fidelity National Financial Inc.The Jacksonville, Florida-title insurer closed on FGL Holdings Monday. FGL is the George Town, Cayman Islands-based holding company that controls Fidelity & Guaranty Life Insurance Company (F&G), which has its main offices in Des Moines, Iowa.</p>',
                pubDate: new Date('Tue, 02 Jun 2020 1:59:19 +0000'),
                dc_creator: 'ThinkAdvisor'
            }, {
                source: 'rssfeed',
                url: 'https://businessrecord.com/Content/Default/All-Latest-News/Article/Fidelity-National-Financial-completes-acquisition-of-FGL-Holdings-/-3/248/90283',
                title: 'Fidelity National Financial completes acquisition of FGL Holdings',
                description: '<p>Fidelity National Financial Inc. and FGL Holdings on Monday announced the completed acquisition of FGL Holdings by FNF. Under the terms of the definitive agreement, FNF issued approximately 27 million shares of FNF common stock and paid about $1.8 billion in cash to former holders of FGL ordinary and preferred shares.</p>',
                pubDate: new Date('Tue, 02 Jun 2020 1:59:19 +0000'),
                dc_creator: 'Business Record'
            }, {
                source: 'rssfeed',
                url: 'https://www.moodys.com/research/Moodys-upgrades-FGL-Holdings-ratings-following-acquisition-by-Fidelity-National--PR_1000002952?cid=7QFRKQSZE021',
                title: 'Moody’s upgrades FGL Holdings’ ratings following acquisition by Fidelity National Financial',
                description: '<p>New York , June 1, 2020 – Moody`s Investors Service, ("Moody`s") has upgraded the LT issuer ratings of FGL Holdings (NYSE: FG; F&G) to Ba2 from Ba3 following the closing of the acquisition by Fidelity National Financial, Inc. (NYSE: FNF; Baa2 senior). Moody`s has upgraded the insurance financial strength.</p>',
                pubDate: new Date('Mon, 01 Jun 2020 1:59:19 +0000'),
                dc_creator: 'Moody`s Investors Service'
            });
            // return deferred.promise;
        }
    }

    sortBy(field: string) {
        this.myFeedsList.sort((a: any, b: any) => {
            if (a[field] > b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        this.myFeedsList = this.myFeedsList;
        // console.log(JSON.stringify(this.myFeedsList));
    }

    redirectToPage(routeUrl) {
        // const routerState = this.router.routerState.snapshot;
        this.router.navigate([routeUrl['url']], { queryParams: {} });
    }

    logout() {
        update_child('clear', 'Dashboard');
        this.authService.logout().subscribe(response => {
            // remove user from local storage to log user out
            this.commonService.resetSelector();
            this.localStorageService.remove("_t");
            this.localStorageService.remove("userTabInfo");
            this.localStorageService.remove("userInfo");
            this.localStorageService.remove("activeTabId");
            this.localStorageService.remove("activeSubTab");
            if (response['status'] === 'Success') {
                if (this.commonService.SSOLogin && this.commonService.SSOLogin.includes('no')) {
                    this.router.navigate(['/login'], { queryParams: {} });
                } else {
                    this.router.navigate(['/logout'], { queryParams: {} });
                }
            }
        });
    }

    getSubscriptionType = function (type) {
        var string = type;
        var value = string[0].toUpperCase() + string.slice(1);
        return value + 'ly';
    }

    //returns the date
    getsubscriptionStartDate = function (value) {
        // return $filter('date')(value * 1000, "dd-MMM-yyyy");
        return value;
    }

    //returns the date with start and end date
    getCurrentCycleDate = function (start, end) {
        // var startDate = $filter('date')(start * 1000, "dd-MMM-yyyy");
        // var endDate = $filter('date')(end * 1000, "dd-MMM-yyyy");
        // return startDate + ' to ' + endDate;
        return start + ' to ' + end;
    }

    //returns the amount value and next invoice date
    getNextInvoice = function (planObj, discount, end) {
        var amount = (planObj.amount - discount) / 100;
        var finalAmount = amount//$filter('number')(amount, 2);
        var endDate = end //$filter('date')(end * 1000, "dd-MMM-yyyy");
        var currencySymbol = '';
        if (planObj.currency === 'usd') {
            currencySymbol = '$';
        } else {
            currencySymbol = planObj.currency;
        }
        return currencySymbol + '' + finalAmount + ' on ' + endDate;
    }

    cancelSubscriptions(cancelSubscription) {
        this.modalService.open(cancelSubscription);
    }

    cancelSubscriptionOk() {
        var feedbackStr = $('#feedbackReason').val();
        var feedbackInfo = {
            feedback: feedbackStr
        };
        this.spinner.show();
        this.authService.cancelStripeSubscription({
            params: feedbackInfo
        }).subscribe(res => {
            if (res && res['msg'] == 'Subscription updated successfully')
                this.flashMessagesService.show('Your AgentVizion registration has been cancelled successfully!', { cssClass: 'alert-success', timeout: 3000, closeOnClick: true, showCloseBtn: true });
            // $('#host_name').html('Your AgentVizion registration has been cancelled successfully!');
            this.spinner.hide();
            this.modalService.dismissAll();

        }, err => {
            this.spinner.hide();
            this.modalService.dismissAll()
        });
    }

    updateCard() {
        this.showCardsPopup = true;
        setTimeout(() => {
            document.getElementById('openCardModal').click();
        }, 100);
    }

    subscriptionDetails(subscriptionData) {
        this.spinner.show();
        this.authService.getsubscriptionDetails().subscribe(res => {
            console.log("@@@@@", res);
            var subscriptionResponse: any = res;
            if (subscriptionResponse.length) {
                var discount = subscriptionResponse.discount ? subscriptionResponse.discount.coupon.amount_off : 0;
                this.subscriptionDetail = {
                    'subscriptionType': this.getSubscriptionType(subscriptionResponse.plan.interval),
                    'plan': subscriptionResponse.plan.id,
                    'subscriptionDate': this.getsubscriptionStartDate(parseInt(subscriptionResponse.created) * 1000),
                    'currentCycle': [parseInt(subscriptionResponse.current_period_start) * 1000, parseInt(subscriptionResponse.current_period_end) * 1000],
                    'currency': subscriptionResponse.plan.amount,
                    'nextInvoice': this.getsubscriptionStartDate(parseInt(subscriptionResponse.current_period_end) * 1000)
                }
            }
            this.spinner.hide();
            this.showCardsPopup = true;
            this.registrationDetails = subscriptionResponse;
            setTimeout(() => {
                document.getElementById('registrationModal').click();
            }, 100);
            // this.modalService.open(subscriptionData);
        })
    }

    onChangePassword() {
        this.routeParams = {
            url: "/change-password",
            queryParam: ''
        }
        this.redirectToPage(this.routeParams);
        setTimeout(() => {
            this.commonService.updateUserActivity({ activity: 'Agentvizion tab changed to: change-password' });
        }, 500);
    }

    onMyNews(myNewsContent) {
        this.getCallAllFeedsApis();
        this.modalService.open(myNewsContent);
    }

    setNotifications(notificationContent) {
        // this.getUserOptList();
        this.getScreenApplicableNotificationCategories();
        this.getUserPreferenceList();
        this.modalService.open(notificationContent);
    }

    checkOptionAllowedByRole(data) {
        return data.rolesAllowed.includes(this.loginUserRole);
    }

    showDataLoadStatus() {
        let details: any = {};
        details.title = "Data Load Status";
        details.sameUrl = false;
        details.configName = 'getDataLoadStatus';
        details.showModal = true;
        const modalRef = this.modalService.open(DataLoadStatusComponent, { size: 'xl', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
        modalRef.componentInstance.my_table_modal_object = details;
    }

    updatePreference(event) {

    }

    getDescription(description) {
        var descStr = description.replace(/(<([^>]+)>)/ig, '');

        function decodeHtmlCharCodes(str) {
            return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
                return String.fromCharCode(charCode);
            });
        }
        return decodeHtmlCharCodes(descStr);
    }

    onSelectionNews(newsObj) {
        if (this.isValidHttpUrl(newsObj['url'])) {
            window.open(newsObj.url, '_blank');
            return;
        } else if (newsObj.source?.source?.toLowerCase().includes('twitter')) {
            let url = `https://twitter.com/${newsObj.source.term}/status/${newsObj.url}`;
            window.open(url, '_blank');
        } else {
            console.error("invalid url");
        }
    }

    openHelpLink(data: any) {
        if ('tab1' === data.data.id) {
            const url = data.data.helpLink;
            window.open(url, '_blank');;
        }
    }

    getTabContent(obj) {
        $('#navbarNav').collapse('hide');
        let previousTab = this.localStorageService.get('activeTabId');
        this.commonService.resetparameters();
        this.commonService.showLoader();
        $('navbar-nav').removeClass('active');
        let activeTabId = obj.id;
        this.selectedTab = activeTabId;
        let routeUrl = '';
        $('#pageTabs li').removeClass('active');
        if ($('#productFilter li.init').html() !== 'Select Product Type') {
            this.commonService.setSelector('product', $('#productFilter li.init').html());
            this.commonService.setParameters('param7', 'category');
        }
        $('#agency-select_container').show();
        $('#pageTabs #' + activeTabId + '.nav-item').addClass('active');
        $('#filterByDateFilter_container, #fromDate_container, #toDate_container').removeClass('none-pointer-events');
        if (previousTab !== activeTabId) {
            let tabObject = this.tabsData.filter(ele => { return ele.tab_id == activeTabId })
            if (activeTabId === 2) {
                $('#lineOfBusinessFilter_container').show();
                // routeUrl = this.routeLinkData[activeTabId];
                if (tabObject.length) {
                    routeUrl = this.routeLinkData[activeTabId] + `/${tabObject[0]['subTabs'][0]['sub_tab_name'].replace(/\s/g, '').toLowerCase()}`;
                }
            } else if (activeTabId === 1) {
                $('#lineOfBusinessFilter_container').show();
                routeUrl = this.routeLinkData[activeTabId];
            } else if (activeTabId === 5 || activeTabId === 3 || activeTabId === 4) {
                // let tabObject = this.tabsData.filter(ele=>{return ele.tab_id == activeTabId})
                if (tabObject.length) {
                    routeUrl = this.routeLinkData[activeTabId] + `/${tabObject[0]['subTabs'][0]['sub_tab_name'].replace(/\s/g, '').toLowerCase()}`;
                }
            } /*else if(activeTabId === 4) {
                routeUrl = this.routeLinkData[activeTabId];
            }*/ else if (activeTabId === 6) {
                routeUrl = this.routeLinkData[activeTabId];
            } else if (activeTabId === 7 || activeTabId === 5) {
                // let tabObject = this.tabsData.filter(ele=>{return ele.tab_id == activeTabId})
                if (tabObject.length) {
                    routeUrl = this.routeLinkData[activeTabId];
                }
            } else {

                routeUrl = `app/${obj.tabObj?.tab_name?.toLowerCase()}`
            }
            this.routeParams = {
                url: routeUrl,
                queryParam: ''
            }
            this.localStorageService.set('activeTabId', activeTabId);
            this.commonService.setParameters('param5', 'All');
            this.commonService.setParameters('column', '');
            this.commonService.resetSapOrTapToggle();
            this.redirectToPage(this.routeParams);
            setTimeout(() => {
                this.commonService.updateUserActivity({ activity: 'Agentvizion tab changed to: ' + (tabObject[0] ? tabObject[0]['tab_name'] : '') });
            }, 1000);
        } else {
            this.commonService.hideLoader();
        }
        this.commonService.resetSelectorPosition();
    }

    isValidHttpUrl(string) {
        let url;

        console.log(string, url);
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    getNewsFeedUrls() {
        this.commonService.getNewsFeedUrls().subscribe(res => {
            if (res.length) {
                this.socialMediaFeedUrls = res;
            }
        })
    }

    goToAVLite(event = true) {
        if (!event) return;
        let metaData: { [key: string]: any } = {}
        let excludeLocalstorageKeys = ['activeSubTab', 'activeTabId'];
        let keys = Object.keys(localStorage)
        for (let k of keys) {
            if (excludeLocalstorageKeys.includes(k)) continue;
            let temp = localStorage.getItem(k);
            try {
                temp = JSON.parse(temp);
            } catch (e) { }
            // console.log(temp);
            metaData[k] = temp
        }
        // metaData['_t'] = localStorage.getItem('_t');
        this.commonService.saveSessionDetails({ metaData }).subscribe((res: any) => {
            // console.log(res);
            window.location.href = `${this.avLiteUrl}?session_id=${res.session_id}`;
        })
    }

    getAVUrls() {
        return new Promise((resolve, reject) => {
            this.commonService.getAVURLs().subscribe(async (res: any) => {
                // console.log(res);
                if (res && res[0]?.av_lite_URL) this.avLiteUrl = res[0].av_lite_URL;
                if (res && res[0]?.mcc_URL) {
                    this.localStorageService.set("avMCCUrl", res[0]?.mcc_URL);
                }
                let notificationArray = res[0]?.notification_control.split(',');
                // Convert array to key-value pairs
                let notification_control = notificationArray.map(item => {
                    const [key, value] = item.split('=');
                    return { key, value: +value };
                });
                this.magnifactNotificationControls = notification_control
                await this.getLastAppType();
                resolve(true);
            })
        })
    }

    saveLastAppType() {
        let params = { last_app_type: 0 };
        this.commonService.saveLastAppType(params).subscribe((res: any) => {
            // console.log(res);
        })
    }

    async getLastAppType() {
        return await new Promise((resolve, reject) => {
            this.commonService.getLastAppType().subscribe((res: any) => {
                // console.log(res);
                if (res[0]?.last_app_type == 1 && !this.session_id) {
                    let div = document.createElement('div');
                    div.innerHTML = `<div style="margin:auto;">
                                    <h6 style="">Loading last application state</h6>
                                    </div>`;
                    div.style.position = 'absolute';
                    div.style.width = '100%';
                    div.style.height = '100%';
                    div.style.backgroundColor = '#fff';
                    div.style.top = '0';
                    div.style.zIndex = '999999';
                    div.style.display = 'flex';
                    document.body.appendChild(div);
                    this.goToAVLite();
                    resolve(false);
                } else {
                    this.saveLastAppType();
                    resolve(true);
                }
            })
        })
    }

    getLabels(key) {
        return this.NotificationLabels.find(option => option.key === key).label;
    }

    showMedium(key) {
        return this.NotificationLabels.find(option => option.key === key).display;
    }

    checkClientDisablity(index) {
        if (this.notificationControl[index]?.value == 0) {
            return true;
        } else {
            return false;
        }
    }

    checkMagnifactDisablity(index) {
        if (this.magnifactNotificationControls[index]?.value == 0) {
            return true;
        } else {
            return false;
        }
    }

    checkUserDisablity(index) {
        if (this.userNotificationControls[index]?.value == 0) {
            return true;
        } else {
            return false;
        }
    }

    checkCategoryApplicable(category, key) {
        return category.is_category_applicable.includes(`${key}=0`);
    }

    getEnableAccessForUser() {
        // return this.notificationControl.includes('Email=1') || this.notificationControl.includes('Web=1');
        return this.notificationControl?.some(item => item.value == 1) ? true : false;
    }

    getEnableAccessForBell() {
        // return this.notificationControl.includes('Web=1');
        return this.notificationControl?.some(item => item.key == 'Web' && item.value == 1) ? true : false;
    }

    isVisibleBlock(key) {
        if (key == 'Email') {
            return this.notificationControl.includes('Email=1');
        }
        if (key == 'Web') {
            return this.notificationControl.includes('Web=1');
        }
    }

    getClassName() {
        if (this.notificationControl.includes('Email=1') && this.notificationControl.includes('Web=1')) {
            return 'col-6'
        } else if (this.notificationControl.includes('Email=0') && this.notificationControl.includes('Web=1')) {
            return 'col-12'
        } else if (this.notificationControl.includes('Email=1') && this.notificationControl.includes('Web=0')) {
            return 'col-12'
        }

    }

    getNotificationPopupStyles() {
        let height = this.screenNotificationCategories?.length * 120;
        const dynamicStyles = {
            'height': height + 'px'
        };

        return dynamicStyles;
    }

    getShowNotificationPopupStyles() {
        let height = this.notificationContentData?.length * 80;
        const dynamicStyles = {
            'height': height + 'px'
        };

        return dynamicStyles;
    }

    getScreenApplicableNotificationCategories() {
        let params = {
            screen_type: 'AV_Users'
        }
        this.screenNotificationCategories = [];
        this.commonService.getScreenApplicableNotificationCategories(params).subscribe(response => {
            this.screenNotificationCategories = response;
        })
    }

    getUserPreferenceList() {
        this.commonService.getUserPreferenceList().subscribe((res: any) => {
            this.userNotificationControls = [];
            let notificationArray = res[0].notification_medium_preference.split(',');
            let notification_control = notificationArray.map(item => {
                const [key, value] = item.split('=');
                return { key, value: +value };
            });
            this.userNotificationControls = notification_control;
            this.user_medium_selection = res[0].notification_medium_preference;
            this.user_selection = res[0];
        })
    }

    getUserCheckValue(key, category) {
        if (key == 'Email') {
            return this.user_selection?.enable_email_preference ? this.user_selection.enable_email_preference.includes(category.id) : false;
        } else if (key == 'Web') {
            return this.user_selection?.enable_web_preference ? this.user_selection.enable_web_preference.includes(category.id) : false;
        } else if (key == 'Mob') {
            return this.user_selection?.enable_mob_preference ? this.user_selection.enable_mob_preference.includes(category.id) : false;
        } else if (key == 'SMS') {
            return this.user_selection?.enable_sms_preference ? this.user_selection.enable_sms_preference.includes(category.id) : false;
        }

    }

    getMagnifactCheckValue(category, key) {
        return category.magnifact_selection.includes(`${key}=1`);
    }

    getClientCheckValue(category, key) {
        return category.client_selection.includes(`${key}=1`);
    }

    checkIfAnyOneSelectedAtClientLevel(category) {
        let medium = ['Email', 'Web', 'Mob', 'SMS'];
        for (let e of medium) {
            let temp = this.getClientCheckValue(category, e) && !this.checkClientDisablity(medium.indexOf(e));
            let temp1 = this.getMagnifactCheckValue(category, e) && !this.checkMagnifactDisablity(medium.indexOf(e));
            if (temp && temp1) return temp;
        }
        return false;
    }

    checkIfAnyCategoryAvailableClientLevel() {
        const hasMagnifactSelection = this.screenNotificationCategories.some(category => {
          return this.checkIfAnyOneSelectedAtClientLevel(category)
        });
        return hasMagnifactSelection;
      }

    updateUserOptNotification(event, key, index) {
        if (this.checkClientDisablity(index)) {
            return;
        }
        let value = event.target.checked ? 1 : 0
        let initialString = this.user_medium_selection;
        let updatedString = this.updateValue(initialString, key, value);
        let params = {
            user_selection: updatedString
        }

        this.commonService.updateUserOptNotification(params).subscribe((res: any) => {
            this.successMsgFlag = true;
            setTimeout(() => {
                this.successMsgFlag = false;
            }, 1000);
            this.getUserPreferenceList();
        })
    }

    updateValue(initialString, key, newValue) {
        // Use a regular expression to find and replace the value for the specified key
        let updatedString = initialString.replace(new RegExp(`${key}=[0-1]`), `${key}=${newValue}`);
        return updatedString;
    }

    updateUserPreferenceList(event, category, key, index) {
        if (this.checkClientDisablity(index) || this.checkUserDisablity(index)) {
            return;
        }
        let params = {
            category_id: category.id,
            key: key
        }
        this.commonService.updateUserPreferenceList(params).subscribe((res: any) => {
            this.successMsgFlag = true;
            setTimeout(() => {
                this.successMsgFlag = false;
            }, 1000);
            this.getUserPreferenceList();
        })
    }

    getNotificationCount() {
        this.commonService.getNotificationsUnreadCount().subscribe((res: any) => {
            this.NotificationsUnreadCount = res[0].notification_count;
        })
    }

    showNotification(showNotificationContent) {
        this.RemoveNotificationCount();
        this.getNotificationList();
        this.modalService.open(showNotificationContent, { windowClass: 'custom-modal-small' });
    }

    getNotificationList() {
        let params = this.reqParams;
        this.commonService.getNotificationsList({ params }).subscribe((res: any) => {
            this.notificationContentData = res.data;
            this.paginationConfig.totalRecords = res.totalCount[0]['count'];
        })
    }

    RemoveNotificationCount() {
        this.commonService.RemoveNotificationCount().subscribe((res: any) => {
            this.getNotificationCount();
        })
    }

    MarkAllAsRead() {
        this.commonService.updateAllNotificationsReadStatus().subscribe((res: any) => {
            this.getNotificationList();
            // this.getNotificationCount();
        })
    }

    MarkAsReadUnRead(event, msgId, val) {
        event.stopPropagation();
        let params = {
            msg_id: msgId,
            is_read: val
        }
        this.commonService.updateReadUnreadStatus(params).subscribe((res: any) => {
            this.getNotificationList();
            // this.getNotificationCount();
        })
    }

    redirectToNotifictionURL(event, data) {
        if(data.is_read == 0) {
            this.MarkAsReadUnRead(event, data.msg_id, 1)
        }
        let userTabData: any = JSON.parse(localStorage.getItem('userTabInfo'));
        let activeTabObj = [];
        const [tab_name, sub_tab_name] = data.redirect_url.split('/').filter(Boolean).slice(-2);

        this.localStorageService.set('previousRoute', this.router.url);
        activeTabObj = userTabData.filter(ele => ele.tab_name.replace(/\s/g, '').toLowerCase() === tab_name);
        if (activeTabObj.length) {
            this.selectedTab = activeTabObj[0]['tab_id'];
            activeTabObj[0]['subTabs'].forEach(subtab => {
                if (subtab.sub_tab_name.replace(/\s/g, '').toLowerCase() === sub_tab_name) {
                    this.localStorageService.set('activeSubTab', { id: subtab.sub_tab_id, api: subtab.api_name });
                }
            });
            this.router.navigate([`app/${tab_name}/${sub_tab_name}`], { queryParams: { date: data.etl_run_date } });
        }

    }

    clearAllNotifications() {
        this.commonService.updateClearNotificationFlag().subscribe((res: any) => {
            this.getNotificationList();
            // this.getNotificationCount();
        })
    }

    paginatorClickEvent($event) {
        console.log('paginatorClickEvent', $event);
        switch ($event?.buttonClicked) {
            case 'next':
                this.reqParams.pageNo = this.paginationConfig.currentRecordStart + this.paginationConfig.offset - 1;
                this.paginationConfig.currentRecordStart = this.paginationConfig.currentRecordStart + this.paginationConfig.offset;
                this.paginationConfig.currentRecordEnd = this.paginationConfig.currentRecordEnd + this.paginationConfig.offset;
                this.getNotificationList();
                break;
            case 'previous':
                this.reqParams.pageNo = this.paginationConfig.currentRecordStart - this.paginationConfig.offset - 1;
                this.paginationConfig.currentRecordStart = this.paginationConfig.currentRecordStart - this.paginationConfig.offset;
                this.paginationConfig.currentRecordEnd = this.paginationConfig.currentRecordStart + this.paginationConfig.offset - 1;
                this.getNotificationList();
                break;
            case 'last':
                let pageNo = parseInt((this.paginationConfig.totalRecords / this.paginationConfig.offset).toString()) * this.paginationConfig.offset;
                this.reqParams.pageNo = pageNo;
                this.paginationConfig.currentRecordStart = pageNo + 1;
                this.paginationConfig.currentRecordEnd = this.paginationConfig.totalRecords;
                this.getNotificationList();
                break;
            case 'first':
                this.reqParams.pageNo = 0;
                this.paginationConfig.currentRecordStart = 1;
                this.paginationConfig.currentRecordEnd = this.paginationConfig.offset;
                this.getNotificationList();
                break;
            default:
                break;
        }
    }

    ngOnDestroy(): void {
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe();
        }
    }
}
