import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonService } from '../../services/common.service';


@Component({
  selector: 'app-header-selectors',
  templateUrl: './header-selectors.component.html',
  styleUrls: ['./header-selectors.component.scss']
})
export class HeaderSelectorsComponent implements OnInit {

  logo: string;

  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this.logo = this.commonService.getLogoPath();
    !this.logo ? this.getClientConfig():'';
  }

  getClientConfig(){
    this.authService.getClientConfig().subscribe(response => {
      this.logo = response[0].logo;
    });
  }

}
