
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{shortMessage}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <p style="line-height: 1.4;font-size: 14px;" [innerHTML]="longMessage"></p>
    </div>
</ng-template>

<div id="marqueeMainContainer" *ngIf="getMarqueeList.length && showAlertTicker && showFlash" style="position:relative;top: 5px;" class="col-xs-12 col-sm-12 col-lg-12">
    <div id="updatedId" style="padding-left: 10px;"><div class="flash-news-title">UPDATES</div></div>
    <div class="marquee" id="marqueeId">
        <span *ngFor="let mOption of getMarqueeList" data-title="" (click)="onMarqueeClick(content, mOption)">{{mOption.short_message}}</span>
    </div>
    <div id="updatesBtn" class="updates-btn py-1" style="margin-right: 10px;">
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="onMarquueCancel()"></button>
    </div>
    <div id="showUpdatesBtn" class="show-update-btn" (click)="onMarquueShow()">
        <button type="button" class="fa fa-angle-down" data-dismiss="modal" aria-hidden="true"></button>
    </div>
</div>