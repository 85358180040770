import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule)},
  { path: 'app', loadChildren: () => import('./core/core.module').then(mod => mod.CoreModule)},
  { path: 'subscribe', loadChildren: () => import('./stripe/stripe.module').then(mod => mod.StripeModule)}]

@NgModule({
  imports: [RouterModule.forRoot(routes,  {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }