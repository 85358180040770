import { AfterViewChecked, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewChecked {

  @ViewChild('footer') footer: ElementRef;
  app_version: string = '';

  constructor() { }

  ngAfterViewChecked(): void {
    this.setFooterPosition()
  }

  ngOnInit(): void {
    setInterval(() => this.setFooterPosition());
  }

  setFooterPosition(){
    if(document.body.scrollHeight<(document.documentElement.clientHeight-this.footer.nativeElement.offsetHeight)){
      this.footer.nativeElement.style.position = 'fixed';
      this.footer.nativeElement.style.bottom = '0px';
    } else{
      this.footer.nativeElement.style.position = '';
    }
    this.app_version = localStorage.getItem('app_version');
  }

}
