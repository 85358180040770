import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth-routing.module';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HeaderSelectorsComponent } from '../shared/components/header-selectors/header-selectors.component';
import { FooterSelectorsComponent } from '../shared/components/footer-selectors/footer-selectors.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../shared/shared.module';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [
    HeaderSelectorsComponent,
    FooterSelectorsComponent,
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    LogoutComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AuthRoutingModule,
    RouterModule,
    FlashMessagesModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgbModule,
    NgxSpinnerModule,
    SharedModule
  ]
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule { }
