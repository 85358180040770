import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  localStorage: Storage;
  changes$ = new Subject();

  constructor() {
    this.localStorage   = window.localStorage;
  }

  get(key: string): any {
      let item = this.localStorage.getItem(key)!;
      if(item != 'undefined')
        return JSON.parse(item);
      else 
        return '';
  }

  set(key: string, value: any): boolean {
      this.localStorage.setItem(key, JSON.stringify(value));
      this.changes$.next({
        type: 'set',
        key,
        value
      });
      return true;
  }
  
  remove(key: string): boolean {
      this.localStorage.removeItem(key);
      this.changes$.next({
        type: 'remove',
        key
      });
      return true;
  }
}