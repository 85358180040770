import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_BASE_HREF, Location, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule} from './auth/auth.module';
import { CoreModule} from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { SharedModule } from './shared/shared.module';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { NotificationService } from './shared/notification/notification.service';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ExportService } from './shared/services/export.service';
import { TableService } from './shared/components/table/table.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { StripeModule } from './stripe/stripe.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AuthModule,
    CoreModule,
    StripeModule,
    FlashMessagesModule.forRoot(),
    UiSwitchModule.forRoot({
      size: 'medium',
      checkedLabel: 'on',
      uncheckedLabel: 'off'
    }),
    NgxSliderModule,
    SharedModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers:  [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    NotificationService, TableService, DatePipe, ExportService
  ], //{provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' }
  bootstrap: [AppComponent]
})
export class AppModule { }
